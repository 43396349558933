import http from "../utils/http";

const getAllAsserts = (query) => {
  const params = new URLSearchParams();

  if (query.hasOwnProperty("clientId") && query.clientId) {
    params.append("ClientId", query.clientId);
  }

  if (query.hasOwnProperty("applianceTypes") && query.applianceTypes) {
    query.applianceTypes.forEach((type) => {
      params.append("ApplianceTypes", type);
    });
  }

  return http.get(`/Report/asset-reports`, { params });
};

const downloadAssetReports = (query) => {
  const params = new URLSearchParams();

  if (query.hasOwnProperty("clientId") && query.clientId) {
    params.append("ClientId", query.clientId);
  }

  if (query.hasOwnProperty("applianceTypes") && query.applianceTypes) {
    query.applianceTypes.forEach((type) => {
      params.append("ApplianceTypes", type);
    });
  }

  return http.get(`/Report/asset-reports/download-csv`, {
    params,
    responseType: "blob",
  });
};

export default {
  getAllAsserts,
  downloadAssetReports,
};
