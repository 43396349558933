
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Button,
    Grid,
    Divider,
    FormControl,
    IconButton,
    Link,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addDays } from 'date-fns'
import ResultsTable from '../../components/ui/ResultsTable/ResultsTable'
import EditIcon from "../../components/ui/Icons/EditIcon"
import DeleteIcon from '../../components/ui/Icons/DeleteIcon';
import SorsModal from "../../components/ui/SorsModal/SorsModal"
import SorsEditModal from "../../components/ui/SorsModal/SorsEditModal"
import BasicModal from "../../components/ui/BasicModal/BasicModal"
import { useHistory } from 'react-router-dom'
import Preloader from '../../components/Preloader/Preloader';
import PropertyService from "../../services/PropertyService"
import ClientService from "../../services/ClientService"
import JobService from "../../services/JobService"

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '20px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#E4006D'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4006D'
            }
        },
    },
    button: {
        width: 'auto',
        marginBottom: '20px',
    },
    textarea: {
        paddingRight: '25px',
        marginTop: '8px',
    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500'
    },
    fieldContainer: {
        marginBottom: '25px',
    },
    titleBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        marginBottom: '20px'
    },
    title: {
        paddingRight: '10px',
        fontWeight: '600',
        marginBottom: '10px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    divider: {
        backgroundColor: '#E6ADA5'
    },
    dividerGrey: {
        backgroundColor: '#dedede',
        marginBottom: '30px',
    },
    select: {
        marginRight: '25px',
        marginTop: '8px',
        '& .MuiSelect-icon': {
            color: '#E4006D',
            marginRight:'10px'
            }
    },
    modalSelect: {
        marginTop: '8px',
        borderRadius: '4px'
    },
    input: {
        paddingRight: '25px',
        marginTop: '8px',
    },
    datePicker: {
        borderColor: '#E4006D',
        marginTop: '8px',
        marginRight: '25px',
        width: '100%',
        '& button': {
            width: 'auto',
        },
        '& .MuiButtonBase-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-root': {
            fill: '#E4006D',
            marginRight:'-5px'
        }
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    iconButton: {
        padding: 0,
        marginLeft: '5px'
    },
    subNavigationItem: {
        marginRight: '20px',
        marginBottom: '60px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        width: 'auto'
    },
    approverContainer: {
        position: 'relative',
    },
    changeApprover: {
        position: 'absolute',
        right: '50px',
        top: '52%',
    },
    autoComplete: {
        marginRight: '25px',
        marginTop: '8px',
        width: '94%'
    },
    addSor: {
        color: '#f74A36',
        textTransform: 'capitalize',
        fontSize: '16px',
        marginTop: '5px',
        marginBottom:'5px'
    }
}));

const CreateJob = (props) => {

    const classes = useStyles();
    const params = new URLSearchParams(window.location.search)
    const propertyId = props.match.params.propertyId
    const history = useHistory()
    
    const handleGoToSearch = () => {
        history.push(`/jobs?clientId=${job.clientId}`);
    }

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showPreloader, setShowPreloader] = useState(false);
    const [property, setProperty] = useState({});
    const [contractDetails, setContractDetails] = useState([]);
    const [priorityDetails, setPriorityDetails] = useState([]);
    const [sorsList, setSorsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sorsModal, setSorsModal] = useState(false);
    const [sorsEditModal, setSorsEditModal] = useState(false);
    const [selectedDetailItem, setSelectedDetailItem] = useState({ qty: 0, comments: "" });
    const [selectedDetailIndex, setSelectedDetailIndex] = useState(-1);
    const [validationErrs, setValidationErrs] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [createError, setCreateError] = useState("No job has been submitted");

    const [job, setJob] = useState({
        jobDescription: "",
        accessDetails: "",
        jobID: "",
        jobNumber: "",
        propertyId: props.match.params.id,
        property: {},
        selectAddress: "",
        clientId: 'EBB48256-D3DC-4070-9154-5D4D63145EB8',
        clientContractName: "",
        clientContractID: -1,
        createdOn: new Date().toISOString(),
        createdByUser: 'Portal',
        targetDateTime: addDays(new Date(), 3).toISOString(),
        sors: [],
        priorityCode: -1,
        priorityName: ''
    }, loading);

    useEffect(() => {
        if (property.uprn != null && property.client != null && job.clientContractID!=-1) {
            JobService.getPriorities(property.client.clientId, property.uprn, job.clientContractID)
                .then((response) => {
                    setPriorityDetails(response.collection);
                })
                .catch((error) => console.warn(error))
        }
    }, [property.uprn, property.client, job.clientContractID])

    useEffect(() => {
        if (property.uprn != null && property.client != null && job.clientContractID != -1) {
            JobService.getAllSors(property.client.clientId, job.clientContractID, property.uprn)
                .then((response) => {
                    console.log('sors', response.collection);
                setSorsList(response.collection)
            })
            .catch((error) => console.warn(error))
        }
    }, [property.uprn, property.client,job.clientContractID])

    useEffect(() => {
        if (loading) {
            ClientService.getContractsForProperty(propertyId)
                .then((response) => {
                    setContractDetails(response.collection);
                    setJob({
                        ...job,
                        clientId: response.collection[0]?.clientId
                    })
                })
                .catch((error) => console.warn(error))

            PropertyService.getById(propertyId)
                .then((response) => {
                    setProperty(response.object);
                    setJob({
                        ...job,
                        propertyId: propertyId,
                        property: response.object,
                        uprn: response.object.uprn,
                        clientId: response.object.client.clientId
                    })
                })
                .catch((error) => console.warn(error))
                .finally(setLoading(false))

        }
    }, [loading]);

    const handleErrorModal = () => {
        setShowErrorModal(false);
        history.push(`property/${propertyId}`);
    }

    const addSorsButtons = (lines) => {
        const newLines = JSON.parse(JSON.stringify(lines))

        newLines.forEach((line, i) => {
            line.actions = <div className={classes.actionButtons}><IconButton className={classes.iconButton} aria-label="edit" component="span" lineid={i} onClick={handleEditSorLine}> <EditIcon fill="#9B9D9D" /> </IconButton><IconButton className={classes.iconButton} aria-label="edit" component="span" lineid={i} onClick={handleDeleteSorLine}> <DeleteIcon fill="#9B9D9D" /> </IconButton ></div>

        })

        return newLines;
    }

    const handleEditSorLine = (event) => {
        let itemIndex = event.currentTarget.getAttribute("lineid")
        setSelectedDetailItem(job.sors[itemIndex]);
        setSelectedDetailIndex(itemIndex);
        setSorsEditModal(!sorsEditModal)
    }

    const handleDeleteSorLine = (event) => {
        let itemIndex = event.currentTarget.getAttribute("lineid")
        let updateSors = job.sors;
        updateSors.splice(itemIndex, 1);

        let updateVals = {
            sors: updateSors
        }
        setJob(job => ({
            ...job,
            ...updateVals
        }));

        handleValidation('No SORs have been added', updateSors.length < 1);
    }

    const handleValidation = (err,add) => {       
        let existing = validationErrs.filter((item) => item !== err);
        if (add) { existing.push(err); }
        setValidationErrs(existing);
    }

    const handleJobChange = (event) => {
        const value = event.target.value;
        setJob({
            ...job,
            [event.target.name]: value,
        })
        handleValidation('You must give a client job reference', value === "");
    };

    function addSearchIconToEndAdornment(endAdornment) {
        const children = React.Children.toArray(endAdornment.props.children);
        children.push(<SearchIcon />);
        return React.cloneElement(endAdornment, {}, children);
    }

    const handleContractChange = (event) => {
        const value = event.target.value;
        setJob({
            ...job,
            clientContractID: value
        })
        handleValidation('You must choose a contract',value===-1);
        
    }

    const handlePriorityChange = (event) => {
        const value = event.target.value;
        setJob({
            ...job,
            priorityCode: value,
        })
        handleValidation('You must choose a job type',value===-1);
        
    }


    const handleDateChange = (date) => {
        console.log('output',Date(Date.now()),Date(date),Date(Date.now()) >= Date(date))
        setSelectedDate(date);
        setJob({
            ...job,
            targetDateTime: new Date(date).toISOString(),
        })
    };

    const handleRowClick = (event) => {

    }

    const handleSorsModal = () => {
        setSorsModal(!sorsModal)
    }

    const handleSorsModalSave = (sor, qty) => {
        setSorsModal(!sorsModal);
        let newSor = {
            code: sor.code,
            qty: qty,
            description: sor.description
        }
        let existingSors = job.sors;
        existingSors.push(newSor);
        let updateVals = {
            sors: existingSors
        }
        setJob(job => ({
            ...job,
            ...updateVals
        }));
        handleValidation('No SORs have been added', updateVals.length < 1);
    }

    const handleSorsEditModal = () => {
        setSorsEditModal(!sorsEditModal)
    };

    const handleSorsEditModalSave = (sor, index) => {
        let existingSors = job.sors;
        existingSors[index].qty = sor.qty;
        
        let updateVals = {
            sors: existingSors
        }
        setJob(job => ({
            ...job,
            ...updateVals
        }));
    };

    const handleClearAllLines = () => {
        let updateSors = {
            sors: []
        };
        setJob(job => ({
            ...job,
            ...updateSors
        }));
        handleValidation('No SORs have been added', true);
    }

    const createJob = () => {
        if (validationErrs.length === 0) {
            setShowPreloader(true);
            JobService.create(job)
            .then((response) => {
                console.log(response);
                if (response.success) {
                    history.push(`/schedule-job/${job.clientId}/${job.clientContractID}/${job.uprn}/${response.jobNumber}/${job.jobNumber}`);
                }
                else {
                    console.log('Error creating Job', response.error?.message)
                    setCreateError(response.error?.message);
                    setShowErrorModal(true);
                }
                
            })
            .catch((error) => console.warn(error))
            .finally(() => setShowPreloader(false));
        }
        else {
            validationErrs.forEach((item) => console.log(item));
        }
    }

    const errorList = () => {
        validationErrs.map((err) => <Grid item xs={12} key={validationErrs.indexOf(err)} >
            <div>{err}</div>
        </Grid>);
    }

    const createErrorText = () => {
        return <div>{createError}</div>;
    }


    return (

        <Preloader open={showPreloader}> 
        <div>

            
            <div className={classes.titleBox}>
                <Typography className={classes.title} variant="h5">
                    Create Job
                </Typography>
            </div>

            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>

                        <Typography variant="body2" className={classes.itemTitle}>
                            Client Job Reference
                        </Typography>
                        <Link href="#" variant="body1" color="secondary">
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                fullWidth
                                name="jobNumber"
                                placeholder="Enter client job number"
                                value={job.jobNumber}
                                onChange={handleJobChange}
                            />
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Client Contract</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        ><Select
                            labelId="contract-label"
                                    className={classes.select }
                            name="clientContractID"
                            displayEmpty
                            value={contractDetails.length > 0 ? job.clientContractID : ''}
                            onChange={handleContractChange}
                            >
                                <MenuItem key={-1} value={-1}>Please select the contract...</MenuItem>

                                {contractDetails.map((item) =>
                                    <MenuItem key={item.clientContractID} value={item.clientContractID}>{item.clientContractName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Priority</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <Select
                                    className={classes.select}
                                name="priorityCode"
                                displayEmpty
                                value={job.priorityCode}
                                onChange={handlePriorityChange}
                            >
                                <MenuItem key={-1} value={-1}>Please select the priority...</MenuItem>
                                {priorityDetails.map((item) =>
                                    <MenuItem key={item.code} value={item.code}>{item.literalText}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Target Date</label>
                        <MuiPickersUtilsProvider className={classes.datePickerContainer} utils={DateFnsUtils}>
                            <Grid container>
                                <KeyboardDatePicker
                                    disableToolbar
                                    autoOk
                                    className={classes.datePicker}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    name="targetDateTime"
                                    id="targetDateTime"
                                    value={job.targetDateTime}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} className={classes.fieldContainer}>
                    <Box>

                        <label className={classes.itemTitle}>Job Description</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.textarea}
                                variant="outlined"
                                multiline
                                minRows={5}
                                maxRows={10}
                                fullWidth
                                name="jobDescription"
                                placeholder="Description..."
                                value={job.jobDescription}
                                onChange={handleJobChange}
                            />
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            
            <div className={classes.titleBox}>
                <Typography className={classes.subtitle} variant="h5">
                    SORs
                </Typography>
            </div>
            <Divider className={classes.divider} />

            <Grid container>
                    {job.sors.length > 0 &&
                        <Grid item xs={12} sm={12}>
                    
                            <ResultsTable
                                columns={[
                                    {
                                        name: 'id',
                                        label: 'Id',
                                        hidden: true
                                    },
                                    {
                                        name: 'code',
                                        label: 'Code'
                                    },
                                    {
                                        name: 'description',
                                        label: 'Description'
                                    },
                                    {
                                        name: 'qty',
                                        label: 'Quantity'
                                    },
                                    {
                                        name: 'actions',
                                        label: ''
                                    },
                                ]}
                                data={addSorsButtons(job.sors)}
                                rowClick={handleRowClick}
                                linkKey="id"
                                orderBy="id"
                                direction="desc"
                            /> 
                        </Grid>
                  }  
                <Grid item xs={12} sm={12}>
                       
                        <Box>
                        {job.clientContractID !== "" &&
                            <Button
                                className={classes.addSor}
                                    variant="none"
                                color="primary"
                                onClick={handleSorsModal}
                            >
                                + Add New Line
                            </Button>}
                        
                        </Box>
 <Divider className={classes.dividerGrey} />
                    </Grid>

            </Grid>
            
            <Grid container >
                
                    <Grid item xs={12} sm={4}>

                        <Button
                            className={classes.subNavigationItem}
                            variant="outlined"
                            color="primary"
                            onClick={handleGoToSearch}
                        >
                            Cancel
                        </Button>

                        <Button
                            className={classes.subNavigationItem}
                            variant="contained"
                            color="primary"
                            onClick={createJob}
                        >
                            Next
                        </Button>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                    {validationErrs.map((err) => <Grid item xs={12} key={validationErrs.indexOf(err)} >
                        <div className={classes.itemTitle}>{err}</div>
                    </Grid>)}
                </Grid>
            </Grid>



            <SorsModal
                open={sorsModal}
                onClose={handleSorsModal}
                onSave={handleSorsModalSave}
                sorsList={(sorsList.length>0 ? sorsList : [])}
                canEdit={true}
            />

            <SorsEditModal
                open={sorsEditModal}
                onClose={handleSorsEditModal}
                onSave={handleSorsEditModalSave}
                detailItem={selectedDetailItem}
                detailIndex={selectedDetailIndex}
                canEdit={true}
            />

            <BasicModal
                open={showErrorModal}
                onClose={handleErrorModal}
                onSubmit={handleErrorModal}
                submitButtonText="OK"
                dialogContent={createErrorText}
                headerTitle="Error creating Job"
            />
        </div>
        </Preloader>
    );
};

export default CreateJob;