import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Container, Paper, Box, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: `url($"/images/auth-layout.svg")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
      display: 'flex',
      position: 'relative',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  backgroundPaper: {
    background: 'rgba(255, 255, 255, 0.93)',
    borderRadius: '14px',
    padding: '30px 35px'
  },
  boxText: {
    margin: '20%'
  },
  title: {
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '64px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: '25px'
  },
  subTitle: {
    fontSize: '18px',
    fontStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left'
  }
}))

const AuthLayout = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              component={Paper}
              square
              className={classes.backgroundPaper}
            >
              {props.children}
            </Grid>
            <Grid item xs={12} sm={6} spacing={1}>
              <Box className={classes.boxText}>
                <Typography className={classes.title}>
                  <div>
                    WELCOME TO <b>LIBERTY</b>
                  </div>
                  <div>PORTAL</div>
                </Typography>
                <Typography className={classes.subTitle}>
                  Please Sign In to continue
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default AuthLayout
