import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Grid, Card, CardContent } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600',
    marginBottom: '20px'
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '16px!important'
  },
  cardActions: {
    padding: '0px'
  },
  cardBox: {
    width: '100%',
    marginBottom: '20px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    '&:hover': {
      boxShadow: '0 0 10px rgba(0,0,0,0.2)'
    }
  },
  contentCardBox: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemName: {
    paddingLeft: '15px',
    color: '#F74A36',
    textDecoration: 'none'
  }
}))

const Admin = () => {
  const classes = useStyles()

  return (
    <div>
      <div>
        <Typography className={classes.title} variant="h5">
          Admin
        </Typography>
      </div>
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} container>
          <Card className={classes.cardBox}>
            <CardContent className={classes.cardContent}>
              <Box className={classes.contentCardBox}>
                <img src="/icons/file-icon.svg" alt="users management icon" />{' '}
                <Typography
                  variant="body1"
                  className={classes.listItemName}
                  key="Users"
                  component={NavLink}
                  to="/admin/users"
                >
                  Users
                </Typography>
              </Box>
            </CardContent>
          </Card>

      
          <Card className={classes.cardBox}>
            <CardContent className={classes.cardContent}>
              <Box className={classes.contentCardBox}>
                <img src="/icons/file-icon.svg" alt="power bi management icon"/>{' '}
                <Typography
                  variant="body1"
                  className={classes.listItemName}
                  key="power-bi-reports"
                  component={NavLink}
                  to="/admin/power-bi-management">
                    Power BI Reports Management
                  </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default Admin
