import React, { useState } from 'react'
import ModalContainer from "../ModalContainer/ModalContainer"
import { makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Button,
    Divider,
    Grid,
    FormControl,
    TextField,
    Typography
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'none',
        width: 'fit-content!important',
        padding: '10px 30px'
    },
    label: {
        marginBottom: '10px',
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
        fontSize: '12px'
    },
    textarea: {

    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
    },
    fieldContainer: {
        marginBottom: '20px',
    },
    titleBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        marginBottom: '20px'
    },
    title: {
        borderRight: '1px solid #BCBEBE',
        paddingRight: '10px',
        fontWeight: '600',
        marginBottom: '10px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '30px',
    },
    select: {
        marginRight: '25px',
        marginTop: '8px'
    },
    modalSelect: {
        marginTop: '8px'
    },
    input: {
        marginTop: '8px'
    },
    buttonContainer: {
        justifyContent: 'flex-end',
        width: '100%',
        textAlignLast:'end'
    },
    subNavigationItem: {
        margin: '20px 0px 0px 20px',
        marginBottom: '60px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        minWidth: '100px'
    },
    searchInput: {
        background: '#ffffff',
        boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.10)',
        border: 'none'
    },
    searchButton: {
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        height: '100%',
        minWidth: '100%'
    },
    spaceBetween: {
        justifyContent: 'space-between',
    }

}))


export default function PrintEmailModal(props) {

    const {
        open,
        onClose,
        onPrint,
        onEmail
    } = props

    const classes = useStyles();

    const [emailTo, setEmailTo] = useState("");
    const [emailToIsValid, setEmailToIsValid] = useState(true);

    const validateEmailTo = (value) => {
        setEmailToIsValid(ValidateEmail(value));
        setEmailTo(value);
    }

    function ValidateEmail(addy) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(addy)) {
            return (true)
        }
        return (false)
    }

    const handlePrint = () => {
        onPrint();
        onClose();
    }

    const handleEmail = () => {
        if (emailToIsValid && emailTo !== "") {
            onEmail(emailTo);
            onClose();
        }
        else {
            setEmailToIsValid(emailTo==="" ? false : true)
        }
    }

    return (
        <ModalContainer
            open={open}
            onClose={onClose}
            modalContent={() => (

                <Grid container>

                    <Grid container spacing={2}>
                        <div className={classes.titleBox}>
                            <Typography className={classes.subtitle} variant="h5">
                                Print / Email Purchase Order
                            </Typography>
                        </div>
                        <Divider className={classes.divider} />
                        <Grid item xs={12} className={classes.fieldContainer}>
                            <Box>
                                <label className={classes.itemTitle}>Email To</label>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <TextField
                                        className={classes.input}
                                        variant="outlined"
                                        fullWidth
                                        error={emailToIsValid}
                                        id="emailTo"
                                        value={emailTo}
                                        type="email"
                                        helperText={emailToIsValid ? "" : "Invalid Email Address"}
                                        onChange={(_event) => { validateEmailTo(_event.target.value) }}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid container className={classes.buttonContainer}>
                            <Box className={classes.buttonContainer}>
                                <Button
                                    className={classes.subNavigationItem}
                                    variant="outlined"
                                    color="primary"
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                                
                                <Button
                                    className={classes.subNavigationItem}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleEmail}
                                >
                                    Email
                                </Button>

                                <Button
                                    className={classes.subNavigationItem}
                                    variant="contained"
                                    color="primary"
                                    onClick={handlePrint}
                                >
                                    Print
                                </Button>

                            </Box>
                        </Grid>

                    </Grid>
                </Grid>
            )}
        />
    );
}
