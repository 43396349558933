export const downloadFileFromBase64 = (fileName, documentBody) => {
  const linkSource = `data:application/octet-stream;base64,${documentBody}`
  const downloadLink = document.createElement('a')
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

export const downloadFile = (fileName, blob) => {
  const downloadLink = document.createElement('a')
  downloadLink.href = URL.createObjectURL(blob)
  downloadLink.download = fileName
  downloadLink.click()
}

export const convertBase64ToPdfBLob = (documentBody) => {
  var byteCharacters = atob(documentBody)
  var byteNumbers = new Array(byteCharacters.length)
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  var byteArray = new Uint8Array(byteNumbers)
  return new Blob([byteArray], { type: 'application/pdf;base64' })
}
