import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { Button, Box, Typography, Dialog } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    width: 'fit-content!important',
    padding: '10px 30px'
    },
   modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
   },
   paper: {
    position: 'absolute',
    width: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6, 5, 0, 5),
    borderRadius: '16px',
    overflowY: 'scroll',
    maxHeight: '90%',
    '& :focus-visible': {
        outline: 'none',
    }
    },
  label: {
    marginBottom: '10px',
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500',
    fontSize: '12px',
  },
  modalTitle: {
    backgroundColor: '#F5F2F5',
    display: 'grid',
  },
  contentBox: {
    padding: '30px 50px 10px 50px'
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  modalContainer: {
    borderRadius: '16px!important',
    zIndex: '3000!important'
  },
  titleIcon: {
    margin: 'auto',
    paddingTop: '20px'
  },
  titleContent: {
    margin: 'auto',
    paddingBottom: '20px',
    paddingTop: '10px'
  }
}))


export default function ModalContainer(props) {

    const {
        open,
        headerIcon,
        headerTitle,
        submitButtonText,
        modalContent,
        onSubmit,
        onClose
    } = props


    const classes = useStyles();


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        {modalContent()}
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
