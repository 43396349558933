
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
Divider,
Button,
TextField,
Typography,
Grid,
TablePagination
} from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune';
import { format } from 'date-fns'
import ResultsTable from '../../components/ui/ResultsTable/ResultsTable'
import FilterMenu from "../../components/FilterMenu/FilterMenu"
import PurchaseOrderService from '../../services/PurchaseOrderService'
import { useHistory } from 'react-router-dom'
import ClientContext from "../../utils/globalClientContext"

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '20px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#E4006D'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4006D'
            }
        }
    },
    title: {
        fontWeight: '600',
        marginBottom: '10px'
    },
    searchContainer: {
        maxWidth: '800px',
        padding: '10px 0px 50px 0px',
        margin: 'auto'
    },
    submit: {
        margin: 0,
        textTransform: 'none',
        height: '100%',
        minWidth: '100%',
    },
    filter: {
        height: '100%',
        textTransform: 'capitalize',
        color: '#E4006D',
    },
    divider: {
        backgroundColor: '#E6ADA5'
    },
    input: {
        background: '#ffffff',
        boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.10)'
    },
    label: {
        marginBottom: '10px',
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
        fontSize: '12px'
    },
    preloader: {
        display: 'flex',
        justifyContent: 'center',
        padding: '40px 0'
    },
    overlayOpen: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1500,
        transition: 'all 1s ease',
        transitionProperty: 'background-color, visibility',
        transitionDelay: '0.3s',
        visibility: 'visible'
    },
    overlayClosed: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1500,
        transition: 'all 1s ease',
        transitionProperty: 'background-color, visibility',
        transitionDelay: '0.3s',
        visibility: 'hidden'

    },
    filterMenuOpen: {
        height: '100vh',
        width: '300px',
        backgroundColor: '#ffffff',
        padding: '77px 50px',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1600,
        borderRadius: '0px 16px 16px 0px',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        transition: 'left 1s ease',

    },
    filterMenuClosed: {
        height: '100vh',
        width: '300px',
        backgroundColor: '#ffffff',
        padding: '77px 50px',
        position: 'absolute',
        left: '-300px',
        top: 0,
        zIndex: 1600,
        borderRadius: '0px 16px 16px 0px',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        transition: 'left 1s ease',
    },
}))


const PurchaseOrders = (props) => {

    const classes = useStyles()
    const history = useHistory()
    const { globalClient, setShowGlobalClientList } = useContext(ClientContext);
    
    const [purchaseOrder] = useState();
    const searchParams = new URLSearchParams(props.location.search)

    const searchText = searchParams.get('searchText') || ''
    const searchApproval = searchParams.get('approvalStatus') || ''
    const [purchaseOrders, setPurchaseOrders] = useState([])
    const [filterText, setFilterText] = useState(searchText)
    const [filterApproval, setFilterApproval] = useState(searchApproval)
    const [isSearched, setSearched] = useState(
        !(!searchText && !searchApproval)
    )
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(100)
    const [length, setLength] = useState(0)
    const [showFilterMenu, setShowFilterMenu] = useState(false);

    useEffect(() => {
        setShowGlobalClientList(true);
    })

    useEffect(() => {
        if (isSearched) {
                       
            PurchaseOrderService.getAll({
                filterApproval,
                filterText,
                filterContract: globalClient,
                size,
                page: page + 1,
            })
                .then((response) => {
                    const items = response.value.collection.map((item) => ({
                        id: item.purchaseOrderId,
                        clientContract: item.clientContractName,
                        instruction: item.instructionNumber,
                        PONumber: item.purchaseOrderNumber,
                        POStatus: item.purchaseOrderStatus.literalText,
                        createdOn: format(new Date(item.createdOn),"dd-MM-yy HH:mm"),
                        approver: item.approverName,
                        createdBy: item.createdBy,
                        currentCost: item.currentCost.toFixed(2)
                    }))
                    setLength(response.value.totalRecords)
                    setPurchaseOrders(items)
                })
                .catch((error) => console.warn(error))
        }
    }, [filterApproval, filterText, isSearched, page, size,globalClient])

    const handleChangeApproval = (status) => {
        setFilterApproval(status)
    }

    const handleClearFilter = () => {
        setFilterApproval(null);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeSize = (event) => {
        setSize(event.target.value)
        setPage(0)
    }

    const handleChangeFilterText = (event) => {
        setFilterText(event.target.value)
    }

    const handleSearch = (searchValue, filterValue) => {
        setSearched(true)
        setFilterText(searchValue)
        setFilterApproval(filterValue)
        setSearchParams(searchValue, filterValue)
        setPage(0)
    }

    const setSearchParams = (searchValue, approvalValue) => {
        const params = new URLSearchParams()
        if (searchValue) {
            params.append('searchText', searchValue)
        }

        if (approvalValue) {
            params.append('approvalStatus', approvalValue)
        }

        history.push({
            pathname: '/purchase-orders',
            search: params.values.length > 0 ? '' : `?` + params
        })
    }

    return (


        <div>
            
        <div>
           
            <Grid container spacing={2} className={classes.searchContainer}>

                <Grid item xs={7}>     
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        fullWidth
                        id="POSearch"
                        name="POSearch"
                        placeholder="Search Purchase Order Number"
                        value={purchaseOrder}
                        onChange={handleChangeFilterText}
                    />
                </Grid>


                <Grid item xs={3}>
                    <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            size="large"
                            onClick={() => handleSearch(filterText, filterApproval)}
                    >
                        Search
                    </Button>
                </Grid>

                <Grid item xs={2}>
                        <Button
                            onClick={() => {
                                setShowFilterMenu(!showFilterMenu);
                            }}
                        color="primary"
                        size="large"
                        className={classes.filter}
                        startIcon={<TuneIcon />}
                    >
                        Filters
                     </Button>

                    </Grid>


            </Grid>
            
            <div>
                <Typography className={classes.title} variant="h5">
                    Purchase Orders
                </Typography>
                <Divider className={classes.divider} />
                <Grid container>
                    <Grid item xs={12} sm={12}>

                            <ResultsTable
                                columns={[
                                    {
                                        name: 'id',
                                        label: 'Id',
                                        hidden: true
                                    },
                                    {
                                        name: 'clientContract',
                                        label: 'Client & Contract'
                                    },
                                    {
                                        name: 'instruction',
                                        label: 'Instruction'
                                    },
                                    {
                                        name: 'PONumber',
                                        label: 'PO Number'
                                    },
                                    {
                                        name: 'POStatus',
                                        label: 'PO Status'
                                    },
                                    {
                                        name: 'createdOn',
                                        label: 'Created On'
                                    },
                                    {
                                        name: 'approver',
                                        label: 'Approver'
                                    },
                                    {
                                        name: 'createdBy',
                                        label: 'Created By'
                                    },
                                    {
                                        name: 'currentCost',
                                        label: 'Current Cost'
                                    },
                                ]}
                                data={purchaseOrders}
                                baseLink="/purchase-orders"
                            linkKey="id"
                            orderBy="id"
                            direction="desc"
                            />
                            {!purchaseOrders || purchaseOrders.length===0 ? <p>No data</p> : 
                        <TablePagination
                            rowsPerPageOptions={[10, 50, 100, 500]}
                            component="div"
                            count={length}
                            rowsPerPage={size}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeSize}
                        /> }
                    </Grid>
                </Grid>
            </div>

            </div>

            <FilterMenu
                onShowFilterMenu={() => {
                    setShowFilterMenu(!showFilterMenu);
                }}
                showFilterMenu={showFilterMenu}
                onChangeSelected={handleChangeApproval}
                onClearFilter={handleClearFilter}
            />

    </div>
)
}

export default PurchaseOrders