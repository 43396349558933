import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Button,
  Box,
  TextField,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import UserService from "../../../services/UserService";
import ResultsTable from "../../../components/ui/ResultsTable/ResultsTable";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Moment from "moment";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "600",
    marginBottom: "10px",
  },
  divider: {
    backgroundColor: "#E6ADA5",
  },
  submit: {
    margin: "auto 10px",
    height: "52px",
    width: "30%!important",
    textTransform: "none",
  },
  preloader: {
    display: "flex",
    justifyContent: "center",
    padding: "40px 0",
  },
}));

const UsersGrid = (props) => {
  const classes = useStyles();

  const searchParams = new URLSearchParams(props.location.search);
  const searchText = searchParams.get("search") || "";

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState(searchText);
  const [nextPage, setNextPage] = useState("");

  const getUsers = (nextPage) => {
    return UserService.getAll({ search, nextPage }).then((response) => {
      const items = response.collection.map((item) => ({
        id: item.id,
        username: item.username || "",
        name: `${item.firstName || ""} ${item.surname || ""}`,
        companyName: item.companyName || "",
        active: item.activeStatus,
        userRole: item.userRole || "",
        lastSignInDate: item.lastSignInDate
          ? Moment(item.lastSignInDate).format("DD-MM-YYYY HH:mm")
          : null,
      }));

      return { items, nextPage: response.nextPage };
    });
  };

  useEffect(() => {
    setLoading(true);
    getUsers(nextPage)
      .then((response) => {
        setUsers(response.items);
        setNextPage(response.nextPage);
      })
      .catch((error) => console.warn(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    setLoading(true);
    getUsers('')
      .then((response) => {
        setUsers(response.items);
        setNextPage(response.nextPage ? response.nextPage :'');
      })
      .catch((error) => console.warn(error))
      .finally(() => setLoading(false));
  };

  const handleLoadMore = () => {
    getUsers(nextPage)
      .then((response) => {
        const newUsers = users.concat(response.items);
        setUsers(newUsers);
        setNextPage(response.nextPage);
      })
      .catch((error) => console.warn(error))
      .finally(() => setLoading(false));
  };

  const onSearchHandler = ({ target: { name, value } }) => {
    setSearch(value);
  };

  return (
    <div>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={7} container direction="column">
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6} container direction="column">
              <TextField
                className={classes.input}
                variant="outlined"
                fullWidth
                id="search"
                name="search"
                placeholder=""
                value={search}
                onChange={onSearchHandler}
              />
            </Grid>
            <Grid item xs={6} container direction="column">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                size="large"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={5} container direction="column">
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              className={classes.submit}
              color="primary"
              startIcon={<PersonAddIcon />}
              href="/admin/users/add-new-user"
            >
              New User
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography className={classes.title} variant="h5">
          Users
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={12} sm={12}>
          {loading ? (
            <div className={classes.preloader}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <ResultsTable
              columns={[
                {
                  name: "id",
                  label: "Id",
                  hidden: true,
                },
                {
                  name: "username",
                  label: "Email/Username",
                },
                {
                  name: "name",
                  label: "Name",
                },
                {
                  name: "companyName",
                  label: "Company Name",
                },
                {
                  name: "active",
                  label: "Active",
                  isCustomContent: true,
                  render: (rowData) => {
                    return (
                      <span>
                        {rowData.active ? (
                          <img src="/icons/Active-status.svg" alt="Status" />
                        ) : (
                          <img src="/icons/Close-status.svg" alt="Status" />
                        )}
                      </span>
                    );
                  },
                },
                {
                  name: "userRole",
                  label: "User Role",
                },
                {
                  name: "lastSignInDate",
                  label: "Last Login Date",
                },
              ]}
              data={users}
              baseLink="/admin/users"
              linkKey="id"
              orderBy="id"
              direction="asc"
            />
          )}
          {nextPage && (
            <Grid container alignItems="center" justify="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                size="small"
                onClick={handleLoadMore}
              >
                Load more
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default UsersGrid;
