import React, { useState, useEffect } from 'react'
import ModalContainer from "../ModalContainer/ModalContainer"
import { makeStyles } from '@material-ui/core/styles'
import {
Box,
Button,
Grid,
FormControl,
MenuItem,
Select,
TextField,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    width: 'fit-content!important',
    padding: '10px 30px'
},
label: {
    marginBottom: '10px',
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500',
    fontSize: '12px'
},
textarea: {
    height: '200px',
},
itemTitle: {
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500'
},
fieldContainer: {
    marginBottom: '20px',
},
titleBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '20px',
    marginBottom: '20px'
},
title: {
    borderRight: '1px solid #BCBEBE',
    paddingRight: '10px',
    fontWeight: '600',
    marginBottom: '10px'
},
subTitle: {
    paddingLeft: '10px',
    color: '#BCBEBE'
},
divider: {
    backgroundColor: '#E6ADA5',
    marginBottom: '30px',
},
select: {
    marginRight: '25px',
    marginTop: '8px'
},
modalSelect: {
    marginTop: '8px'
},
input: {
    marginTop: '8px'
},
buttonContainer: {
    justifyContent: 'flex-end',
},
subNavigationItem: {
    margin: '20px 0px 0px 20px',
    marginBottom: '60px',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '400',
    minWidth: '100px'
},

}))


export default function DeliveryDetailsModal(props) {

const {
    open,
    data,
    onSubmit,
    onClose,
    canEdit
} = props

    

const classes = useStyles();

const [select, setSelect] = useState({
    selectAddress: "",
    contactName: data.contactName,
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    postCode: "",
    contactTelephone: "",
    contactEmail: "",
});

    useEffect(() => {
        setSelect(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const onSave = (event) => {
        onSubmit(select);
    }

    const handleSelectChange = (event) => {
        const value = event.target.value;
        if (canEdit && !value.match(/[%<>\\$'"]/)) {
            
            setSelect({
                ...select,
                [event.target.name]: value,
            })
        } 
    };

return (
    <ModalContainer
        open={open}
        onClose={onClose}
        modalContent={() => (

            <Grid container>

                <Grid container>
                    <Grid item xs={12} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Contact Name</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    fullWidth
                                    name="contactName"
                                    placeholder="Contact name..."
                                    value={select.contactName}
                                    onChange={handleSelectChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Address Line 1</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    fullWidth
                                    name="addressLine1"
                                    placeholder="Address Line 1..."
                                    value={select.addressLine1}
                                    onChange={handleSelectChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>


                <Grid container>
                    <Grid item xs={12} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Address Line 2</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    fullWidth
                                    name="addressLine2"
                                    placeholder="Address Line 2..."
                                    value={select.addressLine2}
                                    onChange={handleSelectChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>


                <Grid container>
                    <Grid item xs={12} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Address Line 3</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    fullWidth
                                    name="addressLine3"
                                    placeholder="Address Line 3..."
                                    value={select.addressLine3}
                                    onChange={handleSelectChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>City</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    fullWidth
                                    name="addressLine4"
                                    placeholder="City..."
                                    value={select.addressLine4}
                                    onChange={handleSelectChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Postcode</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    fullWidth
                                    name="postCode"
                                    placeholder="Postcode..."
                                    value={select.postCode}
                                    onChange={handleSelectChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Telephone</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    fullWidth
                                    name="contactTelephone"
                                    placeholder="Telephone..."
                                    value={select.contactTelephone}
                                    onChange={handleSelectChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Email</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    fullWidth
                                    name="contactEmail"
                                    placeholder="Email..."
                                    value={select.contactEmail}
                                    onChange={handleSelectChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={classes.buttonContainer}>
                    <Box>
                        <Button
                            className={classes.subNavigationItem}
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        {canEdit &&
                            <Button
                                className={classes.subNavigationItem}
                                variant="contained"
                                color="primary"
                                onClick={onSave}
                            >
                                OK
                            </Button>

                        }
                        
                    </Box>
                </Grid>

            </Grid>
        )}
    />
);
}
