import React, { useState, useEffect } from 'react'
import { Grid, InputBase, Button, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    submit: {
        margin: 'auto 10px',
        height: '52px',
        width: '15%!important',
        textTransform: 'none'
    },
    input: {
        borderRadius: '30px'
    },
    searchPanel: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inline-flex',
        width: '100%',
        marginBottom: '20px'
    },
    searchBoxBorder: {
        flexGrow: '1',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        borderRadius: '10px',
        padding: '10px',
        '&:hover': {
            boxShadow: '0 0 10px rgba(0,0,0,0.2)'
        }
    },
    customSearchInput: {
        width: '100%',
        borderRight: '2px solid #BCBEBE'
    },
    select: {
        width: '100%'
    }
}))

const SearchPanel = (props) => {
    const classes = useStyles()

    const [filterValue, setFilterValue] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [filterItems, setFilterItems] = useState([])
    const [filterSecondValue, setFilterSecondValue] = useState('')
    const [filterSecondItems, setFilterSecondItems] = useState([])

    const handleChangeFilter = (event) => {
        setFilterValue(event.target.value)
    }

    const handleChangeTypeFilter = (event) => {
        setFilterSecondValue(event.target.value)
    }

    const handleChangeSearch = (event) => {
        setSearchValue(event.target.value)
    }

    const handleOnSearch = () => {
        props.onSearch(searchValue, filterValue, filterSecondValue)
    }

    useEffect(() => {
        setSearchValue(props.searchValue)
        setFilterValue(props.filterValue)
        setFilterItems(props.filterItems)
        if (props.filterSecondItems) {
            setFilterSecondItems(props.filterSecondItems);
            setFilterSecondValue(props.filterSecondValue);
        }
    }, [props.filterItems, props.filterValue, props.searchValue, props.filterSecondItems, props.filterSecondValue])

    return (
        <div>
            <div className={classes.searchPanel}>
                <div className={classes.searchBoxBorder}>
                    <form>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={filterSecondItems && filterSecondItems.length > 0 ? 4 : 8} container direction="column">
                                <InputBase
                                    className={classes.customSearchInput}
                                    value={searchValue}
                                    inputProps={{ 'aria-label': 'naked' }}
                                    onChange={handleChangeSearch}
                                    placeholder={props.searchValuePlaceholder}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            handleOnSearch()
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={filterSecondItems && filterSecondItems.length > 0 ? 4 : 4} container direction="column">
                                <Select
                                    width={1}
                                    id="simple-select"
                                    disableUnderline
                                    displayEmpty
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left'
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    value={filterValue}
                                    onChange={handleChangeFilter}
                                    className={classes.select}
                                >
                                    <MenuItem key={0} value="">
                                        All
                                    </MenuItem>
                                    {filterItems?.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            {filterSecondItems && filterSecondItems.length > 0 &&
                                <Grid item xs={4} container direction="column">
                                    <Select
                                        width={1}
                                        id="simple-select2"
                                        disableUnderline
                                        displayEmpty
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left'
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        value={filterSecondValue}
                                        onChange={handleChangeTypeFilter}
                                        className={classes.select}
                                    >
                                        <MenuItem key={0} value="-1">
                                            All
                                        </MenuItem>
                                        {filterSecondItems?.map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>}

                        </Grid>
                    </form>
                </div>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size="large"
                    onClick={handleOnSearch}
                >
                    Search
                </Button>
            </div>
        </div>
    )
}

export default SearchPanel
