import React from 'react';
import ResultsTable from '../ResultsTable/ResultsTable';

const BasicSOR = ({ items }) => {
  return (
    
      <ResultsTable
        columns={[
          {
            name: 'sorCode',
            label: 'SOR Code'
          },
          {
            name: 'description',
            label: 'Description'
          },
          {
            name: 'quantity',
            label: 'Quantity'
              },
              {
                  name: 'originalQuantity',
                  label: 'Original'
              }
        ]}
        data={items}
        orderBy="sorCode"
        direction="desc"
      ></ResultsTable>
    
  );
};

export default BasicSOR;
