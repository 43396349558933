import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Box,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    width: 'fit-content!important',
    padding: '10px 30px'
  },
  label: {
    marginBottom: '10px',
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500',
    fontSize: '12px'
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 0'
  }
}))

const UserDetailsTab = ({ data, loading, onChange, onSave }) => {
  const classes = useStyles()

  return (
    <div>
      {loading ? (
        <div className={classes.preloader}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Box mt={3}>
          <form noValidate autoComplete="off">
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} container>
                <Grid item xs container spacing={3}>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    direction="column"
                  >
                    <Box mb={2}>
                      <label className={classes.label}>First Name</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="firstName"
                        name="firstName"
                        placeholder="Enter First Name"
                        value={data.firstName}
                        onChange={onChange}
                      />
                    </Box>
                    <Box mb={2}>
                      <label className={classes.label}>Surname</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="surname"
                        name="surname"
                        placeholder="Enter Surname"
                        value={data.surname}
                        onChange={onChange}
                      />
                    </Box>
                    <Box mb={2}>
                      <label className={classes.label}>Company Name</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="companyName"
                        name="companyName"
                        placeholder="Enter Company Name"
                        value={data.companyName}
                        onChange={onChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs container spacing={3}>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    direction="column"
                  >
                    <Box mb={2}>
                      <label className={classes.label}>Email</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="email"
                        name="email"
                        placeholder="Enter Email"
                        value={data.email}
                        InputProps={{
                          readOnly: true
                        }}
                        disabled
                      />
                    </Box>
                    <Box mb={2}>
                      <label className={classes.label}>Role</label>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          id="role"
                          name="role"
                          value={data.role}
                          displayEmpty
                          onChange={onChange}
                        >
                          <MenuItem value={1}>User</MenuItem>
                          <MenuItem value={0}>Admin</MenuItem>
                          <MenuItem value={2}>Supplier</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box mb={2}>
                      <label className={classes.label}>User Status</label>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          id="active"
                          name="active"
                          value={data.active}
                          onChange={onChange}
                          displayEmpty
                        >
                          <MenuItem value={true}>Active</MenuItem>
                          <MenuItem value={false}>Disable</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={3} direction="row">
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    direction="column"
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      onClick={onSave}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </div>
  )
}
export default UserDetailsTab
