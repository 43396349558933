import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Grid,
    Button,
    CircularProgress,
    Divider,
    Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { format, addDays, isAfter, isBefore, parse, startOfDay, endOfDay } from 'date-fns';
import ScheduleResult from './ScheduleResult';
import JobService from "../../services/JobService"

const useStyles = makeStyles(() => ({
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500'
    },
    slotWrapper: {
        width: '90%',
        height: '76px',
        borderRadius: '8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#dedede',
        marginBottom: '20px',
        boxShadow: '0px 0px 10px 0px rgba(45,62,80,0.1)',
        "& button": {
            width: '100%',
            height: '76px',
            borderRadius: '8px',
            "&:hover": {
                color: '#fff'
            }
        },
        "&:hover": {
            background: "linear-gradient(267.74deg, #E4006D -7.37%, #F74A36 108.02%)",
            borderStyle: 'none',
            color: '#fff'
        },
    },
    slot: {
        "&:hover": {
            boxShadow: 'none',
            color: '#fff',
            "& svg": { fill: '#fff' }
        },
        "& span": {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'capitalize'
        }
    },
    interiorWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    timeSlot: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        "&:hover": { boxShadow: 'none', color: '#fff' }
    },
    timeIcon: {
        fill: '#E4006D',
        width: '14px',
        height: '14px',
        marginRight: '5px',
        "&:hover": { fill: '#fff' }
    },
    titleBoxTwo: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '60px',
        marginBottom: '20px'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '30px',
    },
    slotsFrom: {
        marginBottom: '30px'
    },
    shiftButtonsBox: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        marginRight: '10px'
    }
}));

const SlotsGrid = (props) => {
    const classes = useStyles();
    const {
        update,
        clientId,
        contractId,
        jobNo,
        clientRef,
        uprn,
        propertyId,
        targetDate
    } = props

    const parsedTargetDate = parse(targetDate, 'yyyy-MM-dd', new Date())
    const initialStartDateObj = addDays(parsedTargetDate, -7)
    const history = useHistory();
    const [slotList, setSlotList] = useState([]);
    const [updating, setUpdating] = useState(false)
    const [chosenSlot, setChosenSlot] = useState(false)
    const [selectedSlot, setSelectedSlot] = useState({})
    const [slotSuccess, setSlotSuccess] = useState(false)
    const [textResult, setTextResult] = useState("")
    const [targetDateObj, setTargetDateObj] = useState(parsedTargetDate)
    const [startDate, setStartDate] = useState(format(initialStartDateObj, 'yyyy-MM-dd'))
    const [startDateObj, setStartDateObj] = useState(startOfDay(initialStartDateObj))
    const endOrTargetDate = (start, target) => {
        return isAfter(addDays(start, 7), target)
            ? format(target, 'yyyy-MM-dd')
            : format(addDays(start, 7), 'yyyy-MM-dd')
    }
    const endOrTargetDateObj = (start, target) => {
        return isAfter(addDays(start, 7), target)
            ? endOfDay(target)
            : endOfDay(addDays(start, 7))
    }
    const [endDate, setEndDate] = useState(format(parsedTargetDate, 'yyyy-MM-dd'))
    const [endDateObj, setEndDateObj] = useState(endOfDay(parsedTargetDate))
    const [isBookingSlot, setIsBookingSlot] = useState(false)
    const [zeroSlotsReturned, setZeroSlotsReturned] = useState(false)
    const [drsError, setDrsError] = useState(false)
    const [showEarlier, setShowEarlier] = useState(false)
    const [showLater, setShowLater] = useState(false)

    useEffect(() => {
        if (update) {
            console.log(`Dates between ${startDate} and ${endDate}`)
            setUpdating(true);
            JobService.getAvailability(clientId, contractId, uprn, jobNo, clientRef, startDate)
                .then((response) => {
                    console.log(response);

                    if (response.success) {
                        console.log('success', response)
                        let result = response.event[0]?.slots;
                        if (result && result.length < 1) {
                            let message = result.error
                                ? result.error.message
                                : 'No slots returned'
                            setTextResult(message);
                            setZeroSlotsReturned(true);
                        }
                        else {
                            console.log('slots', result);
                            setZeroSlotsReturned(true);
                        }
                        setSlotList(result);
                        console.log('showEaelrier', isBefore(startOfDay(new Date()), startDateObj))
                        console.log('show later', isAfter(targetDateObj, endOfDay(endDateObj)))
                        setShowEarlier(isBefore(startOfDay(new Date()), startDateObj));
                        setShowLater(isAfter(targetDateObj, endOfDay(endDateObj)));
                    }
                    else {
                        setTextResult(response.error.message);
                        setZeroSlotsReturned(true);
                        setDrsError(true);
                    }
                })
                .catch((error) => console.warn(error))
                .finally(() => setUpdating(false));
        }

    }, [update, clientId, contractId, jobNo, clientRef, uprn, startDate])

    const returnTime = (start, end) => {
        let st = new Date(start);
        let et = new Date(end);
        return `${format(st, 'HH:mm')} - ${format(et, 'HH:mm')}`;
    }

    const setDates = (modifier) => {
        let newEnd = endOfDay(addDays(endDateObj, modifier));
        let newStart = startOfDay(addDays(startDateObj, modifier));
        setEndDateObj(newEnd)
        setStartDateObj(newStart);
        setStartDate(format(newStart, 'yyyy-MM-dd'))
        setEndDate(format(newEnd, 'yyyy-MM-dd'))
    }

    const goEarlier = () => {
        setDates(-7);
    }


    const goLater = () => {
        setDates(7)
    }

    const bookSlot = (slot) => {
        setChosenSlot(true);
        setSelectedSlot(slot);
        setIsBookingSlot(true);
        setTextResult('Booking slot...');
        JobService.bookSlot(clientId, contractId, uprn, jobNo, slot.id)
            .then((response) => {
                setSlotSuccess(response.success);
                setTextResult(response.success ? 'Slot Booked!' : `Unable to book slot: ${response.error?.message}`)
                setIsBookingSlot(false);
            })
            .catch((error) => console.warn(error));

    }

    const slotItems = (slots) => {
        return slots.map((slot) =>
            <Grid item xs={3} key={slot.id}>
                <Box className={classes.slotWrapper}>
                    <Button
                        onClick={() => bookSlot(slot)}
                        className={classes.slot}
                    >
                        <div className={classes.interiorWrapper}>
                            <div><b>{format(new Date(slot.startDate), "PPPP")}</b></div>
                            <div className={classes.timeSlot}><AccessTimeIcon className={classes.timeIcon} />{returnTime(slot.startDate, slot.endDate)}</div>

                        </div>
                    </Button>
                </Box>
            </Grid>

        )
    }

    const goBack = () => {
        history.push({
            pathname: `/schedule-job/${clientId}/${contractId}/${uprn}/${jobNo}/${clientRef}/${targetDate}/book`
        })
    }

    const onSuccessClick = () => {
        history.push(`/property/${propertyId}?tab=job-history`);
    }

    return (
        (updating)
            ? <div><div className={classes.titleBoxTwo}>
                <Typography className={classes.title} variant="h5">Schedule Job</Typography>
            </div>

                <Divider className={classes.divider} />
                <Grid container>
                    <div className="loading">
                        <h4 className={classes.itemTitle}>Getting slots...</h4>
                        <div>
                            <CircularProgress />
                        </div>
                    </div></Grid></div>
            : (chosenSlot)
                ? <ScheduleResult
                    success={slotSuccess}
                    message={textResult}
                    updating={isBookingSlot}
                    date={format(new Date(selectedSlot?.startDate), "PPPP")}
                    time={returnTime(selectedSlot?.startDate, selectedSlot?.endDate)}
                    continueButton={onSuccessClick}
                    backButton={goBack}
                />
                : (slotList && slotList.length > 0)
                    ? <div>
                        <div className={classes.titleBoxTwo}>
                            <Typography className={classes.title} variant="h5">Schedule Job</Typography>
                        </div>

                        <Divider className={classes.divider} />
                        <Grid container className={classes.slotsFrom}>
                            <Grid item xs={5}>
                                <Typography variant="h6">Available Slots from {startDate ? format(startDateObj, 'd MMM') : 'n/a'} to {endDate ? format(endDateObj, 'd MMM') : 'n/a'}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="p">Slots are available to book for 60 seconds</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.shiftButtonsBox}>
                                {
                                    (showEarlier) ?
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={goEarlier}
                                            type="button"
                                            className={classes.button}
                                            startIcon={<KeyboardArrowLeftIcon />}
                                        >
                                            Earlier
                                        </Button>
                                        : <div></div>
                                }

                                {(showLater) ?
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={goLater}
                                        type="button"
                                        className={classes.button}
                                        startIcon={<KeyboardArrowRightIcon />}
                                    >
                                        Later
                                    </Button>
                                    : <div></div>
                                }
                            </Grid>
                        </Grid>
                        <Grid container>
                            {slotItems(slotList)}
                        </Grid>
                        <Grid container>
                            <Grid item xs={4}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={goBack}
                                    type="button"
                                    className={classes.button}>
                                    Back
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    : (!updating)
                        ? drsError
                            ? <div>
                                <div className={classes.titleBoxTwo}>
                                    <Typography className={classes.title} variant="h5">Schedule Job</Typography>
                                </div>

                                <Divider className={classes.divider} />
                                <Grid container className={classes.slotsFrom}>
                                    <Grid item xs={8}>
                                        <Typography variant="h6">DRS Error</Typography>
                                        <p>{textResult}</p>
                                    </Grid>

                                </Grid>
                            </div>
                            : zeroSlotsReturned
                                ? <div>
                                    <div className={classes.titleBoxTwo}>
                                        <Typography className={classes.title} variant="h5">Schedule Job</Typography>
                                    </div>

                                    <Divider className={classes.divider} />
                                    <Grid container className={classes.slotsFrom}>
                                        <Grid item xs={8}>
                                            <Typography variant="h6">Available Slots from {startDate ? format(startDateObj, 'd MMM') : 'n/a'} to {endDate ? format(endDateObj, 'd MMM') : 'n/a'}</Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.shiftButtonsBox}>
                                            {
                                                (showEarlier) ?
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={goEarlier}
                                                        type="button"
                                                        className={classes.button}
                                                        startIcon={<KeyboardArrowLeftIcon />}
                                                    >
                                                        Earlier
                                                    </Button>
                                                    : <div></div>
                                            }

                                            {(showLater) ?
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={goLater}
                                                    type="button"
                                                    className={classes.button}
                                                    startIcon={<KeyboardArrowRightIcon />}
                                                >
                                                    Later
                                                </Button>
                                                : <div></div>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        No slots available for these dates
                                    </Grid>
                                </div>
                                : <div></div>
                        : <ScheduleResult
                            success={false}
                            message="Failed to retrieve slot information"
                            updating={isBookingSlot}
                            backButton={goBack}
                        />



    );
};
export default SlotsGrid;
