import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import Sidebar from '../../components/navigation/sidebar/Sidebar';
import Preloader from '../../components/Preloader/Preloader';
import ClientContext from "../../utils/globalClientContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: '1900',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    height: 'fit-content',
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: '1200',
    borderBottomLeftRadius: '25px',
    display: 'flex'
  },
  container: {
    padding: theme.spacing(5)
  },
  arrowImage: {
    position: 'fixed',
    bottom: '0px',
    left: '0px'
  }
}));

const MainLayout = (props) => {
    const classes = useStyles();
    const { showPreloader } = useContext(ClientContext);

    return (
      <Preloader open={showPreloader }>
    <div className={classes.root}>
          <Sidebar />
      <main className={classes.content}>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              {props.children}
            </Grid>
          </Grid>
        </Container>
      </main>
            </div>
        </Preloader>
  );
};

export default MainLayout;
