
import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Button,
    Grid,
    Divider
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useHistory } from 'react-router-dom'
import PropertyService from "../../services/PropertyService"
import ClientService from "../../services/ClientService"
import JobService from "../../services/JobService"
import InstructionService from "../../services/InstructionService"
import SlotsGrid from "../Jobs/SlotsGrid"
import ScheduleTextResult from "../Jobs/ScheduleTextResult"
import { format, addDays, isAfter, isBefore } from 'date-fns';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '20px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#E4006D'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4006D'
            }
        },
    },
    button: {
        width: '157px!important',
        height:'48px',
        marginBottom: '20px',
        textTransform: 'capitalize'
    },
    textarea: {
        height: '200px',
        paddingRight: '25px',
        marginTop: '8px',
    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500'
    },
    fieldContainer: {
        marginBottom: '25px',
    },
    titleBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        marginBottom: '20px'
    },
    titleBoxTwo: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '60px',
        marginBottom: '20px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '30px',
    },
    select: {
        marginRight: '25px',
        marginTop: '8px'
    },
    modalSelect: {
        marginTop: '8px'
    },
    input: {
        paddingRight: '25px',
        marginTop: '8px',
    },
    datePicker: {
        borderColor: '#E4006D',
        marginTop: '8px',
        marginRight: '25px',
        width: '100%',
        '& button': {
            width: 'auto',
        },
        '& .MuiButtonBase-root': {
            backgroundColor: 'transparent',
        }
    },
    iconButton: {
        padding: 0,
    },
    subNavigationItem: {
        marginRight: '20px',
        marginBottom: '60px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        width: 'auto'
    },
    check: {
        fill: '#6FCF97',
        fontSize: '29px',
        verticalAlign: 'middle',
        marginRight: '15px',
        marginBottom: '5px'
    }
}));

const ScheduleJob = () => {
    const params = useParams();
    const paramsObject = useMemo(() => {
        return {
            jobNo: params.jobNo,
            clientId: params.clientId,
            clientContractId: params.contractId,
            propertyId: params.propertyId,
            clientRef: params.clientRef,
            isCreate: params.book,
            targetDate: params.targetDate
        };
    }, [params]);
   
    const classes = useStyles();
    const history = useHistory()

    const goToJob = (obj) => {
        console.log(obj);
        history.push(`/job-detail/${params.jobNo}/${params.propertyId}/${params.clientId}`)
    }

    const [property, setProperty] = useState({});
    const [clientName, setClientName] = useState('Retrieving client details...')
    const [clientContractName, setClientContractName] = useState('Retrieving client details...')
    const [showSlots, setShowSlots] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [loading, setLoading] = useState(true)
    const [isComplexJob, setIsComplexJob] = useState(false)
    const [currentAppt, setCurrentAppt] = useState(null)
    
    useEffect(() => {
        if (property.address == null) {
            let cleanup = true;
            PropertyService.getByUprn(paramsObject?.clientId, paramsObject?.propertyId)
                .then(response => response.object)
                .then(data => {
                    if (cleanup) {
                        setProperty(data);
                    }
                })
                .catch((error) => console.warn(error))
                .finally(setLoading(false))

            return () => {
                cleanup = false;
            };
        }
    }, [paramsObject]);

    useEffect(() => {
        
            let cleanup = true;
            ClientService.getContractDetails(paramsObject?.clientContractId)
                .then((response) => {
                    if (cleanup) {
                        setClientName(response.clientName);
                        setClientContractName(response.clientContractName)
                    }
                })
                .catch((error) => console.warn(error))

            return () => {
                cleanup = false;
            };
    }, [paramsObject])

    useEffect(() => {

        let cleanup = true;

        JobService.getById(paramsObject?.jobNo, paramsObject?.uprn, paramsObject?.clientId)
            .then((response) => {
                if (cleanup) {
                    console.log('job', response);
                    let job = response.object;
                    setIsComplexJob(!job.isAppointable);
                    setShowButton(true)
                    if (job.isAppointed) {

                        InstructionService.getById(`${paramsObject?.jobNo}.1`, paramsObject.clientId)
                            .then((response) => {
                                console.log('instruction', response);
                                setCurrentAppt({
                                    startTime: response.object?.startTime,
                                    endTime: response.object?.endTime,
                                    operative: response.object?.operativeAssigned
                                })
                            })
                    }
                }
            })
            .catch((error) => console.warn(error))
            
        return () => {
            cleanup = false;
        };
    }, [paramsObject])

    const getSlots = (e) => {
        setShowButton(false);
        setShowSlots(true);
    }

    return (


        <div>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Button
                        variant="contained"
                        onClick={() => goToJob()}
                        startIcon={<ArrowBackIcon />}
                        className={classes.backBtn}
                    >
                        Back to Job
                    </Button>
                </Grid>
            </Grid>
            <div className={classes.titleBox}>
                <Typography className={classes.title} variant="h5">
                    <CheckCircleOutlineIcon className={classes.check} /> {paramsObject.isCreate==='book' ? 'Schedule Job': 'Job Saved Successfully'}
                </Typography>
            </div>

            
            <Divider className={classes.divider} />

            <Grid container >
                <Grid item xs={12} sm={(currentAppt) ? 4 : 12} className={classes.fieldContainer}>
                    <Box>
                        <Typography variant="body2" className={classes.itemTitle}>
                            Job Number
                        </Typography>
                        <Typography variant="body1" color="primary">
                            {paramsObject?.jobNo}
                        </Typography>
                    </Box>
                </Grid>
                {(currentAppt)
                    ? <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                        <Box>
                            <Typography variant="body2" className={classes.itemTitle}>
                                Existing Appointment
                            </Typography>
                            <Typography variant="body1" color="primary">
                                {format(new Date(currentAppt.startTime),"dd/MM/yyyy HH:mm")} - {format(new Date(currentAppt.endTime),"dd/MM/yyyy HH:mm")}
                            </Typography>
                        </Box>
                    </Grid>
                    : null
                }
                {(currentAppt)
                    ? 
                        <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                            <Box>
                                <Typography variant="body2" className={classes.itemTitle}>
                                    Operative
                                </Typography>
                                <Typography variant="body1" color="primary">
                                    {currentAppt.operative}
                                </Typography>
                            </Box>
                        </Grid>
                    : null
                }
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <Typography variant="body2" className={classes.itemTitle}>
                            Property
                        </Typography>
                        <Typography variant="body1" color="primary">
                            {(property.address != null ? `${property.address.addressLine1}, ${property.address.postCode}` : 'Retrieving address...')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <Typography variant="body2" className={classes.itemTitle}>
                            Client
                        </Typography>
                        <Typography variant="body1" color="primary">
                            {clientName}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <Typography variant="body2" className={classes.itemTitle}>
                            Client contract
                        </Typography>
                        <Typography variant="body1" color="primary">
                            {clientContractName}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            
            {isComplexJob
                ? <ScheduleTextResult text="This job requires manual scheduling. Please contact the operations team."></ScheduleTextResult>
                : isBefore(new Date(params.targetDate), new Date())
                    ? <ScheduleTextResult text="The target date for this job has passed. Please contact the operations team."></ScheduleTextResult>
                    : showButton
                        ? <div>
                            <div className={classes.titleBoxTwo}>
                                <Typography className={classes.title} variant="h5">Schedule Job</Typography>
                            </div>

                            <Divider className={classes.divider} />

                            <Button
                                disabled={property.address==null }
                            variant="contained"
                            color="primary"
                            onClick={(e) => getSlots(e)}
                            type="button"
                            className={classes.button}
                            startIcon={<AccessTimeIcon />}
                        >
                            Get Slots
                        </Button>
                        </div>

                        : (loading)
                            ? <div>Loading...</div>
                            : <SlotsGrid
                                clientId={paramsObject.clientId}
                                contractId={paramsObject?.clientContractId}
                                propertyId={property?.propertyId}
                                uprn={property?.uprn}
                                jobNo={paramsObject?.jobNo}
                                clientRef={paramsObject?.clientRef}
                                update={showSlots}
                                targetDate={paramsObject.targetDate}
                        />
            }

            
                
            
        </div>

    );
};

export default ScheduleJob;