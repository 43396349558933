import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  // TablePagination,
  CircularProgress
} from '@material-ui/core'
import ResultsTable from '../ui/ResultsTable/ResultsTable'
import UserService from '../../services/UserService'
import Moment from 'moment'

const useStyles = makeStyles((theme) => ({
  preloader: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 0'
  }
}))

const SignInLogsTab = ({ userId }) => {
  const classes = useStyles()

  const [signInLogs, setSignInLogs] = useState([])

  const [loading, setLoading] = useState(false)
  // const [page, setPage] = useState(0)
  // const [size, setSize] = useState(100)
  // const [length, setLength] = useState(0)

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage)
  // }

  // const handleChangeSize = (event) => {
  //   setSize(event.target.value)
  //   setPage(0)
  // }

  useEffect(() => {
    setLoading(true)
    UserService.getSignInsLog({ id: userId })
      .then((response) => {
        const items = response.collection.map((item) => {
          return {
            date: item.date
              ? Moment(item.date).format('DD-MM-YYYY HH:mm')
              : null,
            user: item.userName,
            application: item.appDisplayName,
            status: item.status,
            ipAddress: item.ipAddress,
            location: `${item.location?.city} ${item.location?.state} ${item.location?.countryOrRegion}`
          }
        })

        // setLength(response.totalRecords)
        setSignInLogs(items)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => setLoading(false))

    return function cleanup() {
      setLoading(false)
    }
  }, [userId])

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12}>
          {loading ? (
            <div className={classes.preloader}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <ResultsTable
              columns={[
                {
                  name: 'date',
                  label: 'Date',
                  type: 'date'
                },
                {
                  name: 'user',
                  label: 'User'
                },
                {
                  name: 'application',
                  label: 'Application'
                },
                {
                  name: 'status',
                  label: 'Status'
                },
                {
                  name: 'ipAddress',
                  label: 'IP Address'
                },
                {
                  name: 'location',
                  label: 'Location'
                }
              ]}
              data={signInLogs}
              orderBy="date"
              direction="desc"
            ></ResultsTable>
          )}
          {/* <TablePagination
            rowsPerPageOptions={[10, 50, 100, 500]}
            component="div"
            count={length}
            rowsPerPage={size}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeSize}
          /> */}
        </Grid>
      </Grid>
    </div>
  )
}
export default SignInLogsTab
