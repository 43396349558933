import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Button,
  Box,
  Typography,
  Tab,
  Tabs,
  Grid,
  TextField
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PropTypes from 'prop-types'
import UserDetailsTab from '../../components/UserDetailsTab/UserDetailsTab'
import ClientsTab from '../../components/ClientsTab/ClientsTab'
import SignInLogsTab from '../../components/SignInLogsTab/SignInLogsTab'
import AuditLogsTab from '../../components/AuditLogsTab/AuditLogsTab'
import { useHistory } from 'react-router-dom'
import BasicModal from '../../components/ui/BasicModal/BasicModal'
import UserService from '../../services/UserService'
import DeleteIcon from '../../components/ui/Icons/DeleteIcon'
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #E6ADA5'
  },
  indicator: {
    backgroundColor: '#E4006D'
  }
})(Tabs)

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 700,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#E4006D',
      opacity: 1
    },
    '&:selected': {
      color: '#E4006D',
      fontWeight: 700
    },
    '&:focus': {
      color: '#E4006D'
    }
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />)

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '20px',
    marginBottom: '20px'
  },
  title: {
    borderRight: '1px solid #BCBEBE',
    paddingRight: '10px'
  },
  subTitle: {
    paddingLeft: '10px',
    color: '#BCBEBE'
  },
  tabs: {
    backgroundColor: theme.palette.background.paper
  },
  deleteBtn: {
    margin: 'auto 10px',
    height: '52px',
    width: '30%!important',
    textTransform: 'none'
  },
  label: {
    marginBottom: '10px',
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500',
    fontSize: '12px'
  },
  contentBox: {
    padding: '30px 50px 10px 50px'
  },
  rotateLeftIcon: {
    fontSize: '24px !important'
  }
}))

const USER_INITIAL = {
  firstName: '',
  surname: '',
  companyName: '',
  email: '',
  active: true,
  role: 1
}

const ManageUsers = ({
  match: {
    params: { userId }
  }
}) => {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openResetModal, setOpenResetModal] = useState(false)
  const [user, setUser] = useState(USER_INITIAL)
  const [userEmail, setUserEmail] = useState('')
  const [isRegisteredInAuthenticationApp, setIsRegisteredInAuthenticationApp] = useState(false)

  useEffect(() => {
    handleGetUserById();
  }, [userId])

  const handleUpdateUser = useCallback(() => {
    setLoading(true)
    const { firstName, surname, companyName, active, role } = user
    UserService.updateUser(userId, {
      firstName: firstName.length ? firstName : null,
      surname: surname.length ? surname : null,
      companyName: companyName.length ? companyName : null,
      disable: !active,
      role
    })
      .then(() => { })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))
  }, [userId, user])

  const handleGetUserById = () => {
    setLoading(true)
    UserService.getById(userId)
      .then((response) => {
        const data = {
          firstName: response.object.firstName || '',
          surname: response.object.surname || '',
          companyName: response.object.companyName || '',
          email: response.object.username || '',
          emailError: '',
          active: response.object.activeStatus ? true : false,
          role:
            response.object.userRole === 'Admin'
              ? 0
              : response.object.userRole === 'User'
                ? 1
                : 2
        }
        setUserEmail(data.email)
        setIsRegisteredInAuthenticationApp(response.object.isRegisteredInAuthenticationApp)
        setUser(data)
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))
  }

  const handleDelete = () => {
    UserService.deleteUser(userId)
        .then((response) => {
        setOpenDeleteModal(false)
        if (response.succeeded) {
          history.push('/admin')
        }
      })
      .catch((error) => console.error(error))
  }
  const handleResetMFA = () => {
    UserService.resetUserMFADetails(userId)
      .then((response) => {
        setOpenResetModal(false)
        if (response.succeeded) {
          handleGetUserById();
        }
      })
      .catch((error) => console.error(error))
  }
  const onUserHandler = useCallback(
    ({ target: { name, value } }) => {
      const next = { ...user, [name]: value, [`${name}Error`]: '' }
      setUser(next)
    },
    [user]
  )

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true)
  }

  const handleOpenResetMFAModal = () => {
    setOpenResetModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }
  const handleCloseResetMFAModal = () => {
    setOpenResetModal(false)
  }
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue)
  }

  return (
    <div>
      <div>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.goBack()}
        >
          Back to Users List
        </Button>
      </div>
      <Grid container direction="row" spacing={1} alignItems="center">
        <Grid item xs={7} container direction="column">
          <div className={classes.titleBox}>
            <Typography className={classes.title} variant="h4">
              Manage User
            </Typography>
            <Typography
              className={classes.subTitle}
              variant="subtitle1"
              gutterBottom
            >
              {userEmail}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={5} container direction="column">
            <Box display="flex" justifyContent="flex-end">
              {isRegisteredInAuthenticationApp && (
                <Button
                  variant="contained"
                  className={classes.deleteBtn}
                  color="primary"
                  startIcon={<RotateLeftIcon className={classes.rotateLeftIcon} />}
                  onClick={handleOpenResetMFAModal}
                >
                Reset MFA
              </Button>)}
              <Button
                variant="contained"
                className={classes.deleteBtn}
                color="primary"
                startIcon={<DeleteIcon />}
                onClick={handleOpenDeleteModal}
              >
                Delete User
              </Button>
            </Box>
          </Grid>
      </Grid>
      <div>
        <div className={classes.tabs}>
          <AntTabs
            value={currentTab}
            onChange={handleChangeTab}
            aria-label="ant example"
          >
            <AntTab label="USER DETAILS" />
            <AntTab label="CLIENTS" />
            <AntTab label="SIGN-IN-LOGS" />
            <AntTab label="AUDIT LOGS" />
          </AntTabs>
          <TabPanel value={currentTab} index={0}>
            <UserDetailsTab
              loading={loading}
              data={user}
              onChange={onUserHandler}
              onSave={handleUpdateUser}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <ClientsTab userId={userId} />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <SignInLogsTab userId={userId} />
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <AuditLogsTab userId={userId} />
          </TabPanel>
        </div>
      </div>
      <BasicModal
        headerIcon="/icons/admin-delete-icon.svg"
        headerTitle="Confirm user deletion"
        submitButtonText="Confirm"
        onClose={handleCloseDeleteModal}
        onSubmit={handleDelete}
        dialogContent={() => (
          <Box className={classes.contentBox}>
            <Box mb={2}>
              <label className={classes.label}>Email</label>
              <TextField
                className={classes.input}
                fullWidth
                id="username"
                name="username"
                value={userEmail}
                InputProps={{
                  readOnly: true
                }}
              />
            </Box>
          </Box>
        )}
        open={openDeleteModal}
      ></BasicModal>
      <BasicModal
        headerTitle="Confirm reset MFA"
        submitButtonText="Confirm"
        onClose={handleCloseResetMFAModal}
        onSubmit={handleResetMFA}
        dialogContent={() => (
          <Box className={classes.contentBox}>
            <Box mb={2}>
              <label className={classes.label}>Email</label>
              <TextField
                className={classes.input}
                fullWidth
                id="username"
                name="username"
                value={userEmail}
                InputProps={{
                  readOnly: true
                }}
              />
            </Box>
          </Box>
        )}
        open={openResetModal}
      ></BasicModal>
    </div>
  )
}

export default ManageUsers
