import React, { useState, useEffect } from 'react'
import ModalContainer from "../ModalContainer/ModalContainer"
import ResultsTable from 'components/ui/ResultsTable/ResultsTable'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import {
Box,
Button,
Grid,
Divider,
TextField,
} from '@material-ui/core'
import PurchaseOrderService from "../../../services/PurchaseOrderService";

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'none',
        width: 'fit-content!important',
        padding: '10px 30px'
    },
    label: {
        marginBottom: '10px',
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
        fontSize: '12px'
    },
    textarea: {
        height: '200px',
    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500'
    },
    fieldContainer: {
        marginBottom: '20px',
    },
    titleBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        marginBottom: '20px'
    },
    title: {
        borderRight: '1px solid #BCBEBE',
        paddingRight: '10px',
        fontWeight: '600',
        marginBottom: '10px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginTop: '20px',
    },
    select: {
        marginRight: '25px',
        marginTop: '8px'
    },
    modalSelect: {
        marginTop: '8px'
    },
    input: {
        marginTop: '8px'
    },
    buttonContainer: {
        justifyContent: 'flex-end',
    },
    searchInput: {
        background: '#ffffff',
        boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.10)',
        border: 'none'
    },
    searchButton: {
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        height: '100%',
        minWidth: '100%'
    },
    subNavigationItem: {
        margin: '20px 0px 0px 20px',
        marginBottom: '60px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        minWidth: '100px'
    },

}))

export default function ApprovalModal(props) {

const {
    open,
    onSubmit,
    onClose,
    clientContractID,
    canEdit
} = props


const classes = useStyles();

    const [searchedApprovers, setSearchedApprovers] = useState([]);
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        if (clientContractID && clientContractID !== '') {
            getApprovers(clientContractID);
        }
    }, [clientContractID])

    const getApprovers = () => {
        PurchaseOrderService.getApprovers(clientContractID)
            .then((response) => {
                updateSearchedApprovers(response.value.collection);
            })
            .catch((error) => console.warn(error))
    }

    const updateSearchedApprovers = (allApprovers) => {
        let inCollection = [];
        allApprovers.forEach(item => {
            if (item.approverName.toUpperCase().includes(searchText.toUpperCase())) {
                inCollection.push(item);
            }
        })
        setSearchedApprovers(inCollection);
    }

    const handleSearch = (event) => {       
        getApprovers();
    }

    const updateApprover = (chosen) => {
        onSubmit(chosen);
    }

const handleSearchChange = (event) => {
    setSearchText(event.target.value);
};

return (
    <ModalContainer
        open={open}
        onClose={onClose}
        modalContent={() => (

            <Grid container>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={9} className={classes.fieldContainer}>
                        <Box>
                            <TextField
                                className={classes.searchInput}
                                variant="outlined"
                                fullWidth
                                name="search"
                                placeholder="Search"
                                value={searchText}
                                onChange={handleSearchChange }
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} className={classes.fieldContainer}>
                        <Button
                            className={classes.searchButton}
                            variant="contained"
                            fullWidth
                            color="primary"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                    </Grid>

                </Grid>

                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>

                <Grid container>
                    <Grid item xs={12} className={classes.fieldContainer}>

                        <ResultsTable
                            columns={[
                                {
                                    name: 'approverID',
                                    label: 'Id',
                                    hidden: true
                                },
                                {
                                    name: 'approverName',
                                    label: 'Approver'
                                },
                                {
                                    name: 'orderLimit',
                                    label: 'Order Limit',
                                },
                            ]}
                            data={searchedApprovers}
                            onRowClick={updateApprover}
                            linkKey="approverId"
                            orderBy="approverName"
                            direction="desc"
                        />
                    </Grid>
                </Grid>

                <Grid container className={classes.buttonContainer}>
                    <Box>
                        <Button
                            className={classes.subNavigationItem}
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        {canEdit &&
                            <Button
                                className={classes.subNavigationItem}
                                variant="contained"
                                color="primary"
                                onClick={onClose}
                            >
                                OK
                            </Button>

                        }
                        
                    </Box>
                </Grid>

            </Grid>
        )}
    />
);
}
