import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ResultsTable from '../ui/ResultsTable/ResultsTable'
import PropertyService from '../../services/PropertyService'
import { makeStyles } from '@material-ui/core/styles'
import {
    CircularProgress
} from '@material-ui/core'
import Moment from 'moment'

function createJobItem(
  id,
  dateraised,
  jobtype,
  jobnumber,
  clientjobnumber,
  jobstatus,
  priority,
    targetcompletiondate,
    clientId,
    uprn,
  description
) {
  return {
    id,
    dateraised,
    jobtype,
    jobnumber,
    clientjobnumber,
    jobstatus,
    priority,
      targetcompletiondate,
      clientId,
      uprn,
    description
  }
}


const useStyles = makeStyles((theme) => ({
    preloader: {
        display: 'flex',
        justifyContent: 'center',
        padding: '40px 0'
    }
}))


const JobHistoryTab = ({ propertyId, clientId }) => {
    const [jobs, setJobs] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory()
    const classes = useStyles()

    const getJobs = (id, clientId) => {
        if (clientId !== undefined && clientId!=='') {
            PropertyService.getJobHistory(id, clientId)
                .then((response) =>
                {
                     console.log('history',response.collection)
                    const items = response.collection.map((i) =>
                        createJobItem(
                            i.jobId,
                            i.dateRaised
                                ? Moment(i.dateRaised).format('DD-MM-YYYY HH:mm')
                                : null,
                            i.jobType?.literalText,
                            i.jobNumber,
                            i.clientJobNumber,
                            i.jobStatus?.literalText,
                            i.priority?.literalText,
                            i.targetCompletionDate
                                ? Moment(i.targetCompletionDate).format('DD-MM-YYYY HH:mm')
                                : null,
                            i.property?.clientId,
                            i.property?.uprn,
                            i.jobDescription
                        )
                    )

                    setJobs(items)
                    setIsLoading(false)
                })
                .catch((e) => {
                    console.log(e)
                    setIsLoading(false)
                })
        }
    
  }

  useEffect(() => {
    getJobs(propertyId, clientId)
  }, [propertyId, clientId])

    const goToJob = (obj) => {
        history.push(`/job-detail/${obj.jobnumber}/${obj.uprn}/${obj.clientId}`)
    }

  return (
      <div>
          {isLoading || !jobs ? (
              <div className={classes.preloader}>
                  <CircularProgress color="secondary" />
              </div>
          ) : (
              <ResultsTable
                  columns={[
                      {
                          name: 'id',
                          label: 'Id',
                          hidden: true
                      },
                      {
                          name: 'uprn',
                          label: 'uprn',
                          hidden: true
                      },
                      {
                          name: 'clientId',
                          label: 'clientId',
                          hidden: true
                      },
                      {
                          name: 'dateraised',
                          label: 'Date Raised',
                          type: 'date'
                      },
                      {
                          name: 'jobtype',
                          label: 'Job Type'
                      },
                      {
                          name: 'jobnumber',
                          label: 'Job Number'
                      },
                      {
                          name: 'clientjobnumber',
                          label: 'Client Job Number'
                      },
                      {
                          name: 'jobstatus',
                          label: 'Job Status'
                      },
                      {
                          name: 'priority',
                          label: 'Priority'
                        },
                        {
                            name: 'description',
                            label: 'Description'
                        },
                      {
                          name: 'targetcompletiondate',
                          label: 'Target Completion Date'
                      }
                  ]}
                  data={jobs}
                  onRowClick={goToJob}
                  orderBy="dateraised"
                  direction="desc"
              ></ResultsTable>)}
    </div>
  )
}

export default JobHistoryTab
