import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Divider,
  Button,
  Box,
  Select,
  FormControl,
  MenuItem,
  Typography,
  Grid,
  CircularProgress,
  Checkbox,
  ListItemText,
  InputLabel
} from '@material-ui/core'
import ResultsTable from '../../components/ui/ResultsTable/ResultsTable'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useHistory } from 'react-router-dom'
import ClientService from '../../services/ClientService'
import ApplianceService from '../../services/ApplianceService'
import ReportService from '../../services/ReportService'
import { CsvBuilder } from 'filefy'
import { downloadFile } from '../../utils/fileConverter'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  variant: 'menu',
  getContentAnchorEl: null
}
const defaultApplianceType = {
  value: -1,
  label: 'All'
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#E4006D'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E4006D'
      }
    }
  },
  title: {
    fontWeight: '600',
    marginBottom: '10px'
  },
  titleBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '20px',
    marginBottom: '20px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    width: '40%!important'
  },
  divider: {
    backgroundColor: '#E6ADA5'
  },
  input: {
    borderRadius: '30px'
  },
  label: {
    marginBottom: '10px',
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500',
    fontSize: '12px'
  },
  downloadImgBtn: {
    textTransform: 'none',
    float: 'right',
    padding: '10px 25px',
    fontSize: '16px',
    width: 'auto'
  },
  subTitle: {
    color: '#E4006D',
    fontSize: '12px',
    fontWeight: 700
  },
  downloadReports: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '20px'
  },
  backbtn: {
    marginBottom: '30px'
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 0'
  }
}))

const CLIENT_INITIAL = { value: '', label: '' }

const AssertReports = (props) => {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState([])
  const [clients, setClients] = useState([])
  const [applianceTypes, setApplianceTypes] = useState([])
  const [client, setClient] = useState(CLIENT_INITIAL)
  const [selectedApplianceTypes, setSelectedApplianceTypes] = useState([])
  const [isSearched, setSearched] = useState(false)
  const [applianceTypeSelectState, setApplianceTypeSelectState] = useState(true)

  const getClientNames = () => {
    setLoading(true)
    ClientService.getMyClients()
      .then((response) => {
        const items = response.collection
          .sort((item) => item.clientName)
          .map((item) => ({
            value: item.clientId,
            label: item.clientName
          }))

        if (items.length === 1) {
          setClient(items[0])
        }

        setClients(items)
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }

  const getApplianceTypes = () => {
    setLoading(true)
    ApplianceService.getTypes()
      .then((response) => {
        const items = [defaultApplianceType];
        items.push(...response.collection
          .sort((item) => item.clientName)
          .map((item) => ({
            value: item.value,
            label: item.label
          })));
        setApplianceTypes(items)
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }

  const handleChangeClient = (event) => {
    setClient(event.target.value)
  }

  const handleChangeApplianceType = (event) => {
    const changedApplianceTypes = [];

    if(selectedApplianceTypes.findIndex(s => s.label == defaultApplianceType.label) !=-1 &&
      event.target.value.findIndex(s => s.label ==defaultApplianceType.label) == -1
    ){
    }else if(selectedApplianceTypes.findIndex(s => s.label ==defaultApplianceType.label) == -1 && 
      event.target.value.findIndex(s => s.label == defaultApplianceType.label) != -1 
    ){
      changedApplianceTypes.push(...applianceTypes);
    }
    else if(selectedApplianceTypes.findIndex(s => s.label ==defaultApplianceType.label) != -1 && 
    event.target.value.findIndex(s => s.label == defaultApplianceType.label) != -1){
      event.target.value = deleteDefaultApplianceType(event.target.value);
      changedApplianceTypes.push(...event.target.value);
    }
    else if(event.target.value.length == applianceTypes.length - 1){
      changedApplianceTypes.push(defaultApplianceType, ...event.target.value);
    }
    else{
      changedApplianceTypes.push(...event.target.value);
    }

    setSelectedApplianceTypes(changedApplianceTypes);
  }

  const handleSearch = (event) => {
    setSearched(true)
  }

  const deleteDefaultApplianceType = (applianceTypes) => {
    const result = [...applianceTypes]
    const index = result.indexOf(defaultApplianceType);
    if (index > -1) {
      result.splice(index, 1);
    }
    return result;
  }

  const handleOnDownloadAssetReports = () => {
    setLoading(true);
    const selectedApplianceTypesWithoutDefult = deleteDefaultApplianceType(selectedApplianceTypes);
    ReportService.downloadAssetReports({
      clientId: client?.value,
      applianceTypes: selectedApplianceTypesWithoutDefult.map((type) => type.value)
    })
      .then((response) => {
        if (response) {
          downloadFile(`AssetReport.csv`, response)
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  const handleOnExport = () => {
    let data = []

    reports.forEach((report) => {
      data.push(Object.values(report))
    })

    new CsvBuilder('AssetReport.csv')
      .setColumns([
        'UPR',
        'Address Line 1',
        'Address Line 2',
        'Address Line 3',
        'City',
        'County',
        'Postcode',
        'Type',
        'Manufacturer',
        'Model',
        'Location',
        'Ownership'
      ])
      .addRows(data)
      .exportFile()
  }

  useEffect(() => {
    getClientNames()
    getApplianceTypes()
  }, [])

  useEffect(() => {
    if (isSearched) {
      setLoading(true)
      const selectedApplianceTypesWithoutDefult = deleteDefaultApplianceType(selectedApplianceTypes);
      ReportService.getAllAsserts({
        clientId: client?.value,
        applianceTypes: selectedApplianceTypesWithoutDefult.map((type) => type.value)
      })
        .then((response) => {
          const items = response.value.collection.map((item) => ({
            uprn: item.uprn,
            addressLine1: item.address?.addressLine1,
            addressLine2: item.address?.addressLine2,
            addressLine3: item.address?.addressLine3,
            city: item.address?.addressLine3,
            county: item.address?.addressLine4,
            postcode: item.address?.postCode,
            type: item.type?.literalText,
            manufacturer: item.manufacturer?.literalText,
            model: item.model?.literalText,
            location: item.location?.literalText,
            ownership: item.ownership?.literalText
          }))

          setReports(items)
        })
        .catch((error) => console.warn(error))
        .finally(() => setLoading(false))
    }
  }, [applianceTypes, client, isSearched, selectedApplianceTypes])

  return (
    <div>
      {!isSearched ? (
        <div>
          <Button
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIcon />}
            className={classes.backbtn}
          >
            Back to Reports List
          </Button>
          <div>
            <Typography className={classes.title} variant="h5">
              Asset Report
            </Typography>
          </div>
          <Divider className={classes.divider} />
          {loading ? (
            <div className={classes.preloader}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <React.Fragment>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <form className={classes.root} noValidate autoComplete="off">
                    <Box mt={3} mb={4}>
                      <label className={classes.label}>Client Name</label>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <Select
                          id="select-client-name"
                          displayEmpty
                          value={client}
                          onChange={handleChangeClient}
                          disabled={!clients || clients.length === 1}
                        >
                          {clients?.length !== 1 ? (
                            <MenuItem key={0} value={CLIENT_INITIAL}>
                              All
                            </MenuItem>
                          ) : null}
                          {clients &&
                            clients.map((item) => (
                              <MenuItem key={item.value} value={item}>
                                {item.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box mt={3} mb={4}>
                      <label className={classes.label}>Appliance Type</label>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        {applianceTypeSelectState &&
                        selectedApplianceTypes.length < 1 ? (
                          <InputLabel shrink={false}>
                            Select One or More Appliance Types
                          </InputLabel>
                        ) : null}
                        <Select
                          labelId="select-appliance-types-label"
                          id="select-appliance-types"
                          multiple
                          value={selectedApplianceTypes}
                          onOpen={() => setApplianceTypeSelectState(false)}
                          onClose={() => setApplianceTypeSelectState(true)}
                          onChange={handleChangeApplianceType}
                          renderValue={(selected) =>
                            selected.map((i) => i.label).join(', ')
                          }
                          MenuProps={MenuProps}
                        >
                          {applianceTypes &&
                            applianceTypes.map((item) => (
                              <MenuItem key={item.value} value={item}>
                                <Checkbox
                                  checked={selectedApplianceTypes.some(
                                    (i) => i.value === item.value
                                  )}
                                />
                                <ListItemText primary={item.label} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      disabled={selectedApplianceTypes.length < 1}
                      onClick={handleOnDownloadAssetReports}
                    >
                      Download Report
                    </Button>
                  </form>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8}></Grid>
            </React.Fragment>
          )}
        </div>
      ) : (
        <div>
          <Button
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIcon />}
            className={classes.backbtn}
          >
            Back to Reports List
          </Button>
          <div>
            <Typography className={classes.title} variant="h5">
              {client.label}
            </Typography>
          </div>

          <Box className={classes.downloadReports}>
            <Typography className={classes.subTitle} variant="h6">
              ASSET REPORT
            </Typography>
            <Button
              variant="contained"
              className={classes.downloadImgBtn}
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={handleOnExport}
              disabled={!reports || reports.length === 0}
            >
              Download Report
            </Button>
          </Box>

          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} sm={12}>
              {loading ? (
                <div className={classes.preloader}>
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <ResultsTable
                  columns={[
                    {
                      name: 'uprn',
                      label: 'UPRN'
                    },
                    {
                      name: 'addressLine1',
                      label: 'Address Line 1'
                    },
                    {
                      name: 'addressLine2',
                      label: 'Address Line 2'
                    },
                    {
                      name: 'addressLine3',
                      label: 'Address Line 3'
                    },
                    {
                      name: 'city',
                      label: 'City'
                    },
                    {
                      name: 'county',
                      label: 'County'
                    },
                    {
                      name: 'postcode',
                      label: 'Postcode'
                    },
                    {
                      name: 'type',
                      label: 'Type'
                    },
                    {
                      name: 'manufacturer',
                      label: 'Manufacturer'
                    },
                    {
                      name: 'model',
                      label: 'Model'
                    },
                    {
                      name: 'location',
                      label: 'Location'
                    },
                    {
                      name: 'ownership',
                      label: 'Ownership'
                    }
                  ]}
                  data={reports}
                ></ResultsTable>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default AssertReports
