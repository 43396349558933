
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Button,
    Grid,
    Divider,
    FormControl,
    IconButton,
    Link,
    TextField,
    Select,
    MenuItem
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ResultsTable from '../../components/ui/ResultsTable/ResultsTable'
import EditIcon from "../../components/ui/Icons/EditIcon"
import DeleteIcon from '../../components/ui/Icons/DeleteIcon';
import DeliveryDetailsModal from "../../components/ui/DeliveryDetailsModal/DeliveryDetailsModal"
import OrderDetailsModal from "../../components/ui/OrderDetailsModal/OrderDetailsModal"
import OrderDetailsEditModal from "../../components/ui/OrderDetailsModal/OrderDetailsEditModal"
import ApproverModal from "../../components/ui/ApproverModal/ApproverModal"
import PrintEmailModal from "../../components/ui/PrintEmailModal/PrintEmailModal"
import BasicModal from "../../components/ui/BasicModal/BasicModal"
import { useHistory, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import PurchaseOrderService from "../../services/PurchaseOrderService"
import ClientContext from "../../utils/globalClientContext"

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '20px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#E4006D'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4006D'
            }
        },
    },
    button: {
        width: 'auto',
        marginBottom: '20px',
    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500'
    },
    fieldContainer: {
        marginBottom: '25px',
    },
    titleBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        marginBottom: '20px'
    },
    title: {
        borderRight: '1px solid #BCBEBE',
        paddingRight: '10px',
        fontWeight: '600',
        marginBottom: '10px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '30px',
    },
    select: {
        marginRight: '25px',
        marginTop: '8px'
    },
    modalSelect: {
        marginTop: '8px'
    },
    input: {
        paddingRight: '25px',
        marginTop: '8px',
    },
    textarea: {
        height: '200px',
        paddingRight: '25px',
        marginTop: '8px',
    },
    datePicker: {
        borderColor: '#E4006D',
        marginTop: '8px',
        marginRight: '25px',
        width: '100%',
        '& button': {
            width: 'auto',
        },
        '& .MuiButtonBase-root': {
            backgroundColor: 'transparent',
        }
    },
    iconButton: {
        padding: 0,
    },
    subNavigationItem: {
        marginRight: '20px',
        marginBottom: '60px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        width: 'auto'
    },
    orderDetailsTable: {
        marginBottom: '40px',
    },
    approverContainer: {
        position: 'relative',
    },
    changeApprover: {
        position: 'absolute',
        right: '50px',
        top: '52%',
    },


}));


const PurchaseOrderDetails = (props) => {

    const classes = useStyles();
    const history = useHistory()
    const poId = useParams();
    const { setShowGlobalClientList, setShowPreloader, globalUserRole } = useContext(ClientContext);
    const [loading, setLoading] = useState(true)

    const handleGoToSearch = () => {
        history.goBack();
    }

    const [select, setSelect] = useState({
        supplierID: "",
        approverName: "",
        approvalStatus: { literalText: "n/a" },
        purchaseOrderStatus: { literalText: "n/a" },
        purchaseOrderType: { literalText: "n/a" },
        instructionNumber: "",
        descriptionOfWorks: "",
        initialCost: 0,
        currentCost: 0,
        selectAddress: "",
        contactName: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        postcode: "",
        telephone: "",
        email: "",
        supplierBranch: "",
        supplierTicket: "",
        clientContractName: "",
        clientContractID: "",
        createdOn: "01/01/2001 10:00",
        orderLines: [],
        deliveryDetails: {},
        jobStatus: 3,
        jobStatusName: 'Parts Needed',
        jobID: '',
        jobNumber: ''
    }, loading);

    const [canEdit, setCanEdit] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [deliveryDetailsModal, setDeliveryDetailsModal] = useState(false);
    const [orderDetailsModal, setOrderDetailsModal] = useState(false);
    const [orderDetailsEditModal, setOrderDetailsEditModal] = useState(false);
    const [approverModal, setApproverModal] = useState(false);
    const [printEmailModal, setPrintEmailModal] = useState(false);
    const [cancelPOModal, setCancelPOModal] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedDetailItem, setSelectedDetailItem] = useState({ quantity: 0, comments: "" });
    const [selectedDetailIndex, setSelectedDetailIndex] = useState(-1);
    

    const statusMenuItems = [{ value: 3, name: "Parts Needed" }, { value: 7, name: "Parts Authorised" }, { value: 5, name: "Referred to Supervisor" }, { value: 8, name: "Parts Ordered" }, { value: 9, name: "Parts In" }];


    const addOrderLineButtons = (lines) => {
        const newLines = JSON.parse(JSON.stringify(lines))

        if (canEdit) {
            newLines.forEach((line, i) => {
                line.edit = <IconButton className={classes.iconButton} aria-label="edit" component="span" lineid={i} onClick={handleEditOrderLine}> <EditIcon fill="#9B9D9D" /> </IconButton>;
                line.delete = <IconButton className={classes.iconButton} aria-label="edit" component="span" lineid={i} onClick={handleDeleteOrderLine}> <DeleteIcon fill="#9B9D9D" /> </IconButton >
            })
        }
        
        return newLines;
    }

    const handleEditOrderLine = (event) => {
        let itemIndex = event.currentTarget.getAttribute("lineid")
        setSelectedDetailItem(select.orderLines[itemIndex]);
        setSelectedDetailIndex(itemIndex);
        setOrderDetailsEditModal(!orderDetailsEditModal)
    }

    const handleDeleteOrderLine = (event) => {
        let itemIndex = event.currentTarget.getAttribute("lineid")
        let lineToDelete = select.orderLines[itemIndex];
        let updatedLines = select.orderLines.toSpliced(itemIndex, 1);
        let updateCost = select.currentCost - lineToDelete.lineValue;

        let updateVals = {
            currentCost: updateCost.toFixed(2),
            orderLines: updatedLines
        }
        setSelect(select => ({
            ...select,
            ...updateVals
        }));
    }

    const getPurchaseOrder = (id) => {
        let jobDetails;

        PurchaseOrderService.getJobDetails(id)
            .then((response) => {
                let poIsOnContract = response.status !== null;
                jobDetails = {
                    jobStatus: poIsOnContract ? parseInt(response.status.code) : 0,
                    jobStatusName: poIsOnContract ? response.status.literalText : 'n/a',
                    jobNumber: poIsOnContract ? response.jobNumber : '',
                    jobID: poIsOnContract ? response.jobId : '00000000-0000-0000-0000-000000000000'
                }

                PurchaseOrderService.getById(id)
                    .then((response) => {
                        const result = response.object
                        console.log(result);

                        setSelect({
                            purchaseOrderID: result.purchaseOrderId,
                            purchaseOrderNumber: result.purchaseOrderNumber,
                            instructionNumber: result.instructionNumber,
                            instructionID: result.instructionID,
                            purchaseOrderStatus: result.purchaseOrderStatus,
                            approvalStatus: result.approvalStatus,
                            purchaseOrderType: result.purchaseOrderType,
                            descriptionOfWorks: result.descriptionOfWorks,
                            createdOn: result.createdOn,
                            createdBy: result.createdBy,
                            initialCost: result.initialCost,
                            currentCost: result.currentCost,
                            approverName: result.approverName,
                            approverID: result.approverID,
                            orderLimit: result.orderLimit,
                            company: result.company,
                            supplierName: result.supplierName,
                            supplierID: result.supplierID,
                            clientContractName: result.clientContractName,
                            clientContractID: result.clientContractID,
                            deliveryDetails: result.deliveryDetails,
                            supplierBranchName: result.supplierBranchName,
                            supplierTicket: result.supplierTicket ? result.supplierTicket : "",
                            estimatedDeliveryDate: result.estimatedDeliveryDate,
                            orderLines: result.orderLines,
                            jobID: jobDetails.jobID,
                            jobNumber: jobDetails.jobNumber,
                            jobStatus: jobDetails.jobStatus,
                            jobStatusName: jobDetails.jobStatusName
                        });

                        if (Number(result.purchaseOrderStatus.code) === 2) {
                            setCanEdit(true);
                        }

                    })
                    .catch((e) => {
                        console.log(e)
                    })
                    .finally((e) => {
                        setLoading(false);
                    })
                
            })
            .catch((error) => console.warn(error))

        
    }

    const getSuppliers = () => {
        PurchaseOrderService.getSuppliers()
            .then((response) => {
                setSuppliers(response.value.collection);
            })
            .catch((error) => console.warn(error))
    }

    useEffect(() => {
        getPurchaseOrder(poId.id);
        getSuppliers();
        setShowGlobalClientList(false);
    }, [loading, poId.id, setShowGlobalClientList])

    useEffect(() => {
        if (globalUserRole === 'User') { setCanEdit(false); }
    }, [globalUserRole])

    const updatePO = () => {
        setShowPreloader(true);
        PurchaseOrderService.update(select)
            .then((response) => {
                console.log(response);
                history.push(`/purchase-orders?searchText=${select.purchaseOrderNumber}`);
            })
            .catch((error) => console.warn(error))
            .finally(() => setShowPreloader(false));
    }

    const handleSelectChange = (event) => {
        if (canEdit && !event.target.value.match(/[%<>\\$'"]/))
         {
            const value = event.target.value;
            setSelect({
                ...select,
                [event.target.name]: value,
            })
        }
    };


    const handleRowClick = (event) => {
       
    }

    const handleDateChange = (date) => {
        if (canEdit) {
            setSelectedDate(date);
            setSelect({
                ...select,
                estimatedDeliveryDate: new Date(date).toISOString(),
            })
        }
        
    };


    const handleDeliveryDetailsModal = () => {
        setDeliveryDetailsModal(!deliveryDetailsModal)
    };

    const handleDeliveryDetailsModalSave = (updateDetails) => {
        if (canEdit) {
            setSelect(select => ({
                ...select,
                ...{ deliveryDetails: updateDetails }
            }));
        }
        
        setDeliveryDetailsModal(!deliveryDetailsModal);
    }

    const handleOrderDetailsModal = () => {
        setOrderDetailsModal(!orderDetailsModal)
    }

    const handleOrderDetailsModalSave = (partDetails, qty, comments) => {
        setOrderDetailsModal(!orderDetailsModal);

        if (canEdit) {
            let newOrderLine = {
                productName: `${partDetails.supplierPriceCode} - £${partDetails.supplierPriceRate.toFixed(2)}`,
                price: (partDetails.supplierPriceRate).toFixed(2),
                quantity: qty,
                lineValue: (qty * partDetails.supplierPriceRate).toFixed(2),
                comments: comments,
                supplierPriceID: partDetails.supplierPriceID
            }
            let existingOrderLines = select.orderLines;
            existingOrderLines.push(newOrderLine);
            let updateVals = {
                currentCost: (select.currentCost + qty * partDetails.supplierPriceRate).toFixed(2),
                orderLines: existingOrderLines
            }
            setSelect(select => ({
                ...select,
                ...updateVals
            }));
        }
        
    }

    const handleOrderDetailsEditModal = () => {
        setOrderDetailsEditModal(!orderDetailsEditModal)
    };

    const handleOrderDetailsEditModalSave = (partDetails, index) => {
        
        if (canEdit) {
            let oldLineValue = partDetails.lineValue;
            let existingOrderLines = select.orderLines;
            existingOrderLines[index].quantity = partDetails.quantity;
            existingOrderLines[index].comments = partDetails.comments;
            existingOrderLines[index].lineValue = parseFloat((partDetails.quantity * partDetails.price).toFixed(2));
            let updateVals = {
                currentCost: (select.currentCost - oldLineValue + existingOrderLines[index].lineValue).toFixed(2),
                orderLines: existingOrderLines
            }
            setSelect(select => ({
                ...select,
                ...updateVals
            }));
        }
    };



    const handleApproverModal = () => {
        setApproverModal(!approverModal)
    }

    const handleApproverModalSave = (updateDetails) => {
        if (canEdit) {
            setSelect(select => ({
                ...select,
                ...{ approverName: updateDetails.approverName, approverID: updateDetails.approverID }
            }));
            setApproverModal(!approverModal)
        }       
    }

    const handlePrintEmailModal = () => {
        setPrintEmailModal(!printEmailModal)
    }

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = window.atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize),
                byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const handlePrint = () => {
        setShowPreloader(true);
        PurchaseOrderService.print(select.purchaseOrderID)
            .then((response) => {
                let blob = b64toBlob(response, 'application/pdf');
                let blobURL = URL.createObjectURL(blob);

                let iframe = document.createElement('iframe');
                document.body.appendChild(iframe);

                iframe.style.display = 'none';
                iframe.src = blobURL;
                iframe.onload = function () {
                    setTimeout(function () {
                        iframe.focus();
                        iframe.contentWindow.print();
                    }, 1);
                };
            })
            .catch((error) => console.warn(error))
            .finally(() => setShowPreloader(false));

    }

    const handleEmail = (to) => {
        setShowPreloader(true);
        PurchaseOrderService.email(select.purchaseOrderID, to)
            .then((response) => {
                console.log(response);
            })
            .finally(() => setShowPreloader(false));
    }

    const basicModalContent = () => {
        return <Typography>Are you sure you want to cancel this Purchase Order?</Typography>
    }

    const handleCancelPOModal = () => {
        setCancelPOModal(!cancelPOModal)
    }

    const handleCancelPO = () => {
        PurchaseOrderService.cancel(select.purchaseOrderID);
        setCancelPOModal(!cancelPOModal);
        history.push(`/purchase-orders?searchText=${select.purchaseOrderNumber}`);
    }

    const handleChangeJobStatus = (value) => {
        var statusName = statusMenuItems.filter(x => x.value === value.target.value)[0].name;
        setSelect(select => ({
            ...select,
            ...{
                jobStatus: value.target.value,
                jobStatusName: statusName
            }
        }));
    }

    return (

        <div>

            <Button
                variant="contained"
                onClick={() => handleGoToSearch()}
                className={classes.button}
                startIcon={<ArrowBackIcon />}
            >
                Back
            </Button>

            <div className={classes.titleBox}>
                <Typography className={classes.title} variant="h5">
                    Purchase Order Details
                </Typography>
                <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    gutterBottom
                > {select.clientContractName}
                </Typography>
            </div>
            <Divider className={classes.divider} />

            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <Typography variant="body2" className={classes.itemTitle}>
                            PO Number
                        </Typography>
                        <Typography variant="body1">
                            {select.purchaseOrderNumber}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <Typography variant="body2" className={classes.itemTitle}>
                            Created By
                        </Typography>
                        <Typography variant="body1">
                            {select.createdBy}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <Typography variant="body2" className={classes.itemTitle}>
                            Created On
                        </Typography>
                        <Typography variant="body1">
                            {format(new Date(select.createdOn), "dd-MM-yy HH:mm")}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <Typography variant="body2" className={classes.itemTitle}>
                            Instruction Number
                        </Typography>
                        <Typography href="#" variant="body1" color="secondary">
                            {select.instructionNumber}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <Typography variant="body2" className={classes.itemTitle}>
                            Client Contract
                        </Typography>
                        <Typography href="#" variant="body1" color="secondary">
                            {select.clientContractName}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>                                       
                    <Box>
                        <Typography variant="body2" className={classes.itemTitle}>
                            Status
                        </Typography>
                        <Typography variant="body1">
                            {select.purchaseOrderStatus.literalText}
                        </Typography>
                    </Box>                   
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Supplier</label>
                        <Typography variant="body1">
                            {select.supplierName}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Purchase Order Type</label>
                        <Typography variant="body1">
                            {select.purchaseOrderType.literalText}
                        </Typography>
                    </Box>
                </Grid>
                {(select.jobStatus !== 3 && select.jobStatus !== 7 && select.jobStatus !== 8) &&
                    <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Job Status</label>
                            <Typography variant="body1">
                                {select.jobStatusName}
                            </Typography>
                        </Box>
                    </Grid>
                }
            </Grid>
            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Supplier Ticket Number</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                fullWidth
                                name="supplierTicket"
                                placeholder="Enter ticket number..."
                                value={select.supplierTicket}
                                onChange={handleSelectChange}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Estimated Delivery Date</label>
                        <MuiPickersUtilsProvider className={classes.datePickerContainer} utils={DateFnsUtils}>
                            <Grid container>
                                <KeyboardDatePicker
                                    disableToolbar
                                    autoOk
                                    className={classes.datePicker}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    name="estDeliveryDate"
                                    id="estDeliveryDate"
                                    value={select.estimatedDeliveryDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Box>
                </Grid>
                {(select.jobStatus === 3 || select.jobStatus === 7 || select.jobStatus === 8) &&
                    <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Job status</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <Select
                                    id="selectJobStatus"
                                    displayEmpty
                                    className={classes.select}
                                    value={select.jobStatus}
                                    onChange={handleChangeJobStatus}
                                >
                                    <MenuItem key={3} value={3}>
                                        Parts Needed
                                    </MenuItem>
                                    <MenuItem key={5} value={5}>
                                        Referred to Supervisor
                                    </MenuItem>
                                    <MenuItem key={8} value={8}>
                                        Parts Ordered
                                    </MenuItem>
                                    <MenuItem key={9} value={9}>
                                        Parts In
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                }
            </Grid>
            {select.supplierBranchName && 
                <Grid container >
                    <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                        <Box>
                            <Typography variant="body2" className={classes.itemTitle}>
                                Supplier Branch
                            </Typography>
                            <Typography variant="body1">
                                {select.supplierBranchName}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            }
            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <Link onClick={handleDeliveryDetailsModal} variant="body1" color="secondary">
                            + Add / View Delivery Details
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} sm={12} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Description of Works</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.textarea}
                                variant="outlined"
                                multiline
                                minrows={10}
                                maxrows={20}
                                fullWidth
                                name="descriptionOfWorks"
                                placeholder="Description of works..."
                                value={select.descriptionOfWorks}
                                onChange={handleSelectChange}
                            />
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            <div className={classes.titleBox}>
                <Typography className={classes.subtitle} variant="h5">
                    Order Details
                </Typography>
            </div>
            <Divider className={classes.divider} />

            <Grid container>
                <Grid item xs={12} sm={12} className={classes.orderDetailsTable}>

                    <ResultsTable
                        columns={[
                            {
                                name: 'id',
                                label: 'Id',
                                hidden: true
                            },
                            {
                                name: 'productName',
                                label: 'Product'
                            },
                            {
                                name: 'quantity',
                                label: 'Quantity'
                            },
                            {
                                name: 'price',
                                label: 'Price'
                            },
                            {
                                name: 'lineValue',
                                label: 'Line Value'
                            },
                            {
                                name: 'comments',
                                label: 'Comments'
                            },
                            {
                                name: 'edit',
                                label: ''
                            },
                            {
                                name: 'delete',
                                label: ''
                            },
                        ]}
                        data={addOrderLineButtons(select.orderLines)}
                        rowClick={handleRowClick}
                        linkKey="id"
                        orderBy="id"
                        direction="desc"
                    />
                </Grid>
                {canEdit &&
                    <Grid item xs={12} sm={12}>
                        <Box>
                            <Button
                                className={classes.subNavigationItem}
                                variant="outlined"
                                color="primary"
                                onClick={handleOrderDetailsModal}
                            >
                                + Add New Line
                            </Button>
                        </Box>
                    </Grid>

                }
                
            </Grid>

            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box className={classes.approverContainer}>
                        <label className={classes.itemTitle}>Approver</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                fullWidth
                                name="approverName"
                                placeholder="Please select approver"
                                value={select.approverName}
                                onChange={handleSelectChange}
                            />
                        </FormControl>
                        {canEdit &&
                            <Link className={classes.changeApprover} onClick={handleApproverModal} variant="body1" color="secondary">
                                Change...
                            </Link>

                        }
                       
                    </Box>
                </Grid>
                
            </Grid>

            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Initial Cost</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                fullWidth
                                name="initialCost"
                                value={select.initialCost.toFixed(2)}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Current Cost</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                fullWidth
                                name="currentCost"
                                value={parseFloat(select.currentCost).toFixed(2)}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid container>

                        <Button
                            className={classes.subNavigationItem}
                            variant="contained"
                            color="primary"
                            onClick={updatePO}
                        >
                            Save
                        </Button>
                    
                    {canEdit &&
                        <Button
                            className={classes.subNavigationItem}
                            variant="contained"
                            color="primary"
                            onClick={handleCancelPOModal}
                        >
                            Cancel
                        </Button>
                    }
                    <Button
                        className={classes.subNavigationItem}
                        variant="outlined"
                        color="primary"
                        onClick={handlePrintEmailModal}
                    >
                        Print / Email Purchase Order
                    </Button>

                </Grid>

            </Grid>



            <DeliveryDetailsModal
                open={deliveryDetailsModal}
                data={select.deliveryDetails}
                onClose={handleDeliveryDetailsModal}
                onSubmit={handleDeliveryDetailsModalSave}
                canEdit={canEdit}
            />

            <OrderDetailsModal
                open={orderDetailsModal}
                onClose={handleOrderDetailsModal}
                onSave={handleOrderDetailsModalSave}
                suppliersList={suppliers}
                clientContractName={select.clientContractName}
                clientContractID={select.clientContractID}
                canEdit={canEdit}
            />

            <OrderDetailsEditModal
                open={orderDetailsEditModal}
                onClose={handleOrderDetailsEditModal}
                onSave={handleOrderDetailsEditModalSave}
                detailItem={selectedDetailItem}
                detailIndex={selectedDetailIndex}
                canEdit={canEdit}
            />

            <ApproverModal
                open={approverModal}
                onClose={handleApproverModal}
                onSubmit={handleApproverModalSave}
                clientContractID={select.clientContractID}
                canEdit={canEdit}
            />

            <PrintEmailModal
                open={printEmailModal}
                onClose={handlePrintEmailModal}
                onPrint={handlePrint}
                onEmail={handleEmail}
                po={select}
            />

            <BasicModal
                open={cancelPOModal}
                onClose={handleCancelPOModal}
                onSubmit={handleCancelPO}
                cancelButtonText="No"
                submitButtonText="Yes"
                headerTitle="Cancel PO"
                dialogContent={basicModalContent}
            />


        </div>

    );
};

export default PurchaseOrderDetails;