import http from "../utils/http";

const getPowerBiEmbedData = (data) => {
  return http.post(`/power-bi/reports/embed-data`, data);
};

const getGroups = (data) => {
  return http.get(`/power-bi/groups`, data);
};

const getReportsByGroupId = (groupID) => {
  return http.get(`/power-bi/groups/${groupID}/reports`);
};

const getMyPowerBiReports = (data) => {
  return http.get(`/power-bi/management/my-reports`, data);
};

const getPowerBiReports = () => {
  return http.get(`/power-bi/management/reports`);
};

const getPowerBiReportById = (id) => {
  return http.get(`/power-bi/management/reports/${id}`);
};

const deletePowerBiReport = (id) => {
  return http.delete(`/power-bi/management/reports/${id}`);
};

const getPowerBiReportAccesses = (id) => {
  return http.get(`/power-bi/management/reports/${id}/accesses`);
};

const provideAccessToPowerBiReport = (id, clientID, role, ) => {
  return http.post(`/power-bi/management/reports/${id}/provide-access`, { clientID, role});
};

const deletePowerBiReportAccess = (id) => {
  return http.delete(`/power-bi/management/reports/accesses/${id}`);
};

const createPowerBiReport = (data) => {
  return http.post(`/power-bi/management/reports`, data);
};

const updatePowerBiReport = (id, data) => {
  return http.put(`/power-bi/management/reports/${id}`, data);
};

export default {
  getPowerBiEmbedData,
  getGroups,
  getReportsByGroupId,
  getMyPowerBiReports,
  getPowerBiReports,
  getPowerBiReportById,
  deletePowerBiReport,
  getPowerBiReportAccesses,
  provideAccessToPowerBiReport,
  deletePowerBiReportAccess,
  createPowerBiReport,
  updatePowerBiReport
};
