import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, Grid, Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'


const useStyles = makeStyles({
  itemTitle: {
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500'
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    right: '8px'
  },
  title: {
    position: 'relative'
  }
})

const ApplianceModal = (props) => {
  const classes = useStyles()
  const { onClose, open, data } = props

  const handleClose = () => {
    if (onClose) return onClose(data)
  }

  return (
    <Dialog
      style={{ zIndex: 1420 }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.title}>
        Appliance Checks completed
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent style={{ paddingBottom: '20px' }}>
        <Grid item xs={12} container direction="row" spacing={2}>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Type
            </Typography>
            <Typography variant="body1">
              {data?.type || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Manufacturer
            </Typography>
            <Typography variant="body1">
              {data?.manufacturer || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Model
            </Typography>
            <Typography variant="body1">
              {data?.model || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Location
            </Typography>
            <Typography variant="body1">
              {data?.location || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Operating Pressure
            </Typography>
            <Typography variant="body1">
              {data?.operatingpressurembarorheatinkwh || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Operating Pressure UOM
            </Typography>
            <Typography variant="body1">
              {data?.operatingPressureUOM || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Flue Flow Test
            </Typography>
            <Typography variant="body1">
              {data?.flueFlowTest || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Flue Spillage Test
            </Typography>
            <Typography variant="body1">
              {data?.flueSpillageTest || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Flue Visual Condition
            </Typography>
            <Typography variant="body1">
              {data?.flueVisualCondition || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Safety Device Correct Operating
            </Typography>
            <Typography variant="body1">
              {data?.safetyDeviceCorrectOperating || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Termination Satisfactory
            </Typography>
            <Typography variant="body1">
              {data?.terminationSatisfactory || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Ventilation Adequate
            </Typography>
            <Typography variant="body1">
              {data?.ventilationAdequate || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              CO2 Low
            </Typography>
            <Typography variant="body1">
              {data?.cO2Low || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              CO2 High
            </Typography>
            <Typography variant="body1">
              {data?.cO2High || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              CO PPM Low
            </Typography>
            <Typography variant="body1">
              {data?.coppmLow || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              CO PPM High
            </Typography>
            <Typography variant="body1">
              {data?.coppmHigh || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Appliance Serviced
            </Typography>
            <Typography variant="body1">
              {data?.applianceserviced || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="body2" className={classes.itemTitle}>
              Appliance Safe to Use
            </Typography>
            <Typography variant="body1">
              {data?.applianceSafetoUse || '-'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ApplianceModal