import axios from "axios";
const state = {
    userName: null,
    role:null
}

const authentication = {
    isSignedOn:false,
    authorize: (callback) => {
        axios.get(`${process.env.REACT_APP_BASE_API}authenticate/authenticate`)
            .then((response) => {
                if (response.data !== true) {
                    window.location.href = `${process.env.REACT_APP_BASE_API}authenticate/challenge`;
                }
                else {
                    axios.get(`${process.env.REACT_APP_BASE_API}authenticate/info`)
                        .then((response) => {
                            state.userName = response.data.username;
                            state.role = response.data.userRole;
                            callback()
                        }).catch((response) => {
                            console.log(response);
                        });
                }
            }).catch((response)=>{
                console.log(response);
            });
    },
    signOut: () => {
        window.location.href = `${process.env.REACT_APP_BASE_API}authenticate/SignOut`;
    },
    getUserRole: () => {
        return state.role;
    },
    getUserName: () => {
        return state.userName;
    }
}

export default authentication;