import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Divider,
  Button,
  Typography,
  Grid,
  Select,
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Checkbox,
  TextField
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ResultsTable from '../../components/ui/ResultsTable/ResultsTable'
import GetAppIcon from '@material-ui/icons/GetApp'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600',
    marginBottom: '10px'
  },
  divider: {
    backgroundColor: '#E6ADA5',
    marginBottom: '20px'
  },
  backbtn: {
    marginBottom: '30px'
  },
  label: {
    marginBottom: '10px',
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500',
    fontSize: '12px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    width: 'fit-content',
    padding: '10px 30px'
  },
  downloadReports: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '20px'
  },
  downloadImgBtn: {
    textTransform: 'none',
    float: 'right',
    padding: '10px 25px',
    fontSize: '16px',
    width: 'auto'
  },
  subTitle: {
    color: '#E4006D',
    fontSize: '12px',
    fontWeight: 700
  }
}))

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#F74A36'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />)

function createData(
  id,
  uprn,
  addressline1,
  addressline2,
  addressline3,
  city,
  certificateexpirydate,
  dateoflastservice
) {
  return {
    id,
    uprn,
    addressline1,
    addressline2,
    addressline3,
    city,
    certificateexpirydate,
    dateoflastservice
  }
}

const rows = [
  createData(
    1,
    57990,
    '52 Regent Street',
    '52 Regent Street',
    '52 Regent Street',
    'London',
    '21 Aug 2020',
    '21 Aug 2020 '
  ),
  createData(
    2,
    57990,
    '52 Regent Street',
    '52 Regent Street',
    '52 Regent Street',
    'London',
    '21 Aug 2020',
    '21 Aug 2020 '
  ),
  createData(
    3,
    57990,
    '52 Regent Street',
    '52 Regent Street',
    '52 Regent Street',
    'London',
    '21 Aug 2020',
    '21 Aug 2020 '
  ),
  createData(
    4,
    57990,
    '52 Regent Street',
    '52 Regent Street',
    '52 Regent Street',
    'London',
    '21 Aug 2020',
    '21 Aug 2020 '
  ),
  createData(
    5,
    57990,
    '52 Regent Street',
    '52 Regent Street',
    '52 Regent Street',
    'London',
    '21 Aug 2020',
    '21 Aug 2020 '
  ),
  createData(
    6,
    57990,
    '52 Regent Street',
    '52 Regent Street',
    '52 Regent Street',
    'London',
    '21 Aug 2020',
    '21 Aug 2020 '
  ),
  createData(
    7,
    57990,
    '52 Regent Street',
    '52 Regent Street',
    '52 Regent Street',
    'London',
    '21 Aug 2020',
    '21 Aug 2020 '
  ),
  createData(
    8,
    57990,
    '52 Regent Street',
    '52 Regent Street',
    '52 Regent Street',
    'London',
    '21 Aug 2020',
    '21 Aug 2020 '
  )
]

const ServiceExpiryReports = ({ history }) => {
  const classes = useStyles()

  const [state, setState] = React.useState({
    showIssues: true,
    voidProperties: false,
    minorAddressFields: true,
    passBackFields: true,
    minorAddressFields2: true,
    passBackFields2: true,
    minorAddressFields3: true,
    passBackFields3: true
  })

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const [jobAddress, setJobAddress] = React.useState('')
  const [isSearched, setSearched] = React.useState(false)

  const handleChangeFilterJob = (event) => {
    setJobAddress(event.target.value)
  }

  const handleSearch = (event) => {
    setSearched(!isSearched)
  }

  return (
    <div>
      {!isSearched ? (
        <div>
          <Button
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIcon />}
            className={classes.backbtn}
          >
            Back to Reports List
          </Button>
          <div>
            <Typography className={classes.title} variant="h5">
              Service Expiry Report
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} container>
                <Grid item xs container spacing={3}>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    direction="column"
                  >
                    <Box mb={2}>
                      <label className={classes.label}>Client Name</label>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <Select
                          id="select-client-name"
                          displayEmpty
                          value={jobAddress}
                          onChange={handleChangeFilterJob}
                        >
                          <MenuItem value={10}>Test 1</MenuItem>
                          <MenuItem value={20}>Test 2</MenuItem>
                          <MenuItem value={30}>Test 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box mb={2}>
                      <label className={classes.label}>Service Type</label>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <Select
                          id="select-client-name"
                          displayEmpty
                          value={jobAddress}
                          onChange={handleChangeFilterJob}
                        >
                          <MenuItem value={10}>Test 1</MenuItem>
                          <MenuItem value={20}>Test 2</MenuItem>
                          <MenuItem value={30}>Test 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={state.checkedG}
                            onChange={handleChange}
                            name="showIssues"
                          />
                        }
                        label="Show Issues"
                      />
                    </Box>
                    <Box mb={2}>
                      <label className={classes.label}>
                        Show Issies x Days Ahead
                      </label>
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        fullWidth
                        id="issiesAndDays"
                        name="issiesAndDays"
                        placeholder=""
                      />
                    </Box>
                    <Box mb={2}>
                      <label className={classes.label}>
                        Show Properties x Expiry Days Ahead
                      </label>
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        fullWidth
                        id="propertiesAndDays"
                        name="propertiesAndDays"
                        placeholder=""
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={state.checkedG}
                            onChange={handleChange}
                            name="voidProperties"
                          />
                        }
                        label="Include Void Properties"
                      />
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      onClick={handleSearch}
                    >
                      View Report
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs container spacing={3}>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    direction="column"
                  >
                    <Box>
                      <label className={classes.label}>Display Columns</label>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={state.checkedG}
                            onChange={handleChange}
                            name="minorAddressFields"
                          />
                        }
                        label="Minor Address Fields"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={state.checkedG}
                            onChange={handleChange}
                            name="passBackFields"
                          />
                        }
                        label="Pass Back Fields"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={state.checkedG}
                            onChange={handleChange}
                            name="minorAddressFields2"
                          />
                        }
                        label="Minor Address Fields"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={state.checkedG}
                            onChange={handleChange}
                            name="passBackFields2"
                          />
                        }
                        label="Pass Back Fields"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={state.checkedG}
                            onChange={handleChange}
                            name="minorAddressFields3"
                          />
                        }
                        label="Minor Address Fields"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={state.checkedG}
                            onChange={handleChange}
                            name="passBackFields3"
                          />
                        }
                        label="Pass Back Fields"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      ) : (
        <div>
          <Button
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIcon />}
            className={classes.backbtn}
          >
            Back to Reports List
          </Button>
          <div>
            <Typography className={classes.title} variant="h5">
              Cobalt Housing
            </Typography>
          </div>

          <Box className={classes.downloadReports}>
            <Typography className={classes.subTitle} variant="h6">
              SERVICE EXPIRY REPORT
            </Typography>
            <Button
              variant="contained"
              className={classes.downloadImgBtn}
              color="primary"
              startIcon={<GetAppIcon />}
            >
              Download Report
            </Button>
          </Box>

          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} sm={12}>
              <ResultsTable
                columns={[
                  {
                    name: 'id',
                    label: 'Id',
                    hidden: true
                  },
                  {
                    name: 'uprn',
                    label: 'UPRN'
                  },
                  {
                    name: 'addressline1',
                    label: 'Address Line 1'
                  },
                  {
                    name: 'addressline2',
                    label: 'Address Line 2'
                  },
                  {
                    name: 'addressline3',
                    label: 'Address Line 3'
                  },
                  {
                    name: 'city',
                    label: 'City'
                  },
                  {
                    name: 'certificateexpirydate',
                    label: 'Certificate Expiry Date'
                  },
                  {
                    name: 'dateoflastservice',
                    label: 'Date of Last Service'
                  }
                ]}
                data={rows}
                baseLink="/job"
                linkKey="id"
                orderBy="id"
                direction="desc"
              ></ResultsTable>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default ServiceExpiryReports
