import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Divider,
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import UserService from '../../../services/UserService'
import * as Validator from '../../../utils/validations'
import BasicModal from '../../../components/ui/BasicModal/BasicModal'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600',
    marginBottom: '10px'
  },
  divider: {
    backgroundColor: '#E6ADA5'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    width: 'fit-content!important',
    padding: '10px 30px'
  },
  label: {
    marginBottom: '10px',
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500',
    fontSize: '12px'
  },
  contentBox: {
    padding: '30px 50px 10px 50px'
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    left: '50%',
    width: 'fit-content',
    height: 'fit-content'
  }
}))

const INITIAL = {
  firstName: '',
  surname: '',
  companyName: '',
  email: '',
  emailError: '',
  role: 1
}

const INITIALMODAL = {
  email: '',
  password: ''
}

const AddNewUser = (props) => {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(INITIAL)
  const [modalState, setModalState] = useState(INITIALMODAL)

  const onStateHandler = useCallback(
    ({ target: { name, value } }) => {
      const next = { ...state, [name]: value, [`${name}Error`]: '' }
      setState(next)
    },
    [state]
  )

  const handleCreate = async () => {
    setLoading(true)
    const errors = {
      emailError:
        (!Validator.validateEmail(state.email) &&
          Validator.validateEmailText) ||
        (!(await Validator.validateUserExist(state.email)) &&
          Validator.validateUserExistText),
      companyNameError:
        !Validator.validateCompanyName(state.companyName) &&
        Validator.validateCompanyNameText,
      firstNameError: !Validator.validateFirstName(state.firstName) &&
        Validator.validateFirstNameText,
      surnameError: !Validator.validateSurname(state.surname) &&
        Validator.validateSurnameText,
    }
    setLoading(false)

    const hasError = Object.values(errors).some((item) => Boolean(item))
    if (hasError) {
      setState({ ...state, ...errors })
      return
    }
    const requestData = {}
    Object.keys(state).forEach((key) => {
      if (key.includes('Error')) return
      requestData[key] = state[key]
    })

    setLoading(true)
    UserService.createUser(requestData)
      .then((response) => {
        const result = response.object
        setModalState({
          email: result.username,
          password: result.password
        })

        setOpenModal(true)
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))
  }

  const [openModal, setOpenModal] = useState(false)

  const handleCloseModal = () => {
    history.push('/admin')
  }

  return (
    <div>
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        onClick={() => history.goBack()}
      >
        Back to Users List
      </Button>
      <Box mt={3}>
        <Typography className={classes.title} variant="h5">
          Add New User
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={12} sm={12}>
          {loading ? (
            <div className={classes.preloader}>
              <CircularProgress color="secondary" />
            </div>
          ) : null}
          <Box mt={3}>
            <form noValidate autoComplete="off">
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} container>
                  <Grid item xs container spacing={3}>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      direction="column"
                    >
                      <Box mb={2}>
                        <label className={classes.label}>First Name</label>
                        <TextField
                          className={classes.input}
                          variant="outlined"
                          fullWidth
                          id="firstName"
                          name="firstName"
                          error={!!state.firstNameError}
                          helperText={state.firstNameError}
                          value={state.firstName}
                          placeholder="Enter First Name"
                          onChange={onStateHandler}
                        />
                      </Box>
                      <Box mb={2}>
                        <label className={classes.label}>Surname</label>
                        <TextField
                          className={classes.input}
                          variant="outlined"
                          fullWidth
                          id="surname"
                          name="surname"
                          error={!!state.surnameError}
                          helperText={state.surnameError}
                          value={state.surname}
                          placeholder="Enter Surname"
                          onChange={onStateHandler}
                        />
                      </Box>
                      <Box mb={2}>
                        <label className={classes.label}>Company Name</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="companyName"
                          name="companyName"
                          value={state.companyName}
                          placeholder="Enter Company Name"
                          error={!!state.companyNameError}
                          helperText={state.companyNameError}
                          onChange={onStateHandler}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs container spacing={3}>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      direction="column"
                    >
                      <Box mb={2}>
                        <label className={classes.label}>Email</label>
                        <TextField
                          className={classes.input}
                          variant="outlined"
                          fullWidth
                          id="email"
                          name="email"
                          value={state.email}
                          error={!!state.emailError}
                          helperText={state.emailError}
                          placeholder="Enter Email"
                          onChange={onStateHandler}
                          required
                        />
                      </Box>
                      <Box mb={2}>
                        <label className={classes.label}>Role</label>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            id="role"
                            displayEmpty
                            name="role"
                            value={state.role}
                            onChange={onStateHandler}
                          >
                            <MenuItem value={1}>User</MenuItem>
                            <MenuItem value={0}>Admin</MenuItem>
                            <MenuItem value={2}>Supplier</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} direction="row">
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      direction="column"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        size="large"
                        onClick={handleCreate}
                        disabled={loading}
                      >
                        Create User
                        </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
      <BasicModal
        headerIcon="/icons/admin-check-icon.svg"
        headerTitle="User added successfully"
        submitButtonText="Close"
        onSubmit={handleCloseModal}
        dialogContent={() => (
          <Box className={classes.contentBox}>
            <Box mb={2}>
              <label className={classes.label}>Email</label>
              <TextField
                className={classes.input}
                fullWidth
                id="email"
                name="email"
                value={modalState.email}
                InputProps={{
                  readOnly: true
                }}
              />
            </Box>
            <Box mb={2}>
              <label className={classes.label}>Password</label>
              <TextField
                className={classes.input}
                fullWidth
                id="password"
                name="password"
                value={modalState.password}
                InputProps={{
                  readOnly: true
                }}
              />
            </Box>
          </Box>
        )}
        open={openModal}
      ></BasicModal>
    </div>
  )
}
export default AddNewUser
