import React from 'react'

const EditIcon = (props) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 16 18"
      fill={props.fill ? props.fill : 'white'}
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M12.5813 3.5998L15.4 6.41855C15.5187 6.5373 15.5187 6.73105 15.4 6.8498L8.575 13.6748L5.675 13.9967C5.2875 14.0404 4.95938 13.7123 5.00313 13.3248L5.325 10.4248L12.15 3.5998C12.2687 3.48105 12.4625 3.48105 12.5813 3.5998ZM17.6437 2.88418L16.1187 1.35918C15.6437 0.88418 14.8719 0.88418 14.3938 1.35918L13.2875 2.46543C13.1688 2.58418 13.1688 2.77793 13.2875 2.89668L16.1063 5.71543C16.225 5.83418 16.4188 5.83418 16.5375 5.71543L17.6437 4.60918C18.1187 4.13105 18.1187 3.35918 17.6437 2.88418ZM12 11.8186V14.9998H2V4.9998H9.18125C9.28125 4.9998 9.375 4.95918 9.44687 4.89043L10.6969 3.64043C10.9344 3.40293 10.7656 2.9998 10.4312 2.9998H1.5C0.671875 2.9998 0 3.67168 0 4.4998V15.4998C0 16.3279 0.671875 16.9998 1.5 16.9998H12.5C13.3281 16.9998 14 16.3279 14 15.4998V10.5686C14 10.2342 13.5969 10.0686 13.3594 10.3029L12.1094 11.5529C12.0406 11.6248 12 11.7186 12 11.8186Z" />
    </svg>
  )
}

export default EditIcon
