
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
Divider,
Button,
TextField,
Typography,
Grid,
TablePagination
} from '@material-ui/core'
import ResultsTable from '../../components/ui/ResultsTable/ResultsTable'
import ClientService from '../../services/ClientService'
import { useHistory } from 'react-router-dom'
import ClientContext from "../../utils/globalClientContext"

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '20px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#E4006D'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4006D'
            }
        }
    },
    title: {
        fontWeight: '600',
        marginBottom: '10px'
    },
    searchContainer: {
        maxWidth: '800px',
        padding: '10px 0px 50px 0px',
        margin: 'auto'
    },
    submit: {
        margin: 0,
        textTransform: 'none',
        height: '100%',
        minWidth: '100%',
    },
    filter: {
        height: '100%',
        textTransform: 'capitalize',
        color: '#E4006D',
    },
    divider: {
        backgroundColor: '#E6ADA5'
    },
    input: {
        background: '#ffffff',
        boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.10)'
    },
    label: {
        marginBottom: '10px',
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
        fontSize: '12px'
    },
    preloader: {
        display: 'flex',
        justifyContent: 'center',
        padding: '40px 0'
    },
    overlayOpen: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1500,
        transition: 'all 1s ease',
        transitionProperty: 'background-color, visibility',
        transitionDelay: '0.3s',
        visibility: 'visible'
    },
    overlayClosed: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1500,
        transition: 'all 1s ease',
        transitionProperty: 'background-color, visibility',
        transitionDelay: '0.3s',
        visibility: 'hidden'

    },
    filterMenuOpen: {
        height: '100vh',
        width: '300px',
        backgroundColor: '#ffffff',
        padding: '77px 50px',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1600,
        borderRadius: '0px 16px 16px 0px',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        transition: 'left 1s ease',

    },
    filterMenuClosed: {
        height: '100vh',
        width: '300px',
        backgroundColor: '#ffffff',
        padding: '77px 50px',
        position: 'absolute',
        left: '-300px',
        top: 0,
        zIndex: 1600,
        borderRadius: '0px 16px 16px 0px',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        transition: 'left 1s ease',
    },
}))

const ClientContracts = (props) => {

const classes = useStyles()
    const history = useHistory()
    const { setShowGlobalClientList } = useContext(ClientContext);
    

    const searchParams = new URLSearchParams(props.location.search)
    const searchText = searchParams.get('searchText') || ''
    const searchClient = searchParams.get('clientID') || ''
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(100)
    const [length, setLength] = useState(0)
    const [contracts, setContracts] = useState([])
    const [filterText, setFilterText] = useState(searchText)
    const [filterClient, setFilterClient] = useState(searchClient)

    useEffect(() => {
        setShowGlobalClientList(false);
        ClientService.getClientContracts({
            filterClient,
            filterText,
            size,
            page: page + 1
        })
            .then((response) => {
                const items = response.collection.map((item) => ({
                    id: item.clientContractID,
                    client: item.clientName,
                    name: item.clientContractName,
                    code: item.clientContractCode,
                    contractStatus: item.clientContractStatus
                }));
                setLength(response.totalRecords);
                setContracts(items);
            })
            .catch((error) => console.warn(error))
        
    }, [filterClient, filterText, page, size, setShowGlobalClientList]);

    const handleChangeClientContractSearch = (event) => {
        setFilterText(event.target.value)
    }


    const handleChangePage = (event, newPage) => {
       setPage(newPage)
        
    }

    const handleChangeSize = (event) => {
        setSize(event.target.value)
        setPage(0)
        
    }

    const handleSearch = (searchValue, filterValue) => {
        setFilterText(searchValue)
        setFilterClient(filterValue)
        setSearchParams(searchValue, filterValue)
        setPage(0)
    }

    const setSearchParams = (searchValue, clientValue) => {
        const params = new URLSearchParams()
        if (searchValue) {
            params.append('searchText', searchValue)
        }

        if (clientValue) {
            params.append('clientID', clientValue)
        }

        history.push({
            pathname: '/client-contracts',
            search: params.values.length > 0 ? '' : `?` + params
        })
    }

    return (


        <div>
            
        <div>
           
            <Grid container spacing={2} className={classes.searchContainer}>

                <Grid item xs={7}>     
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        fullWidth
                        id="clientContractSearch"
                        name="clientContractSearch"
                        placeholder="Search by Client Contract Name"
                        value={filterText}
                        onChange={handleChangeClientContractSearch}
                    />
                </Grid>


                <Grid item xs={3}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                            size="large"
                            onClick={() => handleSearch(filterText, filterClient)}
                    >
                        Search
                    </Button>
                </Grid>


            </Grid>
            
            <div>
                <Typography className={classes.title} variant="h5">
                    Client Contracts
                </Typography>
                <Divider className={classes.divider} />
                <Grid container>
                    <Grid item xs={12} sm={12}>

                        <ResultsTable
                            columns={[
                                {
                                    name: 'id',
                                    label: 'Id',
                                    hidden: true
                                },
                                {
                                    name: 'client',
                                    label: 'Client'
                                },
                                {
                                    name: 'name',
                                    label: 'Name'
                                },
                                {
                                    name: 'code',
                                    label: 'Code'
                                },
                                {
                                    name: 'contractStatus',
                                    label: 'Contract Status'
                                },
                            ]}
                            data={contracts}
                                baseLink="/client-contracts"
                            linkKey="id"
                            orderBy="id"
                            direction="desc"
                        />
                        <TablePagination
                            rowsPerPageOptions={[10, 50, 100, 500]}
                            component="div"
                            count={length}
                            rowsPerPage={size}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeSize}
                        />
                    </Grid>
                </Grid>
            </div>

            </div>


    </div>
)
}

export default ClientContracts