import React, { useState, useEffect } from 'react'
import ModalContainer from "../ModalContainer/ModalContainer"
import { makeStyles } from '@material-ui/core/styles'

import {
    Box,
    Button,
    Grid,
    FormControl,
    TextField,
} from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'none',
        width: 'fit-content!important',
        padding: '10px 30px'
    },
    label: {
        marginBottom: '10px',
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
        fontSize: '12px'
    },
    textarea: {

    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
    },
    fieldContainer: {
        marginBottom: '20px',
    },
    titleBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        marginBottom: '20px'
    },
    title: {
        borderRight: '1px solid #BCBEBE',
        paddingRight: '10px',
        fontWeight: '600',
        marginBottom: '10px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '30px',
    },
    select: {
        marginRight: '25px',
        marginTop: '8px'
    },
    modalSelect: {
        marginTop: '8px'
    },
    input: {
        marginTop: '8px'
    },
    buttonContainer: {
        justifyContent: 'flex-end',
    },
    subNavigationItem: {
        margin: '20px 0px 0px 20px',
        marginBottom: '60px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        minWidth: '100px'
    },
    searchInput: {
        background: '#ffffff',
        boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.10)',
        border: 'none'
    },
    searchButton: {
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        height: '100%',
        minWidth: '100%'
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    YesNoSwitch: {
        textAlign: 'end',
    }

}))


export default function SorsEditModal(props) {

    const {
        open,
        onClose,
        onSave,
        detailItem,
        detailIndex,
        canEdit
    } = props

    const [select, setSelect] = useState({
        qty: 0
    });

    useEffect(() => {
        setSelect(detailItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailItem]);


    const classes = useStyles();

    const handleSelectChange = (event) => {
        const value = event.target.value;
        if (canEdit && !value.match(/[%<>\\$'"]/)) {
            setSelect({
                ...select,
                [event.target.name]: value,
            })
        }
    };

    const handleSave = () => {
        onSave(select, detailIndex);
        onClose();
    }

    return (
        <ModalContainer
            open={open}
            onClose={onClose}
            modalContent={() => (

                <Grid container>


                    <Grid container spacing={2} className={classes.spaceBetween}>

                        <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                            <Box>
                                <label className={classes.itemTitle}>Quantity</label>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <TextField
                                        className={classes.input}
                                        variant="outlined"
                                        fullWidth
                                        name="qty"
                                        value={select.qty}
                                        onChange={handleSelectChange}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>


                    </Grid>

                    <Grid container className={classes.buttonContainer}>
                        <Box >
                            <Button
                                className={classes.subNavigationItem}
                                variant="outlined"
                                color="primary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            {canEdit &&
                                <Button
                                    className={classes.subNavigationItem}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                            }
                        </Box>
                    </Grid>

                </Grid>
            )}
        />
    );
}
