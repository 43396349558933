import React, { useState, useEffect } from 'react'
import ResultsTable from '../ui/ResultsTable/ResultsTable'
import PropertyService from '../../services/PropertyService'
import { format } from 'date-fns'

const PurchaseOrderTab = ({ propertyId }) => {
    const [purchaseOrders, setPurchaseOrders] = useState([])

    const getPurchaseOrders = (id) => {

        PropertyService.getPurchaseOrders(id)
            .then((response) => {
                const items = response.value.collection.map((item) => ({
                    id: item.purchaseOrderId,
                    clientContract: item.clientContractName,
                    instruction: item.instructionNumber,
                    PONumber: item.purchaseOrderNumber,
                    POStatus: item.purchaseOrderStatus.literalText,
                    createdOn: format(new Date(item.createdOn), "dd-MM-yy HH:mm"),
                    approver: item.approver,
                    createdBy: item.createdBy,
                    currentCost: item.currentCost.toFixed(2)
                }))
                setPurchaseOrders(items);
            })
            .catch((error) => console.warn(error))
            
    }

    useEffect(() => {
        getPurchaseOrders(propertyId)
    }, [propertyId])

    return (
        <div>
            <ResultsTable
                columns={[
                    {
                        name: 'id',
                        label: 'Id',
                        hidden: true
                    },
                    {
                        name: 'createdOn',
                        label: 'Created',
                        type: 'createdOn'
                    },
                    {
                        name: 'PONumber',
                        label: 'PO Number'
                    },
                    {
                        name: 'instruction',
                        label: 'Instruction'
                    },
                    {
                        name: 'POStatus',
                        label: 'Status'
                    },
                    {
                        name: 'currentCost',
                        label: 'Current Cost'
                    },
                    {
                        name: 'clientContract',
                        label: 'Client Contract'
                    }
                ]}
                data={purchaseOrders}
                baseLink="/purchase-orders"
                linkKey="id"
                orderBy="createdOn"
                direction="desc"
            ></ResultsTable>
        </div>
    )
}

export default PurchaseOrderTab
