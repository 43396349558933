import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  TextField
} from '@material-ui/core'

import DeleteIcon from '../ui/Icons/DeleteIcon'
import UserService from '../../services/UserService'
import ClientService from '../../services/ClientService'
import ResultsTable from '../ui/ResultsTable/ResultsTable'
import BasicModal from '../../components/ui/BasicModal/BasicModal'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    width: 'fit-content!important',
    padding: '10px 30px'
  },
  label: {
    marginBottom: '10px',
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500',
    fontSize: '12px'
  },
  contentBox: {
    padding: '30px 50px 10px 50px'
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 0'
  },
  deleteIcon: {
    float: 'right',
    padding: 0
  }
}))

const ClientsTab = ({ userId }) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [addClientModalOpen, setAddClientModalOpen] = useState(false)
  const [deleteClientModalOpen, setDeleteClientModalOpen] = useState(false)

  const [clients, setClients] = useState([])
  const [allClients, setAllClients] = useState([])
  const [client, setClient] = useState({ clientId: '', clientName: '' })

  useEffect(() => {
    setLoading(true)
    Promise.all([UserService.getClients(userId), ClientService.getAll()])
      .then(([userClients, all]) => {
        setClients(userClients.collection)
        setAllClients(all.collection)
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))

    return function cleanup() {
      setLoading(false)
    }
  }, [userId])

  const handleSelectAssignUser = useCallback(({ target: { name, value } }) => {
    setClient(value)
  }, [])

  const handleAssignClient = useCallback(() => {
    if (client.clientId.length) {
      UserService.assignClient({ userId, clientId: client.clientId })
        .then((response) => {
          if (response.succeeded) {
            const item = allClients.find(
              (item) => item.clientId === client.clientId
            )
            setClients([...clients, item])
          }
          handleCloseAddClientModal()
        })
        .catch((error) => console.error(error))
    }
  }, [client, allClients, clients, userId])

  const handleUnassignClient = useCallback(
    (id) => {
      UserService.unassignClient({ userId, clientId: id })
        .then((response) => {
          if (response.succeeded) {
            const items = clients.filter((item) => item.clientId !== id)
            setClients(items)
          }
          handleCloseDeleteClientModal()
        })
        .catch((error) => console.error(error))
    },
    [userId, clients]
  )

  const handleOpenAddClientModal = () => {
    setAddClientModalOpen(true)
  }

  const handleCloseAddClientModal = () => {
    setClient({ clientId: '', clientName: '' })
    setAddClientModalOpen(false)
  }

  const handleOpenDeleteClientModal = (id, name) => {
    setClient({ clientId: id, clientName: name })
    setDeleteClientModalOpen(true)
  }

  const handleCloseDeleteClientModal = () => {
    setClient({ clientId: '', clientName: '' })
    setDeleteClientModalOpen(false)
  }

  return (
    <div>
      {loading ? (
        <div className={classes.preloader}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <React.Fragment>
          <ResultsTable
            columns={[
              {
                name: 'clientId',
                label: 'Id',
                hidden: true
              },
              {
                name: 'clientName',
                label: 'Clients Name'
              },
              {
                name: 'clientId',
                label: '',
                isCustomContent: true,
                render: (rowData) => {
                  return (
                    <IconButton
                      className={classes.deleteIcon}
                      aria-label="delete"
                      onClick={() =>
                        handleOpenDeleteClientModal(
                          rowData.clientId,
                          rowData.clientName
                        )
                      }
                    >
                      <DeleteIcon fill="#BCBEBE" />
                    </IconButton>
                  )
                }
              }
            ]}
            data={clients}
            linkKey="id"
            orderBy="id"
            direction="desc"
          />
          <Grid container spacing={3} direction="row">
            <Grid container item xs={12} sm={12} md={12} direction="column">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                size="large"
                onClick={handleOpenAddClientModal}
              >
                Add Client
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <BasicModal
        headerIcon="/icons/admin-union-icon.svg"
        headerTitle="Add client"
        submitButtonText="Add"
        onSubmit={handleAssignClient}
        onClose={handleCloseAddClientModal}
        dialogContent={() => (
          <Box className={classes.contentBox}>
            <label className={classes.label}>Client Name</label>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
              style={{ minWidth: '200px' }}
            >
              <Select
                MenuProps={{ style: { zIndex: 1500 } }}
                id="client-name"
                value={client}
                onChange={handleSelectAssignUser}
              >
                {allClients
                  .filter(
                    (item) =>
                      !clients
                        .map((client) => client.clientId)
                        .includes(item.clientId)
                  )
                  .map((item) => (
                    <MenuItem key={item.clientId} value={item}>
                      {item.clientName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        open={addClientModalOpen}
      ></BasicModal>
      <BasicModal
        headerIcon="/icons/admin-delete-icon.svg"
        headerTitle="Confirm client deletion"
        submitButtonText="Confirm"
        onClose={handleCloseDeleteClientModal}
        onSubmit={() => handleUnassignClient(client.clientId)}
        dialogContent={() => (
          <Box className={classes.contentBox}>
            <Box mb={2}>
              <label className={classes.label}>Client Name</label>
              <TextField
                className={classes.input}
                fullWidth
                name="clientName"
                value={client.clientName}
                InputProps={{
                  readOnly: true
                }}
              />
            </Box>
          </Box>
        )}
        open={deleteClientModalOpen}
      ></BasicModal>
    </div>
  )
}
export default ClientsTab
