import React, { useState, useEffect } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./Reports.css";
import PowerBiService from "../../services/PowerBiService";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#E4006D",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E4006D",
      },
    },
  },
  title: {
    fontWeight: "600",
    marginBottom: "10px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
  },
  divider: {
    backgroundColor: "#E6ADA5",
  },
  input: {
    borderRadius: "30px",
  },
  label: {
    marginBottom: "10px",
    color: "rgba(168, 118, 163, 1)",
    fontWeight: "500",
    fontSize: "12px",
  },
  preloader: {
    display: "flex",
    justifyContent: "center",
    padding: "40px 0",
  },
}));

const PowerBiReport = ({
  match: {
    params: { groupID, reportID },
  },
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [reportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  });

  useEffect(() => {
    fetchPowerBiReportDetails(reportID, groupID);
  }, []);

  const fetchPowerBiReportDetails = (reportId, groupID) => {
    setLoading(true);
    PowerBiService.getPowerBiEmbedData({ reportId, groupID })
      .then((response) => {
        const embedUrl = response.embedUrl;
        const embedToken = response.embedToken;

        setReportConfig({
          ...reportConfig,
          embedUrl: embedUrl,
          accessToken: embedToken,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: true,
              },
              pageNavigation: {
                visible: true,
                position: models.PageNavigationPosition.Left,
              },
            },
          },
        });
      })
      .catch((error) => console.warn(error))
      .finally(() => setLoading(false));
  };

  return (
    <div>
      {loading ? (
        <div className={classes.preloader}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <PowerBIEmbed
            embedConfig={reportConfig}
            cssClassName={"report-style-class"}
          />
        </>
      )}
    </div>
  );
};

export default PowerBiReport;
