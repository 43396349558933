import React from 'react'
import clsx from 'clsx'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { getComparator, stableSort } from '../../../utils/dataSorts'

const useStyles = makeStyles(() => ({
    root: {
        overflowX: 'auto'
    },
    border0: {
        border: 'none'
    },
    tableTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '400',
        fontSize: '14px'
    },
    link: {
        cursor: 'pointer'
    },
    progressHolder: {
        display: 'flex',
        justifyContent: 'start',
        paddingTop: '50px',
        paddingBottom: '50px'
    }
}))

const ResultsTable = ({
  columns,
  data,
  linkKey,
  orderBy,
  direction,
  baseLink,
  hideHeader,
  onRowClick,
  unborderColumns
}) => {
  const classes = useStyles()
  const history = useHistory()

  const handleRedirect = (link) => history.push(link)

  const handleRowClick = (item) => {
    if (onRowClick) return onRowClick(item)
    if (baseLink) return handleRedirect(`${baseLink}/${item[linkKey]}`)
    return
  }

    return (data && data.length > 0
            ? (columns && columns.length) > 0
                ? (
                    <TableContainer className={classes.root}>
                      <Table>
                        {!hideHeader ? (
                          <TableHead>
                            <TableRow>
                              {columns.map((column, index) =>
                                !column.hidden ? (
                                  <TableCell
                                    className={classes.tableTitle}
                                    key={index}
                                    width={column.width ? column.width : 'auto'}
                                    sortDirection={orderBy === column.name ? direction : false}
                                  >
                                    {column.label}
                                  </TableCell>
                                ) : null
                              )}
                            </TableRow>
                          </TableHead>
                        ) : null}
                        <TableBody>
                          {data &&
                            stableSort(
                              data,
                              getComparator(
                                direction ? direction : 'desc',
                                orderBy,
                                columns.find((c) => c.name === orderBy)?.type
                              )
                            ).map((item, index) => (
                              <TableRow
                                key={index}
                                hover
                                onClick={() => handleRowClick(item)}
                              >
                                {columns.map((column, key) =>
                                  !column.hidden ? (
                                    <TableCell
                                      key={key}
                                      width={column.width ? column.width : 'auto'}
                                      className={clsx({ [classes.border0]: unborderColumns })}
                                    >
                                      {column.isCustomContent
                                        ? column.render(item)
                                        : item[column.name]}
                                    </TableCell>
                                  ) : null
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                )
                : null
            : <div className={classes.progressHolder}>No results returned</div>)
}

export default ResultsTable
