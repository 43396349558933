import React, { useState, useEffect } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./hoc/layouts/AuthLayout";
import SignIn from "./containers/Auth/SignIn/SignIn";
import GlobalCssOverride from "./globalTheme";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MainLayout from "./hoc/layouts/MainLayout";
import MainLayoutNoPreloader from "./hoc/layouts/MainLayoutNoPreloader";
import ScrollToTop from "./hoc/ScrollToTop";
import Properties from "./containers/Properties/Properties";
import PurchaseOrders from "./containers/PurchaseOrders/PurchaseOrders";
import ClientContracts from "./containers/ClientContracts/ClientContracts";
import NewPurchaseOrder from "./containers/PurchaseOrders/NewPurchaseOrder";
import PurchaseOrderDetails from "./containers/PurchaseOrders/PurchaseOrderDetails";
import JobsDataGrid from "./containers/Jobs/JobsDataGrid";
import PropertySummary from "./containers/Properties/PropertySummary";
import ClientContractSummary from "./containers/ClientContracts/ClientContractSummary";
import JobSummary from "./containers/JobSummary/JobSummary";
import CreateJob from "./containers/Jobs/CreateJob";
import ScheduleJob from "./containers/Jobs/ScheduleJob";
import Appliance from "./containers/Appliance/Appliance";
import Instructions from "./containers/Instructions/Instructions";
import Reports from "./containers/Reports/Reports";
import AssertReports from "./containers/Reports/AssertReports";
import PowerBiReport from "./containers/Reports/PowerBiReport";
import ServiceExpiryReports from "./containers/Reports/ServiceExpiryReports";
import Admin from "./containers/Admin/Admin";
import AddNewUser from "./containers/Admin/Users/AddNewUser";
import ManageUsers from "./containers/ManageUsers/ManageUsers";
import AddNewPowerBiReport from "./containers/Admin/PowerBi/AddNewPowerBiReport";
import UsersGrid from "./containers/Admin/Users/UsersGrid";
import ManagePowerBiReport from "containers/Admin/PowerBi/ManagePowerBiReport";
import PowerBiReportsManagement from "containers/Admin/PowerBi/PowerBiReportsManagement";
import ClientContext from "utils/globalClientContext";
import ClientService from "services/ClientService";
import AutoLogout from "utils/autoLogout";
import authentication from 'auth/AuthenticationProvider'
import { datadogRum }
    from

    '@datadog/browser-rum'
    ;

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
});

const App = () => {

    const [globalClient, setGlobalClient] = useState(null);
    const [globalClientList, setGlobalClientList] = useState([]);
    const [globalUserRole, setGlobalUserRole] = useState('User');
    const [globalUserName, setGlobalUserName] = useState('User');
    const [showGlobalClientList, setShowGlobalClientList] = useState(false);
    const [showPreloader, setShowPreloader] = useState(false);
    const value = { globalClient, setGlobalClient, globalClientList, setGlobalClientList, showGlobalClientList, setShowGlobalClientList, showPreloader, setShowPreloader, globalUserRole, setGlobalUserRole, globalUserName, setGlobalUserName };
    const [loadingGlobal, setLoadingGlobal] = useState(true)

    datadogRum.init({
        applicationId: '7409b4a1-0697-4e83-a688-1f209b5ec722',
        clientToken: 'pub2ba946e374a4acfa4bd2f703ebaf25f5',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.eu',
        service: 'liberty-portal-v2-test',
        env: 'staging',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0', 
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });

    useEffect(() => {
        getUserRole();
        getClientContracts();        
    }, [])

    const getClientContracts = () => {
        ClientService.getClientContracts()
            .then((response) => {
                const data = response.collection
                    .sort((item) => item.clientName)
                setGlobalClientList(data)
            })
            .catch((error) => console.warn(error))
            .finally(() => { setLoadingGlobal(false); })
    }

    const getUserRole = () => {
        const role = authentication.getUserRole();
        setGlobalUserRole(role);
        const username = authentication.getUserName();
        setGlobalUserName(username);
    }
        
  const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
      {...rest}
          render={(props) => (
              <ClientContext.Provider value={value} >
                 <Layout>
                    <Component {...props} />
                </Layout>
              </ClientContext.Provider>
      )}
    />
  );

  let routes = (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <AppRoute path="/signin" component={SignIn} layout={AuthLayout} />
        <AppRoute
          path="/properties"
          component={Properties}
          layout={MainLayout}
        />
        <AppRoute
            path="/client-contracts/:id"
            component={ClientContractSummary}
            layout={MainLayout}
        />
        <AppRoute
            path="/client-contracts"
            component={ClientContracts}
            layout={MainLayout}
              />
              <AppRoute
                  path="/purchase-orders/:id"
                  component={PurchaseOrderDetails}
                  layout={MainLayout}
              />
        <AppRoute
            path="/new-purchase-order"
            component={NewPurchaseOrder}
            layout={MainLayout}
        />
        <AppRoute
            path="/purchase-orders"
            component={PurchaseOrders}
            layout={MainLayout}
        />
              
        <AppRoute
          key={"/property/:id"}
          exact
          path="/property/:id"
          component={PropertySummary}
          layout={MainLayout}
        />
        <AppRoute
          key={"/property/:propertyId/appliance/:id"}
          exact
          path="/property/:propertyId/appliance/:id"
          component={Appliance}
          layout={MainLayout}
        />
        <AppRoute path="/reports" component={Reports} layout={MainLayout} />
        <AppRoute
          path="/assert-reports"
          component={AssertReports}
          layout={MainLayout}
        />
        <AppRoute
          path="/power-bi/:groupID/:reportID"
          component={PowerBiReport}
          layout={MainLayout}
        />

        <AppRoute
          path="/service-expiry-reports"
          component={ServiceExpiryReports}
          layout={MainLayout}
        />

         <AppRoute path="/jobs" component={JobsDataGrid} layout={MainLayout} /> 
        <AppRoute
          key={"/job-detail/:id/:uprn/:clientId"}
          exact
          path="/job-detail/:id/:uprn/:clientId"
          component={JobSummary}
          layout={MainLayout}
        />
        <AppRoute
          key={"/job/:jobId/instruction/:id/:clientId/:uprn"}
          exact
          path="/job/:jobId/instruction/:id/:clientId/:uprn"
          component={Instructions}
          layout={MainLayout}
              />
              <AppRoute
                  key={"/create-job/:propertyId"}
                  exact
                  path="/create-job/:propertyId"
                  component={CreateJob}
                  layout={MainLayoutNoPreloader}
              />
              <AppRoute
                  key={"/schedule-job/:clientId/:contractId/:jobId/:propertyId/:jobNo/:clientRef"}
                  exact
                  path="/schedule-job/:clientId/:contractId/:jobId/:propertyId/:jobNo/:clientRef"
                  component={ScheduleJob}
                  layout={MainLayout}
              />
              <AppRoute
                  key={"/schedule-job/:clientId/:contractId/:propertyId/:jobNo/:clientRef/:targetDate/:book"}
                  exact
                  path="/schedule-job/:clientId/:contractId/:propertyId/:jobNo/:clientRef/:targetDate/:book"
                  component={ScheduleJob}
                  layout={MainLayout}
              />
        <AppRoute
          path="/admin"
          component={Admin}
          exact
          key="/admin"
          layout={MainLayout}
        />
        <AppRoute
          path="/admin/users"
          component={UsersGrid}
          exact
          key="/admin"
          layout={MainLayout}
        />
        <AppRoute
          key={"/admin/users/add-new-user"}
          path="/admin/users/add-new-user"
          exact
          component={AddNewUser}
          layout={MainLayout}
        />
        <AppRoute
          key={"/admin/users/:userId"}
          path="/admin/users/:userId"
          exact
          component={ManageUsers}
          layout={MainLayout}
        />
        <AppRoute
          key={"/admin/power-bi-management"}
          exact
          path="/admin/power-bi-management"
          component={PowerBiReportsManagement}
          layout={MainLayout}
        />
        <AppRoute
          key={"/admin/power-bi-management/add-new-report"}
          exact
          path="/admin/power-bi-management/add-new-report"
          component={AddNewPowerBiReport}
          layout={MainLayout}
        />
        <AppRoute
          key={"/admin/power-bi-management/:reportId"}
          exact
          path="/admin/power-bi-management/:reportId"
          component={ManagePowerBiReport}
          layout={MainLayout}
        />
        <Redirect from="/" to="/properties" />
        <AppRoute path="*">
          <AppRoute to="/" />
        </AppRoute>
      </Switch>
    </BrowserRouter>
  );

  return (
    <ThemeProvider theme={theme}>
      <AutoLogout>
        {GlobalCssOverride()}
        {routes}
      </AutoLogout>
    </ThemeProvider>
  );
};

export default App;
