import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Button,
    Grid,
    Card,
    Checkbox,
    CardContent,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune';


const useStyles = makeStyles(() => ({
    arrowHistory: {
        marginLeft: '50px'
    },
    tableIcon: {
        verticalAlign: 'bottom'
    },
    overlayOpen: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 3000,
        transition: 'all 1s ease',
        transitionProperty: 'background-color, visibility',
        visibility: 'visible'
    },
    overlayClosed: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 3000,
        transition: 'all 1s ease',
        transitionProperty: 'background-color, visibility',
        transitionDelay: '0.3s',
        visibility: 'hidden'
        
    },
    filterMenuOpen: {
        height: '100vh',
        width: '300px',
        backgroundColor: '#ffffff',
        padding: '77px 50px',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 3000,
        borderRadius: '0px 16px 16px 0px',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        transition: 'left 1s ease',
        
    },
    filterMenuClosed: {
        height: '100vh',
        width: '300px',
        backgroundColor: '#ffffff',
        padding: '77px 50px',
        position: 'absolute',
        left: '-300px',
        top: 0,
        zIndex: 3000,
        borderRadius: '0px 16px 16px 0px',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        transition: 'left 1s ease',
    },
    filter: {
        textTransform: 'capitalize',
        color: '#E4006D',
        padding: '0px',
        width: 'auto',
        marginBottom: '60px'
    },
    checkbox: {
        '& .MuiSvgIcon-root': {
            width: '0.9em',
            height: '0.9em',
        }
    },
    groupLabel: {
        fontWeight: '500',
        fontSize: '12px',
        color: '#A876A3',
        marginBottom: '10px'
    },
    checkBoxLabel: {
        height: '33px',
        '& .MuiFormControlLabel-label': {
            fontWeight: '400',
            fontSize: '12px',
            color: '#595C5C',
        },
    },


}));



const FilterMenuContainer = (props) => {

    const {
        showFilterMenu,
        onShowFilterMenu,
        filterMenuContent,
    } = props

    const classes = useStyles();

    return (

        <div className={showFilterMenu===true ? classes.overlayOpen : classes.overlayClosed}>

            <div className={showFilterMenu===true ? classes.filterMenuOpen : classes.filterMenuClosed}>

            <Button
                onClick={onShowFilterMenu}
                size="large"
                color="primary"
                className={classes.filter}
                startIcon={<TuneIcon />}
            >
                    Filters
             </Button>

                {filterMenuContent()}
            

            </div>

        </div>

    );
};

export default FilterMenuContainer;
