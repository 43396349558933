import http from '../utils/http'

const getAll = (query) => {
    const params = new URLSearchParams()
    const page = query.hasOwnProperty('page') ? query.page : 1
    const size = query.hasOwnProperty('size') ? query.size : 100
    params.append('PageNumber', page)
    params.append('PageSize', size)

    if (query.hasOwnProperty('filterText') && query.filterText) {
        params.append('SearchText', query.filterText)
    }

    if (query.hasOwnProperty('filterApproval') && query.filterApproval) {
        params.append('ApprovalStatusID', Number.parseInt(query.filterApproval))
    }

    if (query.hasOwnProperty('filterContract') && query.filterContract && query.filterContract!=="-1") {
        params.append('ClientContractID', query.filterContract)
    }
    
    if (query.hasOwnProperty('filterContract') && query.filterContract && query.filterContract !=="-1") {
        params.append('ClientContractID', query.filterContract)
    }

    return http.get(`/PurchaseOrder`, { params })
}

const getById = (id) => http.get(`/PurchaseOrder/${id}`)

const getJobDetails = (id) => http.get(`/PurchaseOrder/${id}/job-details`)

const getSuppliers = () => {
    return http.get(`/PurchaseOrder/suppliers`);
}

const getParts = (supplier, clientContract, exclusions) => {
    const params = new URLSearchParams()
    params.append('IncludeExclusions', exclusions)
    params.append('ClientContractID',clientContract)
    params.append('SupplierID', supplier)

    return http.get(`/PurchaseOrder/parts`, { params })
}

const getApprovers = (clientContract,searchText) => {
    const params = new URLSearchParams()
    params.append('ClientContractID', clientContract)

    return http.get(`/PurchaseOrder/approvers`, { params })
}

const getBranches = (supplierID) => {
    const params = new URLSearchParams()
    params.append('SupplierID', supplierID)

    return http.get(`/PurchaseOrder/branches`, { params })
}

const update = (purchaseOrder) => {
    console.log('for update', purchaseOrder);
    return http.put(`/PurchaseOrder/update`, purchaseOrder);
}

const create = (purchaseOrder) => {
    if (!purchaseOrder.supplierBranchID) { delete purchaseOrder.supplierBranchID; }
    if (!purchaseOrder.companyDivisionID) { delete purchaseOrder.companyDivisionID; }
    if (!purchaseOrder.instructionID) { delete purchaseOrder.instructionID; }
    if (!purchaseOrder.jobID) { delete purchaseOrder.jobID; }
    console.log('for create', purchaseOrder);
    return http.post(`/PurchaseOrder/create`, purchaseOrder);
}

const email = (purchaseOrderID,to) => {
    return http.get(`/PurchaseOrder/email?PurchaseOrderID=${purchaseOrderID}&to=${to}`)
}

const print = (purchaseOrderID) => {
    return http.get(`/PurchaseOrder/print?PurchaseOrderID=${purchaseOrderID}`)
}

const cancel = (purchaseOrderID) => {
    return http.put(`/PurchaseOrder/cancel?PurchaseOrderID=${purchaseOrderID}`);
}

export default {
    getAll,
    getById,
    getJobDetails,
    getSuppliers,
    getParts,
    getApprovers,
    getBranches,
    update,
    create,
    email,
    print,
    cancel
}
