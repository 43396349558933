import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Backdrop,
  CircularProgress,
} from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    preloaderContainer: {
        zIndex: 9999,
        position: 'fixed',
    },
        preloader: {  
        display: 'flex',
        justifyContent: 'center',
        padding: '40px 0'
    },
}))

const Preloader = (props) => {

  const classes = useStyles()


    return (
        <div>
            <div className={classes.preloaderContainer}>
                <Backdrop
                    sx={{ color: '#fff', position: 'fixed', zIndex: 99999 }}
                    open={props.open}
                    onClick={props.handleClose}
                >

                    <div className={classes.preloader}>
                        <CircularProgress color="secondary" />
                    </div>

                </Backdrop>
                
            </div>
            {props.children}
        </div>
       

  )
}
export default Preloader
