import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid, Divider } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  contactDetailsItems: {
    paddingTop: '12px',
    paddingBottom: '35px'
  },
  greyDivider: {
    backgroundColor: '#DEDEDE',
    marginBottom: '20px'
  }
}));

const ListOfNotes = ({ items = [] }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {items.map((item, index, arr) => (
        <div key={index}>
          <Grid item xs container direction="row">
            <Box className={classes.contactDetailsItems}>
              <Typography variant="body2">{item.date}</Typography>
              <Typography variant="body1">{item.noteText}</Typography>
            </Box>
          </Grid>
          {index !== arr.length - 1 ? (
            <Divider className={classes.greyDivider} />
          ) : null}
        </div>
      ))}
    </React.Fragment>
  );
};

export default ListOfNotes;
