import axios from 'axios';
import authentication from '../auth/AuthenticationProvider';

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_API
});

http.interceptors.response.use(
  (response) => response.data,
  (error) => {
    switch (error.response?.status) {
      case 401:
        authentication.signOut();
        break;
      default:
        console.log('Bad request');
    }
    return Promise.reject(error);
  }
);

export default http;
