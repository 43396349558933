import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  TextField,
} from "@material-ui/core";

import DeleteIcon from "../ui/Icons/DeleteIcon";
import ClientService from "../../services/ClientService";
import ResultsTable from "../ui/ResultsTable/ResultsTable";
import BasicModal from "../../components/ui/BasicModal/BasicModal";
import PowerBiService from "../../services/PowerBiService";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    width: "fit-content!important",
    padding: "10px 30px",
  },
  label: {
    marginBottom: "10px",
    color: "rgba(168, 118, 163, 1)",
    fontWeight: "500",
    fontSize: "12px",
  },
  contentBox: {
    padding: "30px 50px 10px 50px",
  },
  preloader: {
    display: "flex",
    justifyContent: "center",
    padding: "40px 0",
  },
  deleteIcon: {
    float: "right",
    padding: 0,
  },
}));

const INITIAL_ACCESS_MODAL_VALUE = { clientId: "", clientName: "", role: 0 };

function mapPowerBiReportAccessItem({ id, roleName, client, reportID }) {
  return {
    id,
    roleName,
    clientName: client.clientName,
    reportID,
  };
}

const PowerBiReportPermissionsTab = ({ reportId }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [provideAccessModalOpen, setProvideAccessModalOpen] = useState(false);
  const [deleteClientModalOpen, setDeleteAccessModalOpen] = useState(false);

  const [permissions, setPermissions] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [access, setAccess] = useState(INITIAL_ACCESS_MODAL_VALUE);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      PowerBiService.getPowerBiReportAccesses(reportId),
      ClientService.getAll(),
    ])
      .then(([permissions, all]) => {
        setPermissions(permissions.collection.map(mapPowerBiReportAccessItem));
        setAllClients(all.collection);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));

    return function cleanup() {
      setLoading(false);
    };
  }, [reportId]);

  const getPowerBiReportAccesses = () => {
    setLoading(true);
    PowerBiService.getPowerBiReportAccesses(reportId)
      .then((permissions) => {
        setPermissions(permissions.collection.map(mapPowerBiReportAccessItem));
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const handleSelectClient = useCallback(({ target: { name, value } }) => {
    setAccess({ ...access, clientId: value });
  }, []);

  const handleSelectRole = useCallback(({ target: { name, value } }) => {
    setAccess({ ...access, role: value });
  }, []);

  const handleProvideAccess = useCallback(() => {
    if (access.clientId.length) {
      PowerBiService.provideAccessToPowerBiReport(
        reportId,
        access.clientId,
        access.role
      )
        .then((response) => {
          if (response.succeeded) {
            getPowerBiReportAccesses();
          }
          handleCloseProvideAccessModal();
        })
        .catch((error) => console.error(error));
    }
  }, [access, allClients, permissions, reportId]);

  const handleDeleteAccess = useCallback(
    (access) => {
      PowerBiService.deletePowerBiReportAccess(access.id)
        .then((response) => {
          if (response.succeeded) {
            getPowerBiReportAccesses();
          }
          handleCloseDeleteAccessModal();
        })
        .catch((error) => console.error(error));
    },
    [reportId, permissions]
  );

  const handleOpenProvideAccessModal = () => {
    setProvideAccessModalOpen(true);
  };

  const handleCloseProvideAccessModal = () => {
    setAccess(INITIAL_ACCESS_MODAL_VALUE);
    setProvideAccessModalOpen(false);
  };

  const handleOpenDeleteAccessModal = (id, clientName, name) => {
    setAccess({
      ...INITIAL_ACCESS_MODAL_VALUE,
      id,
      clientName,
      roleName: name,
    });
    setDeleteAccessModalOpen(true);
  };

  const handleCloseDeleteAccessModal = () => {
    setAccess(INITIAL_ACCESS_MODAL_VALUE);
    setDeleteAccessModalOpen(false);
  };

  return (
    <div>
      {loading ? (
        <div className={classes.preloader}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <React.Fragment>
          <ResultsTable
            columns={[
              {
                name: "id",
                label: "Id",
                hidden: true,
              },
              {
                name: "clientName",
                label: "Client Name",
              },
              {
                name: "roleName",
                label: "Role Name",
              },
              {
                name: "clientName",
                label: "",
                isCustomContent: true,
                render: (rowData) => {
                  return (
                    <IconButton
                      className={classes.deleteIcon}
                      aria-label="delete"
                      onClick={() =>
                        handleOpenDeleteAccessModal(
                          rowData.id,
                          rowData.clientName,
                          rowData.roleName
                        )
                      }
                    >
                      <DeleteIcon fill="#BCBEBE" />
                    </IconButton>
                  );
                },
              },
            ]}
            data={permissions}
            linkKey="id"
            orderBy="id"
            direction="desc"
          />
          <Grid container spacing={3} direction="row">
            <Grid container item xs={12} sm={12} md={12} direction="column">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                size="large"
                onClick={handleOpenProvideAccessModal}
              >
                Provide Access
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <BasicModal
        headerIcon="/icons/admin-union-icon.svg"
        headerTitle="Provide access"
        submitButtonText="Add"
        onSubmit={handleProvideAccess}
        onClose={handleCloseProvideAccessModal}
        dialogContent={() => (
          <Box className={classes.contentBox}>
            <Box mb={2}>
              <label className={classes.label}>Client Name</label>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                style={{ minWidth: "200px" }}
              >
                <Select
                  MenuProps={{ style: { zIndex: 1500 } }}
                  id="client-name"
                  value={access.clientId}
                  onChange={handleSelectClient}
                >
                  {allClients
                    .filter(
                      (item) =>
                        !permissions
                          .map((permission) => permission.clientID)
                          .includes(item.clientId)
                    )
                    .map((item) => (
                      <MenuItem key={item.clientId} value={item.clientId}>
                        {item.clientName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box mb={2}>
              <label className={classes.label}>Role Name</label>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                style={{ minWidth: "200px" }}
              >
                <Select
                  MenuProps={{ style: { zIndex: 1500 } }}
                  id="role"
                  name="role"
                  value={access.role}
                  displayEmpty
                  onChange={handleSelectRole}
                >
                  <MenuItem value={1}>User</MenuItem>
                  <MenuItem value={0}>Admin</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        open={provideAccessModalOpen}
      ></BasicModal>
      <BasicModal
        headerIcon="/icons/admin-delete-icon.svg"
        headerTitle="Confirm access deletion"
        submitButtonText="Confirm"
        onClose={handleCloseDeleteAccessModal}
        onSubmit={() => handleDeleteAccess(access)}
        dialogContent={() => (
          <Box className={classes.contentBox}>
            <Box mb={2}>
              <label className={classes.label}>Client Name</label>
              <TextField
                className={classes.input}
                fullWidth
                name="clientName"
                value={access.clientName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box mb={2}>
              <label className={classes.label}>Role Name</label>
              <TextField
                className={classes.input}
                fullWidth
                name="clientName"
                value={access.roleName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
        )}
        open={deleteClientModalOpen}
      ></BasicModal>
    </div>
  );
};
export default PowerBiReportPermissionsTab;
