import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Divider,
  Button,
  Box,
  Select,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Grid,
  TablePagination,
  CircularProgress
} from '@material-ui/core'
import SearchPanelProperties from '../../components/ui/SearchPanelProperties/SearchPanelProperties'
import ResultsTable from '../../components/ui/ResultsTable/ResultsTable'
import PropertyService from '../../services/PropertyService'
import ClientService from '../../services/ClientService'
import { useHistory } from 'react-router-dom'
import ClientContext from "../../utils/globalClientContext"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#E4006D'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E4006D'
      }
    }
  },
  title: {
    fontWeight: '600',
    marginBottom: '10px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none'
  },
  divider: {
    backgroundColor: '#E6ADA5'
  },
  input: {
    borderRadius: '30px'
  },
  label: {
    marginBottom: '10px',
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500',
    fontSize: '12px'
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 0'
  }
}))

const Properties = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const { setShowGlobalClientList } = useContext(ClientContext);
  
  const searchParams = new URLSearchParams(props.location.search)
  const searchAddress = searchParams.get('address') || ''
  const searchClientId = searchParams.get('clientId') || ''

  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState([])
  const [clientNames, setСlientNames] = useState([])
  const [propertyAddress, setPropertyAddress] = useState(searchAddress)
  const [clientId, setClientId] = useState(searchClientId)
  const [isSearched, setSearched] = useState(
    !(!searchAddress && !searchClientId)
  )
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(100)
  const [length, setLength] = useState(0)

  useEffect(() => {
      getClientNames()
      setShowGlobalClientList(false);
  }, [])

  useEffect(() => {
    if (isSearched) {
      setLoading(true)
      PropertyService.getAll({
        clientId,
        propertyAddress,
        size,
        page: page + 1
      })
          .then((response) => {
              console.log('got response', response);
          const items = response.value.collection.map((item) => ({
            id: item.propertyId,
            uprn: item.uprn,
            addressline1: item.address?.addressLine1,
            addressline2: item.address?.addressLine2,
            city: item.address?.addressLine3,
            postcode: item.address?.postCode,
            client: item.client?.clientName
          }))
          setLength(response.value.totalRecords)
          setProperties(items)
        })
        .catch((error) => console.warn(error))
        .finally(() => setLoading(false))
    }
  }, [propertyAddress, clientId, isSearched, page, size])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeSize = (event) => {
    setSize(event.target.value)
    setPage(0)
  }

  const handleChangeClientName = (event) => {
    setClientId(event.target.value)
  }

  const handleChangePropertyAddress = (event) => {
    setPropertyAddress(event.target.value)
  }

  const handleSearch = (searchValue, filterValue) => {
    setSearched(true)
    setPropertyAddress(searchValue)
    setClientId(filterValue)
    setSearchParams(searchValue, filterValue)
    setPage(0)
  }

  const getClientNames = () => {
    ClientService.getMyClients()
      .then((response) => {
        const data = response.collection
          .sort((item) => item.clientName)
          .map((item) => ({
            value: item.clientId,
            label: item.clientName
          }))
        setСlientNames(data)
      })
      .catch((error) => console.warn(error))
  }

  const setSearchParams = (searchValue, filterValue) => {
    const params = new URLSearchParams()
    if (searchValue) {
      params.append('address', searchValue)
    }

    if (filterValue) {
      params.append('clientId', filterValue)
    }

    history.push({
      pathname: '/properties',
      search: params.values.length > 0 ? '' : `?` + params
    })
  }

  return (
    <div>
      {!isSearched ? (
        <div>
          <div>
            <Typography className={classes.title} variant="h5">
              Property Search
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} sm={4}>
              <form className={classes.root} noValidate autoComplete="off">
                <Box>
                  <label className={classes.label}>Property Address</label>
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    fullWidth
                    id="address"
                    name="address"
                    placeholder="Enter Property Address, Postcode or UPRN"
                    value={propertyAddress}
                    onChange={handleChangePropertyAddress}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleSearch(propertyAddress, clientId)
                      }
                    }}
                  />
                </Box>
                <Box mt={3} mb={4}>
                  <label className={classes.label}>Client Name</label>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <Select
                      id="select-client-name"
                      displayEmpty
                      value={clientId}
                      onChange={handleChangeClientName}
                    >
                      <MenuItem key={0} value="">
                        All
                      </MenuItem>
                      {clientNames &&
                        clientNames.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size="large"
                  onClick={() => handleSearch(propertyAddress, clientId)}
                >
                  Search
                </Button>
              </form>
            </Grid>
            <Grid item xs={12} sm={8}></Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <SearchPanelProperties
            searchValue={propertyAddress}
            filterValue={clientId}
            filterItems={clientNames}
            onSearch={handleSearch}
            searchValuePlaceholder="Enter Property Address, Postcode or UPRN"
          />
          <div>
            <Typography className={classes.title} variant="h5">
              Search Results:
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} sm={12}>
              {loading ? (
                <div className={classes.preloader}>
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <ResultsTable
                  columns={[
                    {
                      name: 'id',
                      label: 'Id',
                      hidden: true
                    },
                    {
                      name: 'uprn',
                      label: 'UPRN'
                    },
                    {
                      name: 'addressline1',
                      label: 'Address Line 1'
                    },
                    {
                      name: 'addressline2',
                      label: 'Address Line 2'
                    },
                    {
                      name: 'city',
                      label: 'City'
                    },
                    {
                      name: 'postcode',
                      label: 'Postcode'
                    },
                    {
                      name: 'client',
                      label: 'Client'
                    }
                  ]}
                  data={properties}
                  baseLink="/property"
                  linkKey="id"
                  orderBy="uprn"
                  direction="asc"
                />
              )}
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 500]}
                component="div"
                count={length}
                rowsPerPage={size}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeSize}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default Properties
