import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Divider,
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BasicModal from "components/ui/BasicModal/BasicModal";
import PowerBiService from "services/PowerBiService";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "600",
    marginBottom: "10px",
  },
  divider: {
    backgroundColor: "#E6ADA5",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    width: "fit-content!important",
    padding: "10px 30px",
  },
  label: {
    marginBottom: "10px",
    color: "rgba(168, 118, 163, 1)",
    fontWeight: "500",
    fontSize: "12px",
  },
  contentBox: {
    padding: "30px 50px 10px 50px",
  },
  preloader: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    margin: "auto",
    top: "50%",
    left: "50%",
    width: "fit-content",
    height: "fit-content",
  },
  backButton: {
    width: "fit-content",
  },
}));

const INITIAL = {
  reportName: "",
  description: "",
  group: "",
  powerBiReport: "",
};

const INITIAL_MODAL = {
  name: "",
};

const AddNewPowerBiReport = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(INITIAL);
  const [modalState, setModalState] = useState(INITIAL_MODAL);
  const [groups, setGroups] = useState([]);
  const [reports, setReports] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    PowerBiService.getGroups()
      .then((response) => {
        setGroups(response.collection);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const onStateHandler = useCallback(
    ({ target: { name, value } }) => {
      const next = { ...state, [name]: value, [`${name}Error`]: "" };
      setState(next);
    },
    [state]
  );

  const handleSelectGroup = useCallback(({ target: { name, value } }) => {
    const next = { ...state, group: value, [`groupError`]: "" };
    getReports(value.id);
    setState(next);
  }, [state]);

  const handleSelectReport = useCallback(
    ({ target: { name, value } }) => {
      const next = {
        ...state,
        powerBiReport: value,
        reportName: value.name,
        description: value.description,
        [`powerBiReportError`]: "",
      };
      setState(next);
    },
    [state]
  );

  const getReports = (groupID) => {
    setLoading(true);
    PowerBiService.getReportsByGroupId(groupID)
      .then((response) => {
        setReports(response.collection);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const handleCreate = async () => {
    setLoading(true);
    PowerBiService.createPowerBiReport({
      reportName: state.reportName,
      description: state.description,
      groupID: state.group.id,
      powerBiReportID: state.powerBiReport.id,
    })
      .then((response) => {
        setModalState({
          name: state.reportName,
        });
        setOpenModal(true);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const handleCloseModal = () => {
    history.push("/power-bi-management");
  };

  return (
    <div>
      <Button
        variant="contained"
        className={classes.backButton}
        startIcon={<ArrowBackIcon />}
        onClick={() => history.goBack()}
      >
        Back to Power BI Reports List
      </Button>
      <Box mt={3}>
        <Typography className={classes.title} variant="h5">
          Add New Report
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={12} sm={12}>
          {loading ? (
            <div className={classes.preloader}>
              <CircularProgress color="secondary" />
            </div>
          ) : null}
          <Box mt={3}>
            <form noValidate autoComplete="off">
              <Grid container spacing={3} direction="column">
                <Grid item xs={12} container>
                  <Grid item xs container spacing={3}>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      direction="column"
                    >
                      <Box mb={2}>
                        <label className={classes.label}>Group</label>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            MenuProps={{ style: { zIndex: 1500 } }}
                            id="group"
                            value={state.group}
                            onChange={handleSelectGroup}
                            required
                          >
                            {groups.map((item) => (
                              <MenuItem key={item.id} value={item}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box mb={2}>
                        <label className={classes.label}>Report</label>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            MenuProps={{ style: { zIndex: 1500 } }}
                            id="report"
                            value={state.powerBiReport}
                            onChange={handleSelectReport}
                            required
                          >
                            {reports.map((item) => (
                              <MenuItem key={item.id} value={item}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs container spacing={3}>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      direction="column"
                    >
                      <Box mb={2}>
                        <label className={classes.label}>Name</label>
                        <TextField
                          className={classes.input}
                          variant="outlined"
                          fullWidth
                          id="name"
                          name="reportName"
                          value={state.reportName}
                          error={Boolean(state.reportError)}
                          helperText={state.reportError}
                          placeholder="Enter Name"
                          onChange={onStateHandler}
                          required
                        />
                      </Box>
                      <Box mb={2}>
                        <label className={classes.label}>Description</label>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            className={classes.input}
                            variant="outlined"
                            fullWidth
                            id="description"
                            name="description"
                            value={state.description}
                            error={Boolean(state.descriptionError)}
                            helperText={state.descriptionError}
                            placeholder="Enter Description"
                            onChange={onStateHandler}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} direction="row">
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      direction="column"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        size="large"
                        onClick={handleCreate}
                        disabled={loading}
                      >
                        Create Report
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
      <BasicModal
        headerIcon="/icons/admin-check-icon.svg"
        headerTitle="Report added successfully"
        submitButtonText="Close"
        onSubmit={handleCloseModal}
        dialogContent={() => (
          <Box className={classes.contentBox}>
            <Box mb={2}>
              <label className={classes.label}>Name</label>
              <TextField
                className={classes.input}
                fullWidth
                id="modal-reportName"
                name="name"
                value={modalState.name}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
        )}
        open={openModal}
      ></BasicModal>
    </div>
  );
};
export default AddNewPowerBiReport;
