import http from '../utils/http'

const getAll = (query) => {

  const params = new URLSearchParams()
  const nextPage = query.hasOwnProperty('nextPage') ? query.nextPage : '';
  params.append('NextPage', nextPage)

  if (query.hasOwnProperty('search') && query.search) {
    params.append('SearchText', query.search)
  }

  return http.get(`/UserManagement`, { params })
}

const getById = (id) => http.get(`/UserManagement/${id}`)

const getAuditLog = (query) => {
  const params = new URLSearchParams()
  // const size = query.hasOwnProperty('size') ? query.size : 100
  // params.append('PageSize', size)

  return http.get(`/UserManagement/${query.id}/audit-log`, { params })
}

const getSignInsLog = (query) => {
  const params = new URLSearchParams()
  // const size = query.hasOwnProperty('size') ? query.size : 100
  // params.append('PageSize', size)

  return http.get(`/UserManagement/${query.id}/sign-ins-log`, { params })
}

const createUser = (data) => http.post(`/UserManagement`, data)

const updateUser = (id, data) => http.put(`/UserManagement/${id}`, data)

const deleteUser = (id) => http.delete(`/UserManagement/${id}`)

const getClients = (id) => http.get(`/UserManagement/${id}/clients`)

const assignClient = (data) =>
  http.post(`/UserManagement/${data.userId}/assign-client`, data)

const unassignClient = (data) =>
  http.post(`/UserManagement/${data.userId}/unassign-client`, data)

const checkUserExist = (data) =>
  http.post(`/UserManagement/check-user-exists`, data)
const resetUserMFADetails = (userId) =>
  http.post(`/UserManagement/${userId}/reset-mfa-details`)

export default {
  getAll,
  getById,
  createUser,
  updateUser,
  deleteUser,
  getAuditLog,
  getSignInsLog,
  assignClient,
  unassignClient,
  getClients,
  checkUserExist,
  resetUserMFADetails
}
