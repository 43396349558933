import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core'
import FilterMenuContainer from "../../components/FilterMenuContainer/FilterMenuContainer";
import ClientContext from "../../utils/globalClientContext"


const useStyles = makeStyles(() => ({
    arrowHistory: {
        marginLeft: '50px'
    },
    tableIcon: {
        verticalAlign: 'bottom'
    },
    filter: {
        textTransform: 'capitalize',
        color: '#E4006D',
        padding: '0px',
        width: 'auto',
        marginBottom: '60px'
    },
    checkbox: {
        '& .MuiSvgIcon-root': {
            width: '0.9em',
            height: '0.9em',
        }
    },
    groupLabel: {
        fontWeight: '500',
        fontSize: '12px',
        color: '#A876A3',
        marginBottom: '10px'
    },
    checkBoxLabel: {
        height: '33px',
        '& .MuiFormControlLabel-label': {
            fontWeight: '400',
            fontSize: '12px',
            color: '#595C5C',
        },
    },
    subNavigationItem: {
        marginTop:'50px'
    },
}));

const ContractFilterMenu = (props) => {
    const classes = useStyles();

    const { showFilterMenu, onShowFilterMenu } = props;

    const { globalClient, setGlobalClient, globalClientList } = useContext(ClientContext);
    const [selectedClient, setSelectedClient] = useState(globalClient);
    
    const handleSelectedChange = (event) => {
        setSelectedClient(event.target.value);
        setGlobalClient(event.target.value);
    }

    const handleClearFilter = () => {
        setSelectedClient('-1');
        setGlobalClient('-1');
    } 

    const radioButtons = globalClientList
        .sort((item) => item.clientContractName)
        .map(item => <FormControlLabel key={item.clientContractID} 
            className={classes.checkBoxLabel} control={<Radio className={classes.checkbox} name={item.clientContractName} />}
            label={item.clientContractName} value={item.clientContractID}
        />);

    return (

        <FilterMenuContainer
            showFilterMenu={showFilterMenu}
            onShowFilterMenu={onShowFilterMenu}
            filterMenuContent={() => (

                <FormControl component="fieldset">
                    <FormLabel className={classes.groupLabel} component="legend">Global Client Filter</FormLabel>
                    <FormGroup>
                        <RadioGroup name="radio-buttons-group" value={selectedClient}
                            onChange={handleSelectedChange}>
                            {radioButtons }
                        </RadioGroup>
                        <Button
                            className={classes.subNavigationItem}
                            variant="outlined"
                            color="primary"
                            onClick={handleClearFilter}
                        >
                            Clear
                        </Button>
                    </FormGroup>
                </FormControl>

                )}
        >

        </FilterMenuContainer>

    );
};

export default ContractFilterMenu;
