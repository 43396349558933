import React, { useEffect, useState } from "react";
import LogOutModal from "components/ui/LogoutModal/LogoutModal";
import authentication from 'auth/AuthenticationProvider'

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

const timeoutMinutes = 180;

const AutoLogout = ({ children }) => {
    let timer;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleTimer();
            });
        });
    }, []);

    

    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };

    const handleTimer = () => {
        timer = setTimeout(() => {
            resetTimer();
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });
            setIsOpen(true)
        }, timeoutMinutes * 60 * 1000);
    };

    const handleLogout = () => {
        setIsOpen(false);
        authentication.signOut();
    };

    const handleClose = () => {
        setIsOpen(false);
    }

    return <div>
        {children}
        <LogOutModal open={isOpen} onClose={handleClose} onLogOut={handleLogout}></LogOutModal>
    </div>;
};

export default AutoLogout;