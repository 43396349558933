import React from "react";
import { useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Grid,
    Button,
    Divider,
    Typography
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const useStyles = makeStyles(() => ({
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500'
    },
    titleBoxTwo: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '60px',
        marginBottom: '20px'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '30px',
    },
    check: {
        fill: '#6FCF97',
        fontSize: '29px',
        verticalAlign: 'middle',
        marginRight: '15px',
        marginBottom: '5px'
    },
    error: {
        fill: '#f74A36',
        fontSize: '29px',
        verticalAlign: 'middle',
        marginRight: '15px',
        marginBottom: '5px'
    },
    button: {
        width: '157px!important',
        height: '48px',
        marginTop: '50px',
        marginBottom: '20px',
        textTransform: 'capitalize'
    }
}));

const ScheduleResult = (props) => {
    const classes = useStyles();
    const {
        success,
        message,
        date,
        time,
        updating,
        continueButton,
        backButton
    } = props

    
    return (<div>

            <div className={classes.titleBoxTwo}>
                <Typography className={classes.title} variant="h5">
                {
                    (updating) 
                        ? <div>Booking Slot</div>
                        : (success)
                        ? <div><CheckCircleOutlineIcon className={classes.check }/> Job Scheduled Successfully</div>
                        : <div><ErrorOutlineIcon className={classes.error} /> Failed to Schedule Job</div>
                }
                </Typography>
            </div>

        <Divider className={classes.divider} />

       
        {
            (updating)
                ? <Grid container>
                    <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                        <Typography variant="body1" color="primary">
                            Contacting DRS...
                        </Typography>
                    </Grid>
                </Grid>
                : (success)
                ? <Grid container>
                    <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                                <Box>
                                    <Typography variant="body2" className={classes.itemTitle}>
                                        Date
                                    </Typography>
                                    <Typography variant="body1" color="primary">
                                        {date}
                                    </Typography>
                                </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} className={classes.fieldContainer}>
                                <Box>
                                    <Typography variant="body2" className={classes.itemTitle}>
                                        Time Slot
                                    </Typography>
                                    <Typography variant="body1" color="primary">
                                        {time}
                                    </Typography>
                                </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={continueButton}
                                type="button"
                                className={classes.button}>
                                Continue
                            </Button>
                        </Grid>
                      </Grid>
                    : <Grid container>
                        <Grid item xs={12} s={12} className={classes.fieldContainer}>
                            <Box>
                                <Typography variant="body2" className={classes.itemTitle}>
                                    Error Message
                                </Typography>
                                <Typography variant="body1" color="primary">
                                    {message}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={backButton}
                                type="button"
                                className={classes.button}>
                                Back
                            </Button>
                        </Grid>
                    </Grid>
            }
            
        
        
    </div>

    );
};
export default ScheduleResult;
