import React, { useState, useEffect } from 'react';
import ResultsTable from '../ui/ResultsTable/ResultsTable';
import PropertyService from '../../services/PropertyService';

function createApplianceItem(id, type, manufacturer, model, scrapped) {
  return { id, type, manufacturer, model, scrapped };
}

const ApplianceTab = ({ propertyId, uprn, clientId }) => {
  const [appliances, setAppliances] = useState([]);

    const getAppliances = (clientId, uprn) => {
      
    PropertyService.getAppliances(clientId, uprn)
      .then((response) => {
        const items = response.value.collection.map((i) =>
          createApplianceItem(
            i.applianceId,
            i.type?.literalText,
            i.manufacturer?.literalText,
            i.model?.literalText,
            i.scrapped
          )
        );

        setAppliances(items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

    useEffect(() => {
        if (clientId && uprn && clientId !== '' && uprn !== '') {
            getAppliances(clientId, uprn);
        }
  }, [clientId, uprn]);

  return (
    <div>
      <ResultsTable
        columns={[
          {
            name: 'id',
            label: 'Id',
            hidden: true
          },
          {
            name: 'type',
            label: 'Type'
          },
          {
            name: 'manufacturer',
            label: 'Manufacturer'
          },
          {
            name: 'model',
            label: 'Model'
          },
          {
            name: 'scrapped',
            label: 'Scrapped/Removed',
            isCustomContent: true,
            render: (rowData) => {
              return <span>{rowData.scrapped ? 'Yes' : 'No'}</span>;
            }
          }
        ]}
        data={appliances}
        baseLink={`${propertyId}/appliance`}
        linkKey="id"
        orderBy="id"
        direction="desc"
      ></ResultsTable>
    </div>
  );
};

export default ApplianceTab;
