import React from 'react'
import {
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  CardActions
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import GetAppIcon from '@material-ui/icons/GetApp'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DocumentService from '../../../services/DocumentService'
import {
  downloadFileFromBase64,
  convertBase64ToPdfBLob
} from '../../../utils/fileConverter'

const useStyles = makeStyles(() => ({
  downloadImgBtn: {
    textTransform: 'none',
    float: 'right'
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '16px!important'
  },
  cardActions: {
    padding: '0px'
  },
  cardBox: {
    width: '100%',
    marginBottom: '20px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)'
  },
  contentCardBox: {
    display: 'flex',
    alignItems: 'center'
  },
  fileName: {
    paddingLeft: '15px'
  }
}))

const ListOfFiles = (props) => {
  const classes = useStyles()

  const handleOnDownload = (file) => {
    downloadFile(file)
  }

  const handleOnDownloadAll = (event) => {
    props.onDownloadAll(event)
  }

    const downloadFile = (file) => {

        var docData = file.documentBody;
        var docName = file.fileName;

        if (!file.documentBody) {
            DocumentService.getById(file)
                .then((response) => {
                    if (response.object) {
                        docData = response.object.documentBody;
                        docName = response.object.fileName;
                        
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        }

        if (docData && docName) {
            downloadFileFromBase64(docName, docData);
        }
   
  }

    const handleOnView = (file) => {
        var docData = file.documentBody;

        if (!file.documentBody) {
            DocumentService.getById(file.documentId)
                .then((response) => {
                    if (response.object) {
                        docData = response.object.documentBody;                        
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        } 

        if (docData) {
            var file = convertBase64ToPdfBLob(docData)
            var fileURL = URL.createObjectURL(file)
            window.open(fileURL)
        }
   
  }

  return (
    <React.Fragment>
      {props.items?.map((item, index) => (
        <Grid item xs container direction="row" key={index}>
          <Card className={classes.cardBox}>
            <CardContent className={classes.cardContent}>
              <Box className={classes.contentCardBox}>
                <img src="/icons/pdf-icon.svg" alt="pdf icon" />
                <Box>
                  <Typography variant="body1" className={classes.fileName}>
                    {item.name}
                  </Typography>
                  <Typography variant="caption" className={classes.fileName}>
                    {item.date}
                  </Typography>
                </Box>
              </Box>
              <CardActions className={classes.cardActions}>
                <Button
                  size="small"
                  color="secondary"
                  startIcon={<VisibilityIcon />}
                  onClick={() => handleOnView(item)}
                >
                  View
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  onClick={() => handleOnDownload(item)}
                >
                  Download
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {props.items.length > 0 ? (
        <Button
          variant="contained"
          className={classes.downloadImgBtn}
          size="large"
          color="primary"
          onClick={handleOnDownloadAll}
          startIcon={<GetAppIcon />}
        >
          Download All Files
        </Button>
      ) : null}
    </React.Fragment>
  )
}

export default ListOfFiles
