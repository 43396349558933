import React, { useState, useEffect, useCallback, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import {
  Button,
  Typography,
  Breadcrumbs,
  Box,
  Grid,
  Divider
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import InstructionService from '../../services/InstructionService'
import { useHistory } from 'react-router-dom'
import Moment from 'moment'
import ResultsTable from '../../components/ui/ResultsTable/ResultsTable'
import ApplianceModal from '../../components/ApplianceModal/ApplianceModal'
import { format } from 'date-fns'
import BasicSOR from '../../components/ui/BasicSOR/BasicSOR'
import ClientContext from "../../utils/globalClientContext"
import {
    createSORItem
} from '../../utils/mapData'

const useStyles = makeStyles((theme) => ({
  root: {},
  titleBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '20px',
    marginBottom: '20px'
  },
  title: {
    borderRight: '1px solid #BCBEBE',
    paddingRight: '10px'
  },
  subTitle: {
    paddingLeft: '10px',
    color: '#BCBEBE'
  },
  padding: {
    padding: theme.spacing(3)
  },
  divider: {
    backgroundColor: '#E6ADA5',
    marginBottom: '20px'
  },
  greyDivider: {
    backgroundColor: '#DEDEDE',
    marginBottom: '20px'
  },
  breadcrumbs: {
    fontSize: '12px',
      marginBottom: '20px',
    marginTop:'20px',
    textTransform: 'uppercase',
    '& a': {
      color: 'inherit'
    },
    '& span': {
      color: '#E4006D',
      fontWeight: 'bold'
    }
  },
  contactDetailsItems: {
    paddingTop: '12px',
    paddingBottom: '35px'
  },
  itemTitle: {
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500'
  },
  tableTitle: {
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '400',
    fontSize: '14px'
  },
  arrowHistory: {
    marginLeft: '50px'
  },
  tableIcon: {
    verticalAlign: 'bottom'
  },
  alignRows: {
    alignItems: 'baseline'
  },
  timeContainer: {
    display: 'flex'
  },
  verticalLine: {
    borderLeft: '1px solid #DEDEDE',
    height: '100%',
    alignSelf: 'center',
    marginTop: '12px',
    marginBottom: '35px'
  },
  paddingTopContainer: {
    paddingTop: '12px'
  },
  columnMarginRight: {
    marginRight: theme.spacing(1)
    },
    purchaseOrderButton: {
        width: 'auto!important'
    }
}))

const Instructions = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const { jobId, id: instructionId, clientId, uprn } = props.match.params
  const { setShowGlobalClientList, globalUserRole } = useContext(ClientContext);
    

  const [modalData, setModalData] = useState(null)
  const [currentInstruction, setCurrentInstruction] = useState({})
  const [partsRequired, setPartsRequired] = useState([])
  const [partsFitted, setPartsFitted] = useState([])
  const [defectsIdentified, setDefectsIdentified] = useState([])
    const [appliancesSurvey, setAppliancesSurvey] = useState([])
    const [purchaseOrders, setPurchaseOrders] = useState([])
    const [sors, setSors] = useState([])

  const getInstruction = (instructionId, clientId) => {
    InstructionService.getById(instructionId, clientId)
        .then((response) => {
            console.log(response);
        const {instructionId, appointmentDateTime, preferredTime, status, startTime, endTime, outcome, client, clientContractId, tenantSignature, smv, actionRequired, ...rest } = response.object
        setCurrentInstruction({
            ...rest,
            id: instructionId,
            appointmentDate: appointmentDateTime ? Moment(appointmentDateTime).format('DD-MM-YYYY') : '',
            preferredTime: Moment(appointmentDateTime).format('HH:mm'),
            status: status?.literalText,
            startTime: startTime ? Moment(startTime).format('HH:mm') : null,
            endTime: endTime ? Moment(endTime).format('HH:mm') : null,
            outcome: outcome?.literalText,
            client: client?.clientName,
            clientContractID: clientContractId,
            tenantSignature: tenantSignature,
            smv: smv.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            actionRequired: actionRequired
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getPartsRequired = (instructionId, clientId) => {
    InstructionService.getPartsRequired(instructionId, clientId)
      .then((response) => {
        setPartsRequired(response.value.collection)
      })
      .catch((e) => {
        console.log(e)
      })
  }

    const getPartsFitted = (instructionId, clientId) => {
        InstructionService.getPartsFitted(instructionId, clientId)
      .then((response) => {
        setPartsFitted(response.value.collection)
      })
      .catch((e) => {
        console.log(e)
      })
    }

    const getSors = (instructionId, clientId) => {
        InstructionService.getActualSors(instructionId, clientId)
            .then((response) => {
                const items = response.collection.map((i) =>
                    createSORItem(i.code, i.description, i.qty, i.originalQty)
                )
                setSors(items)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getDefectsIdentified = (instructionId, clientId) => {
        InstructionService.getDefectsIdentified(instructionId, clientId)
      .then((response) => {
        setDefectsIdentified(response.value.collection)
      })
      .catch((e) => {
        console.log(e)
      })
  }

    const getAppliancesSurvey = (instructionId, clientId) => {
        InstructionService.getAppliancesSurvey(instructionId, clientId)
      .then((response) => {
        const data = response.value.collection.map((item) => {
          const { type, location, manufacturer, model, ...rest } = item
          return {
            ...rest,
            type: type?.literalText || '-',
            location: location?.literalText || '-',
            manufacturer: manufacturer?.literalText || '-',
            model: model?.literalText || '-'
          }
        })

        setAppliancesSurvey(data)
      })
      .catch((e) => {
        console.log(e)
      })
    }

    const getPurchaseOrders = (instructionNo) => {
        InstructionService.getPurchaseOrders(instructionNo)
            .then((response) => {
                const items = response.value.collection.map((item) => ({
                    id: item.purchaseOrderId,
                    clientContract: item.clientContractName,
                    clientContractID: item.clientContractID,
                    clientName: currentInstruction.client,
                    instruction: item.instructionNumber,
                    PONumber: item.purchaseOrderNumber,
                    POStatus: item.purchaseOrderStatus.literalText,
                    createdOn: format(new Date(item.createdOn), "dd-MM-yy HH:mm"),
                    currentCost: item.currentCost,
                    descriptionOfWorks: item.descriptionOfWorks,
                    initialCost: item.initialCost
                }))
                setPurchaseOrders(items);
            })
            .catch((error) => console.warn(error))
    }

useEffect(() => {
        if (currentInstruction && currentInstruction.instructionNumber) {
            getPurchaseOrders(currentInstruction.instructionNumber);
        }      
}, [currentInstruction])

  useEffect(() => {
    getInstruction(instructionId, clientId)
    getPartsRequired(instructionId, clientId)
    getPartsFitted(instructionId, clientId)
    getDefectsIdentified(instructionId, clientId)
      getAppliancesSurvey(instructionId, clientId)
      getSors(instructionId, clientId)
  }, [instructionId, clientId])

    

  const handleOpenModal = useCallback((data) => {
    setModalData(data)
  }, [])

  const handleCloseModal = useCallback(() => {
    setModalData(null)
  }, [])

  return (
      <div>
          <Grid container>
              <Grid item xs={12} md={8}>
                  <Button
                      variant="contained"
                      onClick={() => history.push(`/job-detail/${jobId}/${uprn}/${clientId}`)}
                      startIcon={<ArrowBackIcon />}
                  >
                      Back to Job
                  </Button>
                  <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                      <Link to={`/job/${jobId}`}>
                          INSTRUCTION DETAILS
                      </Link>
                      <span>Instruction №{currentInstruction.instructionNumber}</span>
                  </Breadcrumbs>
              </Grid>
              {(globalUserRole !== 'User' &&
                  <Grid item xs={12} md={4}>
                      <Button
                          component={Link}
                          underline="none"
                          to={`/new-purchase-order?clientContractID=${currentInstruction.clientContractID}&instruction=${currentInstruction.instructionNumber}&instructionID=${currentInstruction.id}&jobID=${jobId}`}
                          className={classes.purchaseOrderButton}
                          startIcon={<DescriptionOutlinedIcon />}
                          variant="contained"
                          size="large"
                          color="primary"
                          target="_new"
                      >
                          Create Purchase Order
                      </Button>
                  </Grid>
              )}
              
          </Grid>
      
      <Divider className={classes.divider} />
      <Grid
        container
        spacing={3}
        direction="row"
        className={classes.contactDetailsItems}
      >
        <Grid item xs={12} container>
          <Grid item xs container spacing={3} className={classes.alignRows}>
            <Grid container item xs={12} sm={12} md={3} direction="column">
              <Box>
                <Typography variant="h5">Instruction Details</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Grid
                item
                xs
                container
                direction="row"
                className={classes.paddingTopContainer}
              >
                <Grid item xs={12} container direction="row" >
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Appointment Date / Preferred Time
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.appointmentDate || currentInstruction.preferredTime  
                          ? `${currentInstruction.appointmentDate} ${currentInstruction.preferredTime}` : 'N/A'
                        }
                      </Typography>
                    </Box>
                                  </Grid>
                                  <Grid item sm={3} md={3} xs={12} container direction="column">
                                      <Box>
                                          <Typography variant="body2" className={classes.itemTitle}>
                                              SMV
                                          </Typography>
                                          <Typography variant="body1">
                                              {currentInstruction.smv}
                                          </Typography>
                                      </Box>
                                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Operative Assigned
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.operativeAssigned ? currentInstruction.operativeAssigned : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  
                              </Grid>
                              <Grid item xs={12} container direction="row" className={classes.paddingTopContainer}>
                                  
                                  <Grid item sm={4} md={4} xs={12} container direction="column">
                                      <Box>
                                          <Typography variant="body2" className={classes.itemTitle}>
                                              Status
                                          </Typography>
                                          <Typography variant="body1">
                                              {currentInstruction.status ? currentInstruction.status : 'N/A'}
                                          </Typography>
                                      </Box>
                                  </Grid>
                                  <Grid item sm={3} md={3} xs={12} container direction="column">
                                      <Box>
                                          <Typography variant="body2" className={classes.itemTitle}>
                                              PDA Status
                                          </Typography>
                                          <Typography variant="body1">
                                              {currentInstruction.pdaStatus ? currentInstruction.pdaStatus : 'N/A'}
                                          </Typography>
                                      </Box>
                                  </Grid>
                                  <Grid item sm={4} md={4} xs={12} container direction="column">
                                      <Box>
                                          <Typography variant="body2" className={classes.itemTitle}>
                                              Letter Stage
                                          </Typography>
                                          <Typography variant="body1">
                                              {currentInstruction.appointmentLetterStage != null ? currentInstruction.appointmentLetterStage : 'N/A'}
                                          </Typography>
                                      </Box>
                                  </Grid>
                              </Grid>
                              <Grid item xs={12} container direction="row" className={classes.paddingTopContainer}>

                                  <Grid item sm={4} md={4} xs={12} container direction="column">
                                      <Box>
                                          <Typography variant="body2" className={classes.itemTitle}>
                                              Action Required
                                          </Typography>
                                          <Typography variant="body1">
                                              {currentInstruction.actionRequired ? currentInstruction.actionRequired : 'N/A'}
                                          </Typography>
                                      </Box>
                                  </Grid>
                                  
                              </Grid>
                <Grid item xs={12} container direction="row" className={classes.paddingTopContainer} >
                  <Grid item sm={3} md={12} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Task Details
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.taskDescription ? currentInstruction.taskDescription : 'N/A'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.greyDivider} />

      <Grid
        container
        spacing={3}
        direction="row"
        className={classes.contactDetailsItems}
      >
        <Grid item xs={12} container>
          <Grid item xs container spacing={3} className={classes.alignRows}>
            <Grid container item xs={12} sm={12} md={3} direction="column">
              <Box>
                <Typography variant="h5">Visit Details</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Grid
                item
                xs
                container
                direction="row"
                className={classes.paddingTopContainer}
              >
                <Grid item xs={12} container direction="row" >
                  <Grid item sm={3} md={2} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Start Time
                      </Typography>
                      <Typography component={'div'} variant="body1">
                        <Box className={classes.timeContainer}>
                          <AccessTimeIcon />
                          {currentInstruction.startTime ? currentInstruction.startTime : '-'}
                        </Box>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={3} md={2} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        End Time
                      </Typography>
                      <Typography component={'div'} variant="body1">
                        <Box className={classes.timeContainer}>
                          <AccessTimeIcon />{' '}
                          {currentInstruction.endTime ? currentInstruction.endTime : '-'}
                        </Box>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={3} md={4} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Outcome
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.outcome ? currentInstruction.outcome : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={3} md={4} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Certificate Verification
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.certificateVerification ? currentInstruction.certificateVerification : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.greyDivider} />

      <Grid
        container
        spacing={3}
        direction="row"
        className={classes.contactDetailsItems}
      >
        <Grid item xs={12} container>
          <Grid item xs container spacing={3} className={classes.alignRows}>
            <Grid container item xs={12} sm={12} md={3} direction="column">
              <Box>
                <Typography variant="h5">Outcome Details</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Grid
                item
                xs
                container
                direction="row"
                className={classes.paddingTopContainer}
              >
                <Grid item xs={12} container direction="row" >
                  <Grid item xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Engineer Comments
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.engineerComments ? currentInstruction.engineerComments : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row" className={classes.paddingTopContainer}>
                  <Grid item sm={3} md={3} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Earth Bond Evident
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.earthBondEvident ? currentInstruction.earthBondEvident : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={3} md={3} xs={12} className={classes.columnMarginRight} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Label & Warning Notice Issued
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.labelandWarningNoticeIssued ? currentInstruction.labelandWarningNoticeIssued : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={3} md={3} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Warning Label Issued
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.warningLabelIssued ? currentInstruction.warningLabelIssued : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  
                </Grid>
                <Grid item xs={12} container direction="row" className={classes.paddingTopContainer}>
                  <Grid item sm={3} md={3} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Number of Smoke Alarms Installed
                    </Typography>
                      <Typography variant="body1">
                        {currentInstruction.numberofSmokeAlarms != null ? currentInstruction.numberofSmokeAlarms : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={3} md={3} xs={12} className={classes.columnMarginRight} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Number of Defective Smoke Alarms
                    </Typography>
                      <Typography variant="body1">
                        {currentInstruction.numberofDefectiveSmokeAlarms != null ? currentInstruction.numberofDefectiveSmokeAlarms : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={3} md={3} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Number of Temporary Heaters
                    </Typography>
                      <Typography variant="body1">
                        {currentInstruction.numberofTemporaryHeaters != null ? currentInstruction.numberofTemporaryHeaters : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row" className={classes.paddingTopContainer}>
                                  <Grid item sm={3} md={3} xs={12} container direction="column">
                                      <Box>
                                          <Typography variant="body2" className={classes.itemTitle}>
                                              Tightness Test
                                          </Typography>
                                          <Typography variant="body1">
                                              {currentInstruction.tightnessTestResult ? currentInstruction.tightnessTestResult : '-'}
                                          </Typography>
                                      </Box>
                                  </Grid>
                                  <Grid item sm={3} md={3} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Gas switched off at meter
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.gasSwitchedOffAtMeter ? currentInstruction.gasSwitchedOffAtMeter : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} className={classes.columnMarginRight} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Transporter / Gas Supplier Reference
                      </Typography>
                      <Typography variant="body1">
                        {currentInstruction.itt_TransporterGasSupplierReference ? currentInstruction.itt_TransporterGasSupplierReference : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.greyDivider} />

      {appliancesSurvey && appliancesSurvey.length ? (
        <React.Fragment>
          <Grid
            container
            spacing={3}
            direction="row"
            className={classes.contactDetailsItems}
          >
            <Grid item xs={12} container>
              <Grid item xs container spacing={3}>
                <Grid container item xs={12} sm={12} md={3} direction="column">
                  <Box>
                    <Typography variant="h5">Appliance Checks completed</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <ResultsTable
                    columns={[
                      {
                        name: 'type',
                        label: 'Type'
                      },
                      {
                        name: 'location',
                        label: 'Location'
                      },
                      {
                        name: 'manufacturer',
                        label: 'Manufacturer'
                      },
                      {
                        name: 'model',
                        label: 'Model'
                      }
                    ]}
                    data={appliancesSurvey}
                    linkKey="id"
                    orderBy="id"
                    direction="desc"
                    onRowClick={handleOpenModal}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.greyDivider} />
          <ApplianceModal open={Boolean(modalData)} onClose={handleCloseModal} data={modalData} />
        </React.Fragment>
      ) : (null)}

      {defectsIdentified && defectsIdentified.length ? (
        <React.Fragment>
          <Grid
            container
            spacing={3}
            direction="row"
            className={classes.contactDetailsItems}
          >
            <Grid item xs={12} container>
              <Grid item xs container spacing={3}>
                <Grid container item xs={12} sm={12} md={3} direction="column">
                  <Box>
                    <Typography variant="h5">Defects Identified</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <ResultsTable
                    columns={[
                      {
                        name: 'description',
                        label: 'Description'
                      },
                      {
                        name: 'category',
                        label: 'Category'
                      },
                      {
                        name: 'unsafeSituationCategory',
                        label: 'Unsafe Situation Category'
                      }
                    ]}
                    data={defectsIdentified}
                    linkKey="id"
                    orderBy="id"
                    direction="desc"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.greyDivider} />
        </React.Fragment>
      ) : (null)}

      {partsFitted && partsFitted.length ? (
        <React.Fragment>
          <Grid
            container
            spacing={3}
            direction="row"
            className={classes.contactDetailsItems}
          >
            <Grid item xs={12} container>
              <Grid item xs container spacing={3}>
                <Grid container item xs={12} sm={12} md={3} direction="column">
                  <Box>
                    <Typography variant="h5">Parts Fitted</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <ResultsTable
                    columns={[
                      {
                        name: 'description',
                        label: 'Description'
                      },
                      {
                        name: 'quantity',
                        label: 'Qty'
                      }
                    ]}
                    data={partsFitted}
                    linkKey="id"
                    orderBy="id"
                    direction="desc"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.greyDivider} />
        </React.Fragment>
      ) : (null)}

      {partsRequired && partsRequired.length ? (
        <React.Fragment>
          <Grid
            container
            spacing={3}
            direction="row"
            className={classes.contactDetailsItems}
          >
            <Grid item xs={12} container>
              <Grid item xs container spacing={3}>
                <Grid container item xs={12} sm={12} md={3} direction="column">
                  <Box>
                    <Typography variant="h5">Parts Required</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <ResultsTable
                    columns={[
                      {
                        name: 'description',
                        label: 'Description'
                      },
                      {
                        name: 'quantity',
                        label: 'Qty'
                      }
                    ]}
                    data={partsRequired}
                    linkKey="id"
                    orderBy="id"
                    direction="desc"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.greyDivider} />
        </React.Fragment>
          ) : (null)}
         {sors && sors.length ? (
        <React.Fragment>
          <Grid
            container
            spacing={3}
            direction="row"
            className={classes.contactDetailsItems}
          >
            <Grid item xs={12} container>
              <Grid item xs container spacing={3}>
                <Grid container item xs={12} sm={12} md={3} direction="column">
                  <Box>
                    <Typography variant="h5">SORs</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <BasicSOR items={sors}></BasicSOR>
                </Grid>
              </Grid>
            </Grid>
                  </Grid>
                  <Divider className={classes.greyDivider} />
        </React.Fragment>
      ) : null} 
          {globalUserRole!=='User' && purchaseOrders && purchaseOrders.length ? (
              <React.Fragment>
                  <Grid
                      container
                      spacing={3}
                      direction="row"
                      className={classes.contactDetailsItems}
                  >
                      <Grid item xs={12} container>
                          <Grid item xs container spacing={3}>
                              <Grid container item xs={12} sm={12} md={3} direction="column">
                                  <Box>
                                      <Typography variant="h5">Purchase Orders</Typography>
                                  </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={9}>
                                  <ResultsTable
                                      columns={[
                                          {
                                              name: 'createdOn',
                                              label: 'Date Raised'
                                          },
                                          {
                                              name: 'POStatus',
                                              label: 'Status'
                                          },
                                          {
                                              name: 'PONumber',
                                              label: 'PO Number'
                                          },
                                          {
                                              name: 'instruction',
                                              label: 'Instruction'
                                          },
                                          {
                                              name: 'initialCost',
                                              label: 'Initial Cost'
                                          },
                                          {
                                              name: 'currentCost',
                                              label: 'Current Cost'
                                          },
                                          {
                                              name: 'descriptionOfWorks',
                                              label: 'Desc. of works'
                                          },
                                          {
                                              name: 'clientName',
                                              label: 'Client'
                                          }
                                      ]}
                                      data={purchaseOrders}
                                      linkKey="id"
                                      baseLink="/purchase-orders"
                                      orderBy="id"
                                      direction="desc"
                                  />
                              </Grid>
                          </Grid>
                      </Grid>
                  </Grid>
                  <Divider className={classes.greyDivider} />
              </React.Fragment>
          ) : (null)}
      {currentInstruction.tenantSignature && currentInstruction.tenantSignature !== 'n/a' ? (<Grid
        container
        spacing={3}
        direction="row"
        className={classes.contactDetailsItems}
      >
        <Grid item xs={12} container>
          <Grid item xs container spacing={3}>
            <Grid container item xs={12} sm={12} md={3} direction="column">
              <Box>
                <Typography variant="h5">Tenant's Signature</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
            <img
                className={classes.image} alt="Signature"
                src={`data:image/jpeg;base64,${currentInstruction.tenantSignature}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>):(null)}
      
    </div>
  )
}
export default Instructions
