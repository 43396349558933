import React, { useState, useEffect } from 'react'
import { Grid, Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropertyService from '../../services/PropertyService'
import ResultsTable from '../ui/ResultsTable/ResultsTable';
import Moment from 'moment'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  itemTitle: {
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500'
  },
  contactDetailsItems: {
    paddingTop: '12px'
  },
  divider: {
    backgroundColor: '#E6ADA5',
    marginBottom: '20px'
  },
  paddingBottomContainer: {
    paddingBottom: '35px'
  }
}))

const PropertyDetailsTab = ({ propertyDetails, propertyId }) => {
  const classes = useStyles()
  const [serviceRecords, setServiceRecords] = useState([])

  const getServiceRecords = (propertyId) => {
    PropertyService.getServiceRecords(propertyId)
      .then((response) => {
        setServiceRecords(response.value.collection.map((i) => {
          return {
            serviceType: i.serviceType,
            dateofLastService: i.dateofLastService
              ? Moment(i.dateofLastService).format('DD-MM-YYYY')
              : null,
            specifiedServiceDate: i.specifiedServiceDate
              ? Moment(i.specifiedServiceDate).format('DD-MM-YYYY')
              : null,
            certificateExpiryDate: i.certificateExpiryDate
              ? Moment(i.certificateExpiryDate).format('DD-MM-YYYY')
              : null,
          }
        }))
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getServiceRecords(propertyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId])

  return (
    <div>
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} container>
          <Grid item xs container spacing={3}>
            <Grid item sm={12} md={3} xs={12} container direction="column">
              <Box>
                <Typography variant="h5">Contact Details</Typography>
              </Box>
            </Grid>
            <Grid item sm={12} md={9} xs={12}>
              <Grid
                item
                xs
                container
                direction="row"
                className={classes.contactDetailsItems}
              >
                <Grid item xs={12} container direction="row" >
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Occupier Full Name
                    </Typography>
                      <Typography variant="body1">
                        {propertyDetails.occupierFullName
                          ? propertyDetails.occupierFullName
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Home Telephone
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.homeTelephone
                          ? propertyDetails.homeTelephone
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Alternative Telephone
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.alternativeTelephone
                          ? propertyDetails.alternativeTelephone
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Mobile Telephone
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.mobileTelephone
                          ? propertyDetails.mobileTelephone
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row" className={classes.paddingBottomContainer}>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Email Address
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.emailAddress
                          ? propertyDetails.emailAddress
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Preferred LGSR Delivery Method
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.preferredLGSRDeliveryMethod
                          ? propertyDetails.preferredLGSRDeliveryMethod
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Is Occupier Vulnerable?
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.isOccupierVulnerable}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} container>
          <Grid item xs container spacing={3}>
            <Grid item sm={12} md={3} xs={12} container direction="column">
              <Box>
                <Typography variant="h5">Property</Typography>
              </Box>
            </Grid>
            <Grid item sm={12} md={9} xs={12}>
              <Grid
                item
                xs
                container
                direction="row"
                className={classes.contactDetailsItems}
              >
                <Grid item xs={12} container direction="row">
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        UPRN
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.uprn ? propertyDetails.uprn : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={8} md={8} xs={12} container direction="column">
                    <Box>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Client
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.client ? propertyDetails.client : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Address 1
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.addressLine1
                          ? propertyDetails.addressLine1
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Address 2
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.addressLine2
                          ? propertyDetails.addressLine2
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Address 3
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.addressLine3
                          ? propertyDetails.addressLine3
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        City
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.city ? propertyDetails.city : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        County
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.county ? propertyDetails.county : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Postcode
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.postCode
                          ? propertyDetails.postCode
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Primary Heat Source
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.primaryHeatSource
                          ? propertyDetails.primaryHeatSource
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Build Year
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.buildYear
                          ? propertyDetails.buildYear
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Client Service Job No
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.clientServiceJobNumber
                          ? propertyDetails.clientServiceJobNumber
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Void Property Capped
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.voidPropertyCapped
                          ? propertyDetails.voidPropertyCapped
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Property Type
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.propertyType
                          ? propertyDetails.propertyType
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Boiler Warranty Expiration Date
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.boilerWarrantyExpirationDate
                          ? propertyDetails.boilerWarrantyExpirationDate
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row" className={classes.paddingBottomContainer}>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Meter Location
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.meterLocation
                          ? propertyDetails.meterLocation
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Meter Payment Type
                      </Typography>
                      <Typography variant="body1">
                        {propertyDetails.meterPaymentType
                          ? propertyDetails.meterPaymentType
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {serviceRecords && serviceRecords.length ? (
        <React.Fragment>
          <Divider className={classes.divider} />
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} container>
              <Grid item xs container spacing={3}>
                <Grid item sm={12} md={3} xs={12} container direction="column">
                  <Box>
                    <Typography variant="h5">Service Records</Typography>
                  </Box>
                </Grid>
                <Grid item sm={12} md={9} xs={12}>
                  <ResultsTable
                    columns={[
                      {
                        name: 'serviceType',
                        label: 'Service Type'
                      },
                      {
                        name: 'dateofLastService',
                        label: 'Date of Last Service'
                      },
                      {
                        name: 'specifiedServiceDate',
                        label: 'Specified Service Date'
                      },
                      {
                        name: 'certificateExpiryDate',
                        label: 'Certificate Expiry Date'
                      }
                    ]}
                    data={serviceRecords}
                    orderBy="serviceType"
                    direction="desc"
                  ></ResultsTable>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>) : null}
    </div>
  )
}

export default PropertyDetailsTab
