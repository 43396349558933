import React, { useState } from 'react'
import { Button, ImageList, ImageListItem, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import GetAppIcon from '@material-ui/icons/GetApp'
import ImgsViewer from 'react-images-viewer'

const useStyles = makeStyles((theme) => ({
    downloadImgBtn: {
        textTransform: 'none',
        float: 'right',
        marginTop: '20px'
    },
    image: {
        height: '165px'
    },
    singleDownloadImgBtn: {
        minWidth: 'auto',
        marginRight: theme.spacing(1),
        float: 'right',
        '&:hover': {
            background: 'transparent'
        }
    }
}))

const ImageGallery = (props) => {
    const classes = useStyles()
    const [viewerState, setViewerState] = useState({
        isOpen: false,
        currentImage: 0
    })

    const handleOpenViewer = (imageIndex) => {
        setViewerState({
            isOpen: true,
            currentImage: imageIndex
        })
    }

    const handleGoToPrevious = () => {
        const currentImage = viewerState.currentImage - 1
        setViewerState({
            isOpen: true,
            currentImage: currentImage >= 0 ? currentImage : 0
        })
    }

    const handleGoToNext = () => {
        const currentImage = viewerState.currentImage + 1
        setViewerState({
            isOpen: true,
            currentImage:
                currentImage < props.items.length
                    ? currentImage
                    : props.items.length - 1
        })
    }

    const handleCloseViewer = () => {
        setViewerState({
            isOpen: false,
            currentImage: 1
        })
    }

    const handleOnDownload = (image) => {
        var a = document.createElement('a')
        a.href = `data:${image.mimeType};base64,${image.documentBody}`
        a.download = `${image.subject}.jpg`
        a.click()
    }

    const handleOnDownloadAll = (event) => {
        props.onDownloadAll(event)
    }

    return (
        <div>
            <ImageList
                rowHeight={250}
                gap={10}
                cols={3}
                className={classes.gridList}
            >
                {props.items?.map((item, index) => (
                    <ImageListItem key={index}>
                        <div>
                            <img
                                className={classes.image}
                                src={`data:${item.mimeType};base64,${item.documentBody}`}
                                alt={item.pictureText}
                                onClick={() => handleOpenViewer(index)}
                            />
                            <div>
                                {item.date}
                                <label
                                    htmlFor="icon-button-file"
                                    className={classes.singleDownloadImgBtn}
                                >
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        aria-label="download image"
                                        component="span"
                                        onClick={() => handleOnDownload(item)}
                                    >
                                        <GetAppIcon />
                                    </IconButton>
                                </label>
                            </div>
                            <span>
                                {item.subject} - {item.pictureText}
                            </span>
                        </div>
                    </ImageListItem>
                ))}
            </ImageList>
            <Button
                variant="contained"
                className={classes.downloadImgBtn}
                size="large"
                color="primary"
                onClick={handleOnDownloadAll}
                startIcon={<GetAppIcon />}
            >
                Download All Photos
            </Button>
            <ImgsViewer
                imgs={props.items.map((i) => {
                    return {
                        src: `data:${i.mimeType};base64,${i.documentBody}`
                    }
                })}
                backdropCloseable={true}
                currImg={viewerState.currentImage}
                isOpen={viewerState.isOpen}
                onClickPrev={handleGoToPrevious}
                onClickNext={handleGoToNext}
                onClose={handleCloseViewer}
            />
        </div>
    )
}

export default ImageGallery
