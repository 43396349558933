import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { Button, Box, Typography, Dialog } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    width: 'fit-content!important',
    padding: '10px 30px'
  },
  label: {
    marginBottom: '10px',
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500',
    fontSize: '12px'
  },
  modalTitle: {
    backgroundColor: '#F5F2F5',
    display: 'grid'
  },
  contentBox: {
    padding: '30px 50px 10px 50px'
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  modalContainer: {
    borderRadius: '16px!important',
    zIndex: '1400!important'
  },
  titleIcon: {
    margin: 'auto',
    paddingTop: '20px'
  },
  titleContent: {
    margin: 'auto',
    paddingBottom: '20px',
    paddingTop: '10px'
  }
}))

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

const BasicModal = (props) => {
  const classes = useStyles()

  const {
    open,
    headerIcon,
    headerTitle,
    submitButtonText,
    cancelButtonText,
    dialogContent,
    onSubmit,
    onClose
    } = props

  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.modalContainer}
      >
        <Box className={classes.modalTitle}>
          {headerIcon && (
            <img
              src={headerIcon}
              alt="check icon"
              className={classes.titleIcon}
            />
          )}
          <Typography className={classes.titleContent}>
            {headerTitle}
          </Typography>
        </Box>
        <DialogContent>{dialogContent()}</DialogContent>
              <DialogActions className={classes.actionContainer}>
                  {cancelButtonText &&
                      <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          className={classes.submit}
                          size="large"
                          onClick={onClose}
                      >
                          {cancelButtonText}
                      </Button>
                  }
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            size="large"
            onClick={onSubmit}
          >
            {submitButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BasicModal
