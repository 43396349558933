import React, {useState, useContext} from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Typography,
  Drawer,
  Divider,
  AppBar,
  List,
  CssBaseline,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import authentication from '../../../auth/AuthenticationProvider'
import TuneIcon from '@material-ui/icons/Tune'
import ContractFilterMenu from "../../ContractFilterMenu/ContractFilterMenu"
import ClientContext from "../../../utils/globalClientContext"

const drawerWidth = 250
const UserGuideURL=process.env.REACT_APP_LIBERTY_PORTAL_USER_GUIDE;
const useStyles = makeStyles((theme) => ({
      root: {
        display: 'flex'
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 0,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        width: `calc(100% - ${theme.spacing(7) + 1}px)`,
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${theme.spacing(9) + 1}px)`
        },
        shadow: 'none',
        backgroundColor: 'white',
        height: theme.spacing(10),
        boxShadow: 'none',
        borderBottom: '1px solid #DEDEDE',
        borderTopLeftRadius: '25px'
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: 'none',
        borderBottom: '1px solid #DEDEDE'
      },
      menuButton: {
          marginRight: 36,
          zIndex: '3000',
      },
      hide: {
        display: 'none'
      },
    drawer: {
        zIndex: 99,
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
      },
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }),
        backgroundColor: '#E5E5E5',
        borderRight: 'none',
        overflow: 'hidden'
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1
        },
        backgroundColor: '#E5E5E5',
        borderRight: 'none'
      },
      drawerChevron: {
        '& span': {
          'flex-direction':'column'
          },
          zIndex: 999,
        width: '36px',
        height: '100%;',
        marginLeft: theme.spacing(-4),
        marginTop: theme.spacing(1.5),
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      sidebarArrow: {
          position: 'fixed',
        bottom: '0'
      },
      toolbar: {
        padding: '15px',
        height: '79px',
        color: '#595C5C',
      },
      list: {
        paddingTop: '45px'
      },
      title: {
        flexGrow: '1',
        marginLeft: theme.spacing(3),
        fontWeight: '300'
      },
      subNavigationItem: {
        marginRight: theme.spacing(2),
        fontSize: '14px',
        fontWeight: '400'
      },
      active: {
        color: '#E4006D'
      },
      filter: {
        textTransform: 'capitalize',
        color: '#E4006D',
        padding: '0px',
          width: 'auto',
        marginRight: '50px',
      },
}))

const Sidebar = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(true)
    
    const { showGlobalClientList, globalUserName, globalUserRole } = useContext(ClientContext);

  const handleCollapse = () => {
    setOpen(false)
  }

  const handleExpand = () => {
    setOpen(true)
  }
  const goToUserGuide = () => {
        window.open(UserGuideURL, '_blank');
    }

    const [showContractFilterMenu, setShowContractFilterMenu] = useState(false);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.drawerChevron}
            onClick={open ? handleCollapse : handleExpand}
          >
            <img
              src={
                open ? '/images/chevron-left.svg' : '/images/chevron-right.svg'
              }
              alt="Arrow pointer"
            />
          </IconButton>
          <Typography variant="h5" className={classes.title}>
                      PORTAL
                  </Typography>
                  {showGlobalClientList &&
                      <Button
                          onClick={() => {
                              setShowContractFilterMenu(!showContractFilterMenu);
                          }}
                          size="large"
                          color="primary"
                          className={classes.filter}
                          startIcon={<TuneIcon />}
                      >
                          Client Contract
                      </Button>}
          <Typography className={classes.subNavigationItem} variant="h6">
            Hi, {globalUserName}
          </Typography>
          <Button
            className={classes.subNavigationItem}
            variant="outlined"
            color="primary"
            onClick={authentication.signOut}
          >
                      LOG OFF
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <Box component={NavLink} to="/">
            <img
              src={open ? '/images/logo-with-liberty.svg' : '/images/logo.svg'}
              alt="Logo"
            />
          </Box>
        </div>
        <Divider />
        <List className={classes.list}>
          <ListItem
            button
            key="Properties"
            component={NavLink}
            to="/properties"
            activeClassName={classes.active}
            exact
          >
            <ListItemIcon>
              <img src="/icons/properties-icon.svg" alt="Arrow pointer" />
            </ListItemIcon>
            <ListItemText primary="Properties" />
          </ListItem>
                <ListItem
                    button
                    key="Jobs"
                    component={NavLink}
                    to="/jobs"
                    activeClassName={classes.active}
                >
                    <ListItemIcon>
                        <img src="/icons/job-icon.svg" alt="Arrow pointer" />
                    </ListItemIcon>
                    <ListItemText primary="Jobs" />
                </ListItem>
            {(globalUserRole === 'Admin' || globalUserRole === 'Supplier') && (
            <ListItem
                button
                key="Purchase Orders"
                component={NavLink}
                to="/purchase-orders"
                activeClassName={classes.active}
            >
                <ListItemIcon>
                    <img src="/icons/purchase-orders-icon.svg" alt="Arrow pointer" />
                </ListItemIcon>
                <ListItemText primary="Purchase Orders" />
                </ListItem>
                  )}
            
                <ListItem
                    button
                    key="Client Contracts"
                    component={NavLink}
                    to="/client-contracts"
                    activeClassName={classes.active}
                >
                    <ListItemIcon>
                        <img src="/icons/contracts-icon.svg" alt="Arrow pointer" />
                    </ListItemIcon>
                    <ListItemText primary="Client Contracts" />
                </ListItem>
                  
                <ListItem
                    button
                    key="Reports"
                    component={NavLink}
                    to="/reports"
                    activeClassName={classes.active}
                >
                    <ListItemIcon>
                        <img src="/icons/reports-icon.svg" alt="Arrow pointer" />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                </ListItem>
            
          {globalUserRole === 'Admin' && (
            <ListItem
              button
              key="Admin"
              component={NavLink}
              to="/admin"
              activeClassName={classes.active}
            >
              <ListItemIcon>
                <img src="/icons/Admin-icon.svg" alt="Arrow pointer" />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </ListItem>
          )}
          
          <img
            className={classes.sidebarArrow}
            src="/images/sidebar-arrow.svg"
            alt="Arrow"
          />
        </List>
          </Drawer>

          <ContractFilterMenu
              onShowFilterMenu={() => {
                  setShowContractFilterMenu(!showContractFilterMenu);
              }}
              showFilterMenu={showContractFilterMenu}
          />

    </React.Fragment>
  )
}

export default Sidebar
