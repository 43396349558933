import http from '../utils/http'

const getAll = (query) => {
  const params = new URLSearchParams()
  const page = query.hasOwnProperty('page') ? query.page : 1
  const size = query.hasOwnProperty('size') ? query.size : 100
  params.append('PageNumber', page)
  params.append('PageSize', size)

  if (query.hasOwnProperty('propertyAddress') && query.propertyAddress) {
    params.append('PropertyAddress', query.propertyAddress)
  }

  if (query.hasOwnProperty('clientId') && query.clientId) {
    params.append('ClientId', query.clientId)
  }

  return http.get(`/Property`, { params })
}

const getById = (id) => http.get(`/Property/${id}`)

const getByUprn = (clientId, uprn) => http.get(`/Property/${clientId}/${uprn}`)

const getJobHistory = (id, clientId) => http.get(`/Property/${id}/${clientId}/jobs`)

const getAppliances = (clientId,uprn) => http.get(`/Property/${uprn}/${clientId}/appliances`)

const getPurchaseOrders = (id) => http.get(`/Property/${id}/purchase-orders`)

const getServiceRecords = (clientId, uprn) => http.get(`/Property/${uprn}/${clientId}/service-records`)

const getAcceptsNewJobs = (id) => http.get(`/Property/${id}/accepts-new-jobs`)

export default {
  getAll,
  getById,
  getByUprn,
  getJobHistory,
  getAppliances,
  getPurchaseOrders,
    getServiceRecords,
  getAcceptsNewJobs
}
