import React, { useState, useEffect } from 'react'
import { Grid, InputBase, Button, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  submit: {
    margin: 'auto 0',
    height: '52px',
    width: '100%!important',
    textTransform: 'none'
    },
    clear: {
        margin: '5px 0 0 0',
        height: '52px',
        width: '100%!important',
        textTransform: 'none'
    },
  input: {
      borderRadius: '30px',
    padding:'12px'
  },
  searchPanel: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
    width: '100%',
    marginBottom: '20px'
  },
  searchBoxBorder: {
    flexGrow: '1',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    borderRadius: '10px',
      padding: '10px 20px',
      marginBottom: '10px',
      marginRight: '10px',
    maxWidth: '32%',
    '&:hover': {
      boxShadow: '0 0 10px rgba(0,0,0,0.2)'
    }
  },
  searchBoxOuterBorder: {
    flexGrow: '1',
    padding: '10px',
  },
    customSearchInput: {
        width: '100%',
        marginRight: '10px',
        '& input': {
            padding: '10px'
        }
  },
  select: {
      width: '100%',
      '& .MuiSelect-select.MuiSelect-select': {
          padding: '12px'
      }
  },
    buttonBox: {
        display: 'flex',
        alignSelf: 'flex-start',
        width: '15%',
        marginTop: '5px',
        flexFlow: 'column'
    }
}))

const SearchPanel = (props) => {
  const classes = useStyles()

  const [filterValue, setFilterValue] = useState('-1')
    const [searchValue, setSearchValue] = useState('')
    const [searchJobValue, setSearchJobValue] = useState('')
    const [searchAccuStatusValue, setSearchAccuStatusValue] = useState('-1')
    const [filterItems, setFilterItems] = useState([])
    const [filterSecondValue, setFilterSecondValue] = useState('-1')
    const [filterSecondItems, setFilterSecondItems] = useState([])
    const [accuStatusItems, setAccuStatusItems] = useState([])

  const handleChangeFilter = (event) => {
    setFilterValue(event.target.value)
    }

    const handleChangeTypeFilter = (event) => {
        setFilterSecondValue(event.target.value)
    }

  const handleChangeSearch = (event) => {
    setSearchValue(event.target.value)
    }

    const handleChangeJobSearch = (event) => {
        setSearchJobValue(event.target.value)
    }

    const handleChangeAccuStatusValue = (event) => {
        setSearchAccuStatusValue(event.target.value)
    }

    const handleOnSearch = () => {
        props.onSearch(searchValue, searchJobValue, filterValue, filterSecondValue, searchAccuStatusValue)
    }

    const handleClear = () => {
        setFilterValue('-1');
        setSearchValue('');
        setSearchJobValue('');
        setSearchAccuStatusValue('-1');
        setFilterSecondValue('-1')
        //props.onSearch()
    }

  useEffect(() => {
      setSearchValue(props.searchValue)
    setSearchJobValue(props.searchJobValue)
    setFilterValue(props.filterValue)
      setFilterItems(props.filterItems)
      if (props.filterSecondItems) {
          setFilterSecondItems(props.filterSecondItems);
          setFilterSecondValue(props.filterSecondValue);
      }
      if (props.accuStatusItems) {
          setAccuStatusItems(props.accuStatusItems)
          setSearchAccuStatusValue(props.accuStatusValue)
      }
  }, [props.filterItems, props.filterValue, props.searchValue, props.searchJobValue, props.filterSecondItems, props.filterSecondValue, props.accuStatusItems, props.accuStatusValue])

  return (
    <div>
      <div className={classes.searchPanel}>
        <div className={classes.searchBoxOuterBorder}>
          <form>
                      <Grid container direction="row" spacing={1} >
                          <Grid item xs={4} container direction="column" className={classes.searchBoxBorder}>
                <InputBase
                  className={classes.customSearchInput}
                  value={searchValue}
                  inputProps={{ 'aria-label': 'naked' }}
                  onChange={handleChangeSearch}
                  placeholder={props.searchValuePlaceholder}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleOnSearch()
                    }
                  }}
                />
                          </Grid>
                          <Grid item xs={4} container direction="column" className={classes.searchBoxBorder}>
                              <InputBase
                                  className={classes.customSearchInput}
                                  value={searchJobValue}
                                  inputProps={{ 'aria-label': 'naked' }}
                                  onChange={handleChangeJobSearch}
                                  placeholder={props.searchJobValuePlaceholder}
                                  onKeyPress={(event) => {
                                      if (event.key === 'Enter') {
                                          handleOnSearch()
                                      }
                                  }}
                              />
                          </Grid>
                          <Grid item xs={4} container direction="column" className={classes.searchBoxBorder}>
                <Select
                  width={1}
                  id="simple-select"
                  disableUnderline
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }}
                  value={filterValue}
                  onChange={handleChangeFilter}
                  className={classes.select}
                >
                  <MenuItem key={0} value="-1">
                    All Clients
                  </MenuItem>
                  {filterItems?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                </Grid>
                
                      </Grid>
                      <Grid container direction="row" spacing={1} >
                          {filterSecondItems && filterSecondItems.length > 0 &&
                              <Grid item xs={4} container direction="column" className={classes.searchBoxBorder }>
                                  <Select
                                      width={1}
                                      id="simple-select2"
                                      disableUnderline
                                      displayEmpty
                                      MenuProps={{
                                          anchorOrigin: {
                                              vertical: 'bottom',
                                              horizontal: 'left'
                                          },
                                          transformOrigin: {
                                              vertical: 'top',
                                              horizontal: 'left'
                                          },
                                          getContentAnchorEl: null
                                      }}
                                      value={filterSecondValue}
                                      onChange={handleChangeTypeFilter}
                                      className={classes.select}
                                  >
                                      <MenuItem key={0} value="-1">
                                          Any PO Status
                                      </MenuItem>
                                      {filterSecondItems?.map((item) => (
                                          <MenuItem key={item.value} value={item.value}>
                                              {item.label}
                                          </MenuItem>
                                      ))}
                                  </Select>
                              </Grid>}
                          {accuStatusItems && accuStatusItems.length > 0 &&
                              <Grid item xs={4} container direction="column" className={classes.searchBoxBorder}>
                                  <Select
                                      width={1}
                                      id="simple-select3"
                                      disableUnderline
                                      displayEmpty
                                      MenuProps={{
                                          anchorOrigin: {
                                              vertical: 'bottom',
                                              horizontal: 'left'
                                          },
                                          transformOrigin: {
                                              vertical: 'top',
                                              horizontal: 'left'
                                          },
                                          getContentAnchorEl: null
                                      }}
                                      value={searchAccuStatusValue}
                                      onChange={handleChangeAccuStatusValue}
                                      className={classes.select}
                                  >
                                      <MenuItem key={0} value="-1">
                                          Any Job Status
                                      </MenuItem>
                                      {accuStatusItems?.map((item) => (
                                          <MenuItem key={item.value} value={item.value}>
                                              {item.label}
                                          </MenuItem>
                                      ))}
                                  </Select>
                              </Grid>}
                      </Grid>
          </form>
              </div>
              <div className={classes.buttonBox}>
<Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          size="large"
          onClick={handleOnSearch}
        >
          Search
                  </Button>
                  <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      className={classes.clear}
                      size="large"
                      onClick={handleClear}
                  >
                      Clear
                  </Button>
              </div>
        
      </div>
    </div>
  )
}

export default SearchPanel
