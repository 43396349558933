import UserService from '../services/UserService'

export const validateEmail = (email) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
export const validateUserExist = async (email) => (!(await UserService.checkUserExist({ email }))?.isUserExist)
export const validateCompanyName = (companyName) => (companyName && (/^[a-z0-9 ]+$/i.test(companyName.trim())))
export const validateFirstName = (firstName) => (firstName && (/^[a-zA-Z0-9-\.' ]+$/i.test(firstName)))
export const validateSurname = (surname) => (surname && (/^[a-zA-Z0-9-\.' ]+$/i.test(surname)))

export const validateEmailText = 'Enter valid email!'
export const validateUserExistText = 'User already exists!'
export const validateCompanyNameText = 'Enter valid company name!'
export const validateFirstNameText = 'Enter valid first name!'
export const validateSurnameText = 'Enter valid surname!'