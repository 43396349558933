import React, { useEffect, useState, useRef } from 'react'
import ModalContainer from "../ModalContainer/ModalContainer"
import { makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Button,
    Divider,
    Grid,
    Typography
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'none',
        width: 'fit-content!important',
        padding: '10px 30px'
    },
    label: {
        marginBottom: '10px',
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
        fontSize: '12px'
    },
    textarea: {

    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
    },
    fieldContainer: {
        marginBottom: '20px',
    },
    titleBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        marginBottom: '20px'
    },
    title: {
        borderRight: '1px solid #BCBEBE',
        paddingRight: '10px',
        fontWeight: '600',
        marginBottom: '10px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '30px',
    },
    buttonContainer: {
        justifyContent: 'flex-end',
        width: '100%',
        textAlignLast: 'end'
    },
    subNavigationItem: {
        margin: '20px 0px 0px 20px',
        marginBottom: '60px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        minWidth: '100px'
    },
    spaceBetween: {
        justifyContent: 'space-between',
    }

}))


export default function LogOutModal(props) {

    const {
        open,
        onClose,
        onLogOut
    } = props

    const classes = useStyles();
    const [time, setTime] = useState(10);
    const decreaseTime = () => setTime((prev) => prev - 1);
    let intervalRef = useRef();

    useEffect(() => {
        if (open === true) {
            console.log('Window is open');
            intervalRef.current = setInterval(() => {
                decreaseTime();
            },1000);
            
        }
        else {
            setTime(10);
        }
    }, [open])

    useEffect(() => {
        if (time < 1) {
            console.log('Logging out...');
            onLogOut();
        }
    },[time,onLogOut])

    const handleCancel = () => {
        clearInterval(intervalRef.current);
        setTime(10);
        onClose();
    }

    return (
        <ModalContainer
            open={open}
            onClose={onClose}
            modalContent={() => (

                <Grid container>

                    <Grid container spacing={2}>
                        <div className={classes.titleBox}>
                            <Typography className={classes.subtitle} variant="h5">
                                Inactivity Warning
                            </Typography>
                        </div>
                        <Divider className={classes.divider} />
                        <Grid item xs={12} className={classes.fieldContainer}>
                            <Box>
                                <p>You have been inactive for 20 minutes. Do you want to remain logged in? If not, you will automatically be logged out in {time} seconds.</p>
                            </Box>
                        </Grid>

                        <Grid container className={classes.buttonContainer}>
                            <Box className={classes.buttonContainer}>
                                <Button
                                    className={classes.subNavigationItem}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    className={classes.subNavigationItem}
                                    variant="contained"
                                    color="primary"
                                    onClick={onLogOut}
                                >
                                    Log Out
                                </Button>

                            </Box>
                        </Grid>

                    </Grid>
                </Grid>
            )}
        />
    );
}
