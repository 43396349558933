
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Button,
    Grid,
    Divider,
    Link
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory, useParams } from 'react-router-dom'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ClientService from '../../services/ClientService'
import ClientContext from "../../utils/globalClientContext"

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '20px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#E4006D'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4006D'
            }
        },
    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500'
    },
    fieldContainer: {
        marginBottom: '25px',
    },
    title: {
        paddingRight: '10px',
        fontWeight: '600',
        marginBottom: '10px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '40px',
    },
    subDivider: {
        backgroundColor: '#DEDEDE',
        marginBottom: '30px',
    },

    iconButton: {
        padding: 0,
    },
    button: {
        width: 'auto',
        marginBottom: '30px',
    },
    subNavigationItem: {
        marginRight: '20px',
        marginBottom: '60px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        width: 'auto'
    },
    approverContainer: {
        position: 'relative',
    },
    changeApprover: {
        position: 'absolute',
        right: '50px',
        top: '52%',
    },
    purchaseOrderButton: {
        color: '#ffffff',
        textTransform: 'capitalize',
        fontSize: '16px',
        fontWeight: '500',
        width: 'auto !important',
        padding: '14px 40px',
        marginBottom: '50px',
        marginTop: '20px',
    }
}));

const ClientContractSummary = (props) => {

    const classes = useStyles();
    const history = useHistory()
    const params = useParams();
    const { setShowGlobalClientList, globalUserRole } = useContext(ClientContext);
    setShowGlobalClientList(false);

    const [contractID, setContractID] = useState(params.id)
    const [contract, setContract] = useState({
        clientContractCode: "",
        clientContractName: "",
        clientContractStatus: "",
        clientName: "",
        primaryPartsApproverName: "",
        secondaryPartsApproveName: "",
        companyDivisionName:""
    })
    const [loading, setLoading] = useState(true)

    const handleGoToSearch = () => {
        history.push({
            pathname: `/client-contracts`
        })
    }

    useEffect(() => {
        if (loading) {
            ClientService.getClientContracts({
                filterContract: contractID,
            })
                .then((response) => {
                    setContract(response.collection[0]);
                    
                })
                .catch((error) => console.warn(error))
                .finally(() => setLoading(false))

        }
        

    }, [contractID]);


    return (

        <div>

            <Button
                variant="contained"
                onClick={() => handleGoToSearch()}
                className={classes.button}
                startIcon={<ArrowBackIcon />}
            >
                Back to Search
            </Button>

            <Typography className={classes.title} variant="h5">
                {contract.clientName}
            </Typography>

            <Divider className={classes.divider} />

            <Grid container>

                <Grid item xs={12} sm={4}>
                    <Typography className={classes.subtitle} variant="h6">
                        Client Contract Info
                     </Typography>
                </Grid>

                {!loading &&

                    <Grid item xs={12} sm={8}>

                        <Grid container >
                            <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                                <Box>
                                    <Typography variant="body2" className={classes.itemTitle}>
                                        Contract
                                    </Typography>
                                    <Typography variant="body1">
                                        {contract.clientContractName}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                                <Box>
                                    <Typography variant="body2" className={classes.itemTitle}>
                                        Division
                                    </Typography>
                                    <Typography variant="body1">
                                        {contract.companyDivisionName}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                                <Box>
                                    <Typography variant="body2" className={classes.itemTitle}>
                                        Code
                                    </Typography>
                                    <Typography variant="body1">
                                        {contract.clientContractCode}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container >
                            <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                                <Box>
                                    <Typography variant="body2" className={classes.itemTitle}>
                                        Contract Status
                                    </Typography>
                                    <Typography variant="body1">
                                        {contract.clientContractStatus}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid container >
                            <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                                <Box>
                                    <Typography variant="body2" className={classes.itemTitle}>
                                        Purchase Order Approver 1
                                    </Typography>
                                    <Typography variant="body1">
                                        {contract.primaryPartsApproverName}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                                <Box>
                                    <Typography variant="body2" className={classes.itemTitle}>
                                        Purchase Order Approver 2
                                    </Typography>
                                    <Typography variant="body1">
                                        {contract.secondaryPartsApproverName}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {(globalUserRole !== 'User' &&                       
                            <Grid container >

                                <Button
                                    component={Link}
                                    underline="none"
                                    href={"/new-purchase-order?clientContractID=" + params.id}
                                    className={classes.purchaseOrderButton}
                                    startIcon={<DescriptionOutlinedIcon />}
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    target="_new"
                                >
                                    Create Purchase Order
                                </Button>
                            </Grid>
                        )}
                        

                    </Grid>
                }
                
                <Grid item xs={12}>
                    <Divider className={classes.subDivider} />
                </Grid>

            </Grid>

        </div>

    );
};

export default ClientContractSummary;