import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, TextField, CircularProgress, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    width: "fit-content!important",
    padding: "10px 30px",
  },
  label: {
    marginBottom: "10px",
    color: "rgba(168, 118, 163, 1)",
    fontWeight: "500",
    fontSize: "12px",
  },
  preloader: {
    display: "flex",
    justifyContent: "center",
    padding: "40px 0",
  },
}));

const PowerBiReportDetailsTab = ({ data, loading, onChange, onSave }) => {
  const classes = useStyles();

  return (
    <div>
      {loading ? (
        <div className={classes.preloader}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Box mt={3}>
          <form noValidate autoComplete="off">
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} container>
                <Grid item xs container spacing={3}>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    direction="column"
                  >
                    <Box mb={2}>
                      <label className={classes.label}>Name</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="name"
                        name="name"
                        placeholder="Enter Name"
                        value={data.name}
                        onChange={onChange}
                      />
                    </Box>
                    <Box mb={2}>
                      <label className={classes.label}>Description</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="description"
                        name="description"
                        placeholder="Enter Description"
                        value={data.description}
                        onChange={onChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={3} direction="row">
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    direction="column"
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      onClick={onSave}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </div>
  );
};
export default PowerBiReportDetailsTab;
