import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Typography,
    Divider
} from '@material-ui/core';


const ScheduleTextResult = withStyles((theme) => ({
    root: {
        marginTop: '20px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#E4006D'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4006D'
            }
        },
    },
    titleBoxTwo: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '60px',
        marginBottom: '20px'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '30px',
    },
}))(({ classes, ...props }) => {
    return (
        <div>
            <div className={classes.titleBoxTwo}>
                <Typography className={classes.title} variant="h5">Schedule Job</Typography>
            </div>

            <Divider className={classes.divider} />

            <Typography>{props.text}</Typography>
        </div>
    );
});


export default ScheduleTextResult