import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const GlobalCss = withStyles({
  '@global': {
    '.MuiButton-containedPrimary': {
      background: 'linear-gradient(267.74deg, #E4006D -7.37%, #F74A36 108.02%)',
      borderRadius: '8px',
      width: '25%!important'
    },
    '.MuiButton-outlinedPrimary': {
      border: '2px solid transparent!important',
      backgroundImage:
        'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #E4006D, #F74A36)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      boxShadow: '2px 1000px 1px #fff inset',
      borderRadius: '8px',
      color: '#E4006D!important',
      '&:hover': {
        border: '2px solid #E4006D!important',
        backgroundColor: 'rgba(63, 81, 181, 0.04)!important'
      }
    },
    '.MuiTypography-colorPrimary': {
      color: '#595C5C'
    },
    '.MuiOutlinedInput-root': {
      borderRadius: '8px'
    },
    '.MuiButton-contained': {
      boxShadow: 'none'
    },
    '.MuiTableRow-root': {
      textDecoration: 'none'
    },
    '.MuiButton-textSecondary': {
      color: '#F74A36'
    },
    '.rec-arrow': {
      display: 'none'
    },
    '.cukhLH': {
      margin: '0px!important'
    },
    '.rec-pagination': {
      display: 'none!important'
    },
    '.MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    },
    '.MuiInput-underline:after': {
      borderBottom: '2px solid #E4006D'
    },
    '.MuiDialog-paper': {
      borderRadius: '16px'
    },
    '.MuiButton-contained.Mui-disabled': {
      color: '#fff',
      cursor: 'not-allowed',
      pointerEvents: 'auto'
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E4006D'
        },
    '& .MuiPickersCalendarHeader-switchHeader .MuiButtonBase-root': {
      width: '10px'
        },
    '& .MuiPickersCalendarHeader-iconButton-root': {
        width: '10px'
    },
  }
})(() => null)

const GlobalCssOverride = () => {
  return (
    <React.Fragment>
      <GlobalCss />
    </React.Fragment>
  )
}

export default GlobalCssOverride
