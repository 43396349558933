import http from '../utils/http'

const getById = (id, clientId) => {
  return http.get(`/Instruction/${id}/${clientId}`)
}

const getFiles = (id) => {
  return http.get(`/Instruction/${id}/files`)
}

const getZipWithAllFiles = (id) => {
  return http.get(`/Instruction/${id}/files/download`, {
    responseType: 'blob'
  })
}

const getNotes = (id, clientId) => {
    return http.get(`/Instruction/${id}/${clientId}/notes`)
}

const getPlannedSors = (id, clientId) => {
    return http.get(`/Instruction/${id}/${clientId}/planned-sors`)
}

const getActualSors = (id, clientId) => {
    return http.get(`/Instruction/${id}/${clientId}/actual-sors`)
}

const getPartsRequired = (id, clientId) => {
    return http.get(`/Instruction/${id}/${clientId}/parts-required`)
}

const getPartsFitted = (id, clientId) => {
    return http.get(`/Instruction/${id}/${clientId}/parts-fitted`)
}

const getDefectsIdentified = (id, clientId) => {
    return http.get(`/Instruction/${id}/${clientId}/defects-identified`)
}

const getAppliancesSurvey = (id, clientId) => {
    return http.get(`/Instruction/${id}/${clientId}/appliances-survey`)
}

const getPurchaseOrders = (id) => http.get(`/Instruction/${id}/purchase-orders`)

const getPhotos = (id) => {
  return http.get(`/Instruction/${id}/photos`)
}

const getZipWithAllPhotos = (id) => {
  return http.get(`/Instruction/${id}/photos/download`, {
    responseType: 'blob'
  })
}

export default {
  getById,
  getFiles,
  getZipWithAllFiles,
  getNotes,
  getPlannedSors,
  getActualSors,
  getPhotos,
  getZipWithAllPhotos,
  getAppliancesSurvey,
  getDefectsIdentified,
  getPartsFitted,
  getPartsRequired,
  getPurchaseOrders
}
