import http from '../utils/http'

const getById = (id) => {
  return http.get(`/Appliances/${id}`)
}

const getTypes = () => {
  return http.get('/Appliances/appliance-types')
}

export default {
  getById,
  getTypes
}
