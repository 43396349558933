import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Button,
    Grid,
    Card,
    Checkbox,
    CardContent,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup
} from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune';
import FilterMenuContainer from "../../components/FilterMenuContainer/FilterMenuContainer";


const useStyles = makeStyles(() => ({
    arrowHistory: {
        marginLeft: '50px'
    },
    tableIcon: {
        verticalAlign: 'bottom'
    },
    filter: {
        textTransform: 'capitalize',
        color: '#E4006D',
        padding: '0px',
        width: 'auto',
        marginBottom: '60px'
    },
    checkbox: {
        '& .MuiSvgIcon-root': {
            width: '0.9em',
            height: '0.9em',
        }
    },
    groupLabel: {
        fontWeight: '500',
        fontSize: '12px',
        color: '#A876A3',
        marginBottom: '10px'
    },
    submit: {
        margin: '30px 0 0 0',
        textTransform: 'none',
        height: '100%',
        minWidth: '100%',
    },
    checkBoxLabel: {
        height: '33px',
        '& .MuiFormControlLabel-label': {
            fontWeight: '400',
            fontSize: '12px',
            color: '#595C5C',
        },
    },


}));



const FilterMenu = (props) => {
    const classes = useStyles();

    const { showFilterMenu, onShowFilterMenu, onChangeSelected, awaitingApproval,rejected,approved, onClearFilter } = props;

    const [state, setState] = React.useState({
        awaitingApproval: awaitingApproval,
        rejected: rejected,
        approved: approved,
        selectedApproval: -1
    });

    const handleChange = (event) => {
        onChangeSelected(event.target.value,);
        setState({ ...state, selectedApproval: event.target.value });
    };

    const handleClearFilter = () => {
        setState({ ...state, selectedApproval: -1 });
        onClearFilter();
    }
    

    return (

        <FilterMenuContainer
            showFilterMenu={showFilterMenu}
            onShowFilterMenu={onShowFilterMenu}
            filterMenuContent={() => (

                <FormControl component="fieldset">
                    <FormLabel className={classes.groupLabel} component="legend">PO Status</FormLabel>
                    
                    <RadioGroup name="radio-buttons-group" value={state.selectedApproval}
                        onChange={handleChange}>
                        
                        <FormControlLabel
                            className={classes.checkBoxLabel} control={<Radio className={classes.checkbox} onChange={handleChange} name="awaitingApproval" />}
                            label="Awaiting Approval" value="2" 
                        />
                        <FormControlLabel
                            className={classes.checkBoxLabel} control={<Radio className={classes.checkbox} onChange={handleChange} name="awaitingApproval" />}
                            label="Rejected" value="3"
                        />
                        <FormControlLabel
                            className={classes.checkBoxLabel} control={<Radio className={classes.checkbox} onChange={handleChange} name="awaitingApproval" />}
                            label="Approved" value="1" 
                        />

                    </RadioGroup>
                    <Button
                        onClick={handleClearFilter}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        size="large">Clear Filter</Button>
                </FormControl>

                )}
        >

        </FilterMenuContainer>

    );
};

export default FilterMenu;
