import React, { useState, useEffect } from 'react'
import ModalContainer from "../ModalContainer/ModalContainer"
import { makeStyles } from '@material-ui/core/styles'
import YesNoSwitch from 'components/ui/YesNoSwitch/YesNoSwitch'
import {
    Box,
    Button,
    Grid,
    FormControl,
    TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import PurchaseOrderService from "../../../services/PurchaseOrderService";

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'none',
        width: 'fit-content!important',
        padding: '10px 30px'
    },
    label: {
        marginBottom: '10px',
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
        fontSize: '12px'
    },
    textarea: {
        
    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
    },
    fieldContainer: {
        marginBottom: '20px',
    },
    titleBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        marginBottom: '20px'
    },
    title: {
        borderRight: '1px solid #BCBEBE',
        paddingRight: '10px',
        fontWeight: '600',
        marginBottom: '10px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '30px',
    },
    select: {
        marginRight: '25px',
        marginTop: '8px'
    },
    modalSelect: {
        marginTop: '8px'
    },
    input: {
        marginTop: '8px'
    },
    buttonContainer: {
        justifyContent: 'flex-end',
    },
    subNavigationItem: {
        margin: '20px 0px 0px 20px',
        marginBottom: '60px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        minWidth: '100px'
        },
    searchInput: {
        background: '#ffffff',
        boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.10)',
        border: 'none'
    },
    searchButton: {
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        height: '100%',
        minWidth: '100%'
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    YesNoSwitch: {
        textAlign: 'end',
    }

}))


export default function OrderDetailsModal(props) {

    const {
        open,
        onClose,
        onSave,
        suppliersList,
        clientContractID,
        canEdit,
        initialSupplier
    } = props

    const classes = useStyles();

    const handleSave = () => {
        if (canEdit) {
            onSave(selectedPart, selectedQty, selectedComments);
        }        
        onClose();
    }


    const [selectedSupplier, setSelectedSupplier] = useState(initialSupplier);
    const [selectedPart, setSelectedPart] = useState(null);
    const [selectedQty, setSelectedQty] = useState(null);
    const [selectedComments, setSelectedComments] = useState(null);
    const [partsList, setPartsList] = useState([]);

    const handleSupplierChange = (supplier) => {
        if (canEdit) {
            setSelectedSupplier(supplier);
            setPartsList([]);
            
            if (supplier && supplier.supplierID) {
                PurchaseOrderService.getParts(supplier.supplierID, clientContractID, false)
                    .then((response) => {
                        setPartsList(response.value.collection);
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            }
        }
        
    }

    useEffect(() => {
        handleSupplierChange(initialSupplier)
    }, [props,initialSupplier])

    
return (
    <ModalContainer
        open={open}
        onClose={onClose}
        modalContent={() => (

            <Grid container>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={9} className={classes.fieldContainer}>
                        
                            <Autocomplete
                                className={classes.modalSelect}
                                variant="outlined"
                            options={suppliersList}
                            defaultValue={selectedSupplier}
                                fullWidth
                                name="supplierSearch"
                                placeholder="Supplier"
                                renderInput={(params) => <TextField {...params} label="Supplier" />}
                                getOptionLabel={option => (option.supplierName!==null) ? option.supplierName : "Null name"}
                                onChange={(_event, newSupplier) => { handleSupplierChange(newSupplier); }}
                            />
                        
                    </Grid>

                    <Grid item xs={12} sm={3} className={classes.YesNoSwitch}>
                        <label className={classes.itemTitle}>Include Exclusions?</label>
                        <Box>
                            <YesNoSwitch />
                        </Box>

                    </Grid> 

                </Grid>
                <Grid item xs={12} sm={12} className={classes.fieldContainer}>
                    <Autocomplete
                        className={classes.modalSelect}
                        variant="outlined"
                        options={partsList}
                        fullWidth
                        disabled={selectedSupplier===null }
                        name="partsSearch"
                        placeholder="Part"
                        renderInput={(params) => <TextField {...params} label="Part" />}
                        getOptionLabel={option => (option.productName !== null) ? `${option.supplierPriceName} (${option.supplierPriceCode})` : "Null name"}
                        onChange={(_event, newPart) => { setSelectedPart(newPart); }}
                    />
                    

                </Grid>




                <Grid container spacing={2} className={classes.spaceBetween}>

                    <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Quantity</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    fullWidth
                                    name="quantity"
                                    value={selectedQty}
                                    onChange={(_event) => { setSelectedQty(_event.target.value) }}
                                />
                            </FormControl>
                        </Box>
                    </Grid>


                </Grid>


                <Grid container >
                    <Grid item xs={12} sm={12} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Comments</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <TextField
                                    className={classes.textarea}
                                    variant="outlined"
                                    multiline
                                    minrows={5}
                                    maxrows={20}
                                    fullWidth
                                    name="comments"
                                    placeholder="Comments..."
                                    value={selectedComments}
                                    onChange={(_event) => { setSelectedComments(_event.target.value) }}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>


                <Grid container className={classes.buttonContainer}>
                    <Box >
                        <Button
                            className={classes.subNavigationItem}
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        {canEdit &&
                            <Button
                                className={classes.subNavigationItem}
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                            >
                                Add
                            </Button>

                        }
                        
                    </Box>
                </Grid>

            </Grid>
        )}
    />
);
}
