import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import PowerBiService from "services/PowerBiService";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "600",
    marginBottom: "20px",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "16px!important",
  },
  cardActions: {
    padding: "0px",
  },
  cardBox: {
    width: "100%",
    marginBottom: "20px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    "&:hover": {
      boxShadow: "0 0 10px rgba(0,0,0,0.2)",
    },
  },
  contentCardBox: {
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    paddingLeft: "15px",
    color: "#F74A36",
    textDecoration: "none",
  },
}));

const Reports = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [powerBiReports, setPowerBiReports] = useState([]);

  useEffect(() => {
    getMyPowerBiReports();
  }, []);

  const getMyPowerBiReports = () => {
    setLoading(true);

    PowerBiService.getMyPowerBiReports()
      .then((response) => {
        const data = response.collection.sort((item) => item.reportName);
        setPowerBiReports(data);
      })
      .catch((error) => console.warn(error))
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <div>
        <Typography className={classes.title} variant="h5">
          Reports
        </Typography>
      </div>
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} container>
          {loading ? (
            <div className={classes.preloader}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <Card className={classes.cardBox}>
                <CardContent className={classes.cardContent}>
                  <Box className={classes.contentCardBox}>
                    <img src="/icons/file-icon.svg" alt="pdf icon" />{" "}
                    <Typography
                      variant="body1"
                      className={classes.fileName}
                      key="ReportsSearch"
                      component={NavLink}
                      to="/assert-reports"
                    >
                      Asset Report
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
              {powerBiReports.map((powerBiReport) => (
                <Card className={classes.cardBox}>
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.contentCardBox}>
                      <img src="/icons/file-icon.svg" alt="report icon" />{" "}
                      <Typography
                        variant="body1"
                        className={classes.fileName}
                        key={powerBiReport.powerBiReportID}
                        component={NavLink}
                        to={`/power-bi/${powerBiReport.groupID}/${powerBiReport.powerBiReportID}`}
                      >
                        {powerBiReport.reportName}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Reports;
