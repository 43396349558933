export const createSORItem = (sorCode, description, quantity, originalQuantity) => {
  return {
    sorCode,
    description,
      quantity,
    originalQuantity
  }
}

export const createJobInstructionItem = (
  id,
  appointmentDateTime,
  preferredTime,
  status,
  outcome,
    taskDescription,
    instructionNumber,
    pdaStatus,
    operativeAssigned,
  actionRequired
) => {
  return {
    id,
    appointmentDateTime,
    preferredTime,
    status,
    outcome,
      taskDescription,
      instructionNumber,
      pdaStatus,
      operativeAssigned,
    actionRequired
  }
}

export const createNoteItem = (date, noteText) => {
  return {
    date,
    noteText
  }
}

export const createFileItem = (name, documentId, date, fileName, documentBody) => {
  return {
    name,
    documentId,
      date,
      fileName,
    documentBody    
  }
}

export const createCommunicationItem = (
  communicationText,
  date,
  type,
  code
) => {
  return {
    communicationText,
    date,
    type,
    code
  }
}

export const createPhotoItem = (
  pictureId,
  documentBody,
  mimeType,
  pictureText,
  subject,
  date
) => {
  return {
    pictureId,
    documentBody,
    mimeType,
    pictureText,
    subject,
    date
  }
}

export const returnNA = (input) => {
    if (!input) {
        return "N/A"
    }

    if (input === '') {
        return "N/A"
    }

    return input;
}
