import React from 'react'
import { Grid, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  itemTitle: {
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500'
  },
  contactDetailsItems: {
    paddingTop: '12px'
  }
}))

const OccupierInformationTab = ({ occupierDetails }) => {
  const classes = useStyles()

  return (
    <div>
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} container>
          <Grid item xs container spacing={3}>
            <Grid item sm={12} md={3} xs={12} container direction="column">
              <Box>
                <Typography variant="h5">
                  Vulnerable Occupier Information
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={12} md={9} xs={12}>
              <Grid
                item
                xs
                container
                direction="row"
                className={classes.contactDetailsItems}
              >
                <Grid item xs={12} container direction="row">
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Oldest Person Living in Property
                      </Typography>
                      <Typography variant="body1">
                        {occupierDetails.oldestPersonLivingInProperty
                          ? occupierDetails.oldestPersonLivingInProperty
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Any Children Under 6 Years
                      </Typography>
                      <Typography variant="body1">
                        {occupierDetails.anyChildrenUnder6Years
                          ? occupierDetails.anyChildrenUnder6Years
                          : 'No'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Has Occupant a Disability
                      </Typography>
                      <Typography variant="body1">
                        {occupierDetails.hasOccupantDisability ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Is There Regular Outside Support
                      </Typography>
                      <Typography variant="body1">
                        {occupierDetails.isRegularOutsideSupport
                          ? occupierDetails.isRegularOutsideSupport
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Any Medical Condition that Requires Hot Water
                      </Typography>
                      <Typography variant="body1">
                        {occupierDetails.anyMedicalConditionHotWaterRequires
                          ? 'Yes'
                          : 'No'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Temporary Heaters Required
                      </Typography>
                      <Typography variant="body1">
                        {occupierDetails.temporaryHeatersRequired
                          ? occupierDetails.temporaryHeatersRequired
                          : 'Not Required'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">

                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Any Language Difficulties
                      </Typography>
                      <Typography variant="body1">
                        {occupierDetails.anyLanguageDifficulties}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Is Occupant Safety Risk
                      </Typography>
                      <Typography variant="body1">
                        {occupierDetails.isOccupantSafetyRisk
                          ? occupierDetails.isOccupantSafetyRisk
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Does the Property have Heat?
                      </Typography>
                      <Typography variant="body1">
                                              {occupierDetails.isPropertyHasHeat
                                                  ? occupierDetails.isPropertyHasHeat ? 'Yes' : 'No'
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Does the Property have Hot Water?
                      </Typography>
                      <Typography variant="body1">
                                              {occupierDetails.isPropertyHasHotWater
                                                  ? occupierDetails.isPropertyHasHotWater ? 'Yes' : 'No'
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Immersion Heater Present
                      </Typography>
                      <Typography variant="body1">
                                              {occupierDetails.immersionHeaterPresent
                                                  ? occupierDetails.immersionHeaterPresent ? 'Yes' : 'No'
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Gas Switched Off at Meter
                      </Typography>
                      <Typography variant="body1">
                                              {occupierDetails.gasSwitchedOffAtMeter
                                                  ? occupierDetails.gasSwitchedOffAtMeter ? 'Yes' : 'No'
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">
                  <Grid item sm={4} md={4} xs={12} container direction="column">
                    <Box className={classes.contactDetailsItems}>
                      <Typography variant="body2" className={classes.itemTitle}>
                        Print Size
                      </Typography>
                      <Typography variant="body1">
                        {occupierDetails.printSize
                          ? occupierDetails.printSize
                                                  : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default OccupierInformationTab
