import React, { useState, useEffect, useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Button, Typography, Tab, Tabs, Box, Grid } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PropTypes from 'prop-types'
import PropertyDetailsTab from '../../components/PropertyDetailsTab/PropertyDetailsTab'
import OccupierInformationTab from '../../components/OccupierInformationTab/OccupierInformationTab'
import JobHistoryTab from '../../components/JobHistoryTab/JobHistoryTab'
import ApplianceTab from '../../components/ApplianceTab/ApplianceTab'
import PurchaseOrderTab from '../../components/PurchaseOrderTab/PurchaseOrderTab'
import PropertyService from '../../services/PropertyService'
import { useHistory } from 'react-router-dom'
import Moment from 'moment'
import ClientContext from "../../utils/globalClientContext"
import CreateJobButton from '../../containers/Properties/CreateJobButton'

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #E6ADA5'
  },
  indicator: {
    backgroundColor: '#E4006D'
  }
})(Tabs)

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 700,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#E4006D',
      opacity: 1
    },
    '&$selected': {
      color: '#E4006D',
      fontWeight: 700
    },
    '&:focus': {
      color: '#E4006D'
    }
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />)

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  titleBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '20px',
    marginBottom: '20px'
  },
  title: {
    borderRight: '1px solid #BCBEBE',
    paddingRight: '10px'
  },
  subTitle: {
    paddingLeft: '10px',
    color: '#BCBEBE'
  },
  padding: {
    padding: theme.spacing(3)
  },
  tabs: {
    backgroundColor: theme.palette.background.paper
  },
  contactDetailsItems: {
    paddingTop: '12px',
    paddingBottom: '35px'
  },
  itemTitle: {
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500'
  },
  divider: {
    backgroundColor: '#E6ADA5',
    marginBottom: '20px'
    },
    button: {
        width: 'auto!important'
    },
    purchaseOrderButton: {
        width: 'auto!important'
    },
    alignRight: {
        textAlign: 'right'
    }
}))

const PropertySummary = (props) => {
  const classes = useStyles()
  const history = useHistory()
    const { setShowGlobalClientList, globalUserRole } = useContext(ClientContext);
    setShowGlobalClientList(false);

  const tabs = [
    { value: 0, label: 'property-details' },
    { value: 1, label: 'occupier-information' },
    { value: 2, label: 'job-history' },
      { value: 3, label: 'appliances' },
      { value: 4, label: 'purchase-orders' }
  ]

  const propertyId = props.match.params.id

  const routeParams = new URLSearchParams(props.location.search)
  const selectedTab =
    tabs.find((t) => t.label === routeParams.get('tab'))?.value || 0

  const initialState = {
    id: null,
    propertyInformation: {
      occupierFullName: '',
      homeTelephone: '',
      emailAddress: '',
      isOverdue: false,
      alternativeTelephone: '',
      preferredLGSRDeliveryMethod: '',
      excludedFromContract: '',
      mobileTelephone: '',
      isOccupierVulnerable: false,
      uprn: '',
      postCode: '',
      county: '',
      city: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      heatingState: null,
      primaryHeatSource: null,
      client: '',
      clientId: '',
      clientServiceJobNumber: '',
      voidPropertyCapped: null,
      propertyType: '',
      boilerWarrantyExpirationDate: null,
      buildYear: null,
      meterPaymentType: null,
      meterLocation: ''
    },
    occupierInformation: {
      oldestPersonLivingInProperty: '',
      isRegularOutsideSupport: '',
      temporaryHeatersRequired: false,
      customerCareCardLeft: false,
      isPropertyHasHeat: '',
      gasSwitchedOffAtMeter: false,
      anyChildrenUnder6Years: false,
      anyMedicalConditionHotWaterRequires: false,
      anyLanguageDifficulties: false,
      isOccupantSafetyRisk: false,
      isPropertyHasHotWater: false,
      isOccupierHasHeatAndHotWater: false,
      printSize: '',
      hasOccupantDisability: false,
      isContactDetailsCollected: false,
      preferredMethodOfContact: '',
      immersionHeaterPresent: false
    }
  }
  const [currentProperty, setCurrentProperty] = useState(initialState)
  const [currentTab, setCurrentTab] = React.useState(selectedTab)

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue)

    const params = new URLSearchParams()
    if (newValue) {
      const tab = tabs.find((t) => t.value === newValue)
      params.append('tab', tab.label)
    }

    history.push({
      pathname: `/property/${propertyId}`,
      search: `?${params}`
    })
  }

  const getProperty = (id) => {
    PropertyService.getById(id)
      .then((response) => {
          const result = response.object
        console.log(result);
        setCurrentProperty({
          id: result.propertyId,
            propertyInformation: {
            isOccupierVulnerable: result.occupierIsVulnerable,
            occupierFullName: result.tenant?.occupierFullName,
            uprn: result.uprn,
            addressLine1: result.address?.addressLine1,
            addressLine2: result.address?.addressLine2,
            addressLine3: '-',
            postCode: result.address?.postCode,
            heatingState: result.heatingState?.literalText,
            primaryHeatSource: result.primaryHeatSource?.literalText,
            client: result.client?.clientName,
            clientId: result.client?.clientId,
            clientServiceJobNumber: result.clientServiceJobNumber,
            voidPropertyCapped: result.voidPropertyCapped
              ? Moment(result.voidPropertyCapped).format('DD-MM-YYYY')
              : null,
            propertyType: result.propertyType?.literalText,
            boilerWarrantyExpirationDate: result.boilerServiceExpirationDate
              ? Moment(result.boilerServiceExpirationDate).format('DD-MM-YYYY')
              : null,
            buildYear: result.buildYear,
            meterLocation: result.meterLocation?.literalText,
            homeTelephone: prependZero(result.homeTelephone),
            mobileTelephone: prependZero(result.mobileTelephone),
            alternativeTelephone: prependZero(result.alternativeTelephone),
            county: result.address?.addressLine4,
            city: result.address?.addressLine3,
            excludedFromContract: result.excludedFromContract,
            emailAddress: result.emailAddress,
            preferredLGSRDeliveryMethod:
              result.preferredlgsrdeliverymethod?.literalText,
              meterPaymentType: result.meterPaymentType?.literalText,
              
          },
          occupierInformation: {
            oldestPersonLivingInProperty: result.tenant?.oldestPersonAtProperty,
            anyChildrenUnder6Years: result.tenant?.childrenUnder6Years,
            isRegularOutsideSupport: result.tenant?.regularOutsideSupport,
            anyMedicalConditionHotWaterRequires:
              result.tenant?.medicalConditionThatRequiresHotWater,
            isOccupierHasHeatAndHotWater:
              result.tenant?.doesThePropertyHaveHeat &&
              result.tenant?.doesThePropertyHaveHeat
                .doesThePropertyHaveHotWater,
            temporaryHeatersRequired: result.tenant?.temporaryHeatersRequired,
            anyLanguageDifficulties: result.tenant?.languageDifficulties,
            customerCareCardLeft: result.tenant?.customerCareCardLeft,
            isOccupantSafetyRisk: result.tenant?.isOccupantSafetyRisk,
            isPropertyHasHeat: result.tenant?.doesThePropertyHaveHeat,
            isPropertyHasHotWater: result.tenant?.doesThePropertyHaveHotWater,
            gasSwitchedOffAtMeter: result.tenant?.gasSwitchedOffAtMeter,
              printSize: result.tenant?.printSize?.literalText
          }
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const prependZero = (number) => {
    if (number && number.length > 0) {
      return number[0] === '0' ? number : `0${number}`
    } else {
      return number
    }
  }

  const handleGoToSearch = () => {
      history.push({
          pathname: `/properties`,
          //search: `?clientId=${currentProperty.propertyInformation.clientId}`
      })
  }

  useEffect(() => {
      getProperty(propertyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId])

  useEffect(() => { }, [currentTab])

  return (
      <div>
          <Grid container>
              <Grid item xs={12} md={8}>
                  <Button
                    variant="contained"
                    onClick={() => handleGoToSearch()}
                    className={classes.button}
                    startIcon={<ArrowBackIcon />}
                  >
                    Back to Search
                  </Button>
                  <div className={classes.titleBox}>
                    <Typography className={classes.title} variant="h4">
                      {currentProperty.propertyInformation?.addressLine1}
                    </Typography>
                    <Typography
                      className={classes.subTitle}
                      variant="subtitle1"
                      gutterBottom
                    >
                      {currentProperty.propertyInformation?.client}
                    </Typography>
                  </div>
              </Grid>
              
                  <Grid item xs={12} md={4} className={classes.alignRight}>
                  <CreateJobButton show={true} propertyId={propertyId} clientId={currentProperty.propertyInformation.clientId} />
                  </Grid>
              
            </Grid>
      <div>
        <div className={classes.tabs}>
          <AntTabs
            value={currentTab}
            onChange={handleChangeTab}
            aria-label="ant example"
          >
            <AntTab label="PROPERTY DETAILS" />
            <AntTab label="OCCUPIER INFORMATION" />
            <AntTab label="JOB HISTORY" />
            <AntTab label="APPLIANCES" />
            {(globalUserRole === 'Admin' || globalUserRole === 'Supplier') && (
                <AntTab label={"PURCHASE ORDERS"} />
            )}
            {/* <AntTab label="AUDIT" /> */}
          </AntTabs>
          <TabPanel value={currentTab} index={0}>
            <PropertyDetailsTab
              propertyId={propertyId}
              propertyDetails={currentProperty.propertyInformation}
            ></PropertyDetailsTab>
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <OccupierInformationTab
              occupierDetails={currentProperty.occupierInformation}
            ></OccupierInformationTab>
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
                      <JobHistoryTab propertyId={propertyId} clientId={currentProperty.propertyInformation.clientId }></JobHistoryTab>
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
                      <ApplianceTab propertyId={propertyId} clientId={currentProperty.propertyInformation.clientId} uprn={currentProperty.propertyInformation.uprn }></ApplianceTab>
                  </TabPanel>
                  {globalUserRole !== 'User' && (
                      <TabPanel value={currentTab} index={4}>
                          <PurchaseOrderTab propertyId={propertyId}></PurchaseOrderTab>
                      </TabPanel>

                  ) }
          {/* <TabPanel value={value} index={4}>
            <AuditTab></AuditTab>
          </TabPanel>  */}
        </div>
      </div>
    </div>
  )
}

export default PropertySummary
