import React from "react";

// set the defaults
const ClientContext = React.createContext({
    globalClient: "-1",
    globalClientList: [],
    showGlobalClientList: false,
    showPreloader: false,
    globalUserRole: 'User',
    globalUserName: 'User',
    setGlobalClient: () => { },
    setGlobalClientList: () => [],
    setShowGlobalClientList: () => { },
    setShowPreloader: () => { },
    setGlobalUserRole: () => { },
    setGlobalUserName: () => { }
});

export default ClientContext;
