import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275
  },
  paper: {
    margin: theme.spacing(8, 11),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    color: '#595C5C'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  link: {
    float: 'right'
  }
}));

const SignIn = () => {
  const classes = useStyles();

  return (
    <div>
      <div id="auth" className={classes.paper}>
        {/* <Box>
                    <img src={Logo} alt="Logo" />
                </Box>
                <Typography variant="h6">
                    Sign In
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2" className={classes.link}>
                                Forgot your password?
                            </Link>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        size="medium"
                    >
                        Sign In
                    </Button>
                </form> */}
      </div>
    </div>
  );
};

export default SignIn;
