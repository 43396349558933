import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import AuthenticationProvider from './auth/AuthenticationProvider';

AuthenticationProvider.authorize(() => {
  ReactDOM.render(

        <App />,
    document.getElementById('root')
  );
  serviceWorker.unregister();
});
//ReactDOM.render(app, document.getElementById("root"));
