import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Divider,
    Button,
    Box,
    Select,
    FormControl,
    MenuItem,
    TextField,
    Typography,
    Grid,
    CircularProgress
} from '@material-ui/core'
import SearchPanel from '../../components/ui/SearchPanel/SearchPanel'
import { AgGridReact } from 'ag-grid-react'
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-quartz.css"
import JobService from '../../services/JobService'
import ClientService from '../../services/ClientService'
import Moment from 'moment'
import { useHistory } from 'react-router-dom'
import ClientContext from "../../utils/globalClientContext"

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '20px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#E4006D'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4006D'
            }
        }
    },
    title: {
        fontWeight: '600',
        marginBottom: '10px'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'none'
    },
    divider: {
        backgroundColor: '#E6ADA5'
    },
    input: {
        borderRadius: '30px'
    },
    label: {
        marginBottom: '10px',
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500',
        fontSize: '12px'
    },
    preloader: {
        display: 'flex',
        justifyContent: 'center',
        padding: '40px 0'
    },
    boxMargin: {
        marginTop: '20px'
    }
}))

const JobsDataGrid = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const { setShowGlobalClientList } = useContext(ClientContext);
    const searchParams = new URLSearchParams(props.location.search)
    const searchAddress = searchParams.get('address') || ''
    const searchJobNo = searchParams.get('jobNo') || ''
    const searchClientId = searchParams.get('clientId') || ''
    const searchTypeId = searchParams.get('typeId') || ''
    const searchStatus = searchParams.get('status') || ''

    const [loading, setLoading] = useState(false)
    const [jobs, setJobs] = useState([{id:"1",clientId:"2342"}])
    const [filterTypes, setFilterTypes] = useState([])
    const [filterClients, setFilterClients] = useState([])
    const [statusTypes, setStatusTypes] = useState([])
    const [status, setStatus] = useState(searchStatus)
    const [address, setAddress] = useState(searchAddress)
    const [jobNo, setJobNo] = useState(searchJobNo)
    const [clientId, setClientId] = useState(searchClientId)
    const [typeId, setTypeId] = useState(searchTypeId)
    const [isSearched, setIsSearched] = useState(
        !(!searchAddress && !searchJobNo && !searchClientId && !searchTypeId && !searchStatus)
    )
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(1000)
    const [length, setLength] = useState(0)
    const [orderBy, setOrderBy] = useState('loggedBy')

    const dateCompare = (date1, date2) => {
        const date1No = date1 && Date.parse(date1);
        const date2No = date2 && Date.parse(date2);

        if (date1No == null && date2No == null) { return 0; }
        if (date1No == null || date2No == null) { return -1 }

        return date1No - date2No;
    }

    const [jobCols, setJobCols] = useState(
        [
            {
                field: 'id',
                headerName: 'Id',
                hide: true
            },
            {
                field: 'clientId',
                headerName: 'ClientId',
                hide: true
            },
            {
                field: 'uprn',
                headerName: 'Uprn',
                hide: true
            },
            {
                field: 'clientContract',
                headerName: 'ClientContract',
                hide: true
            },
            {
                field: 'dateraised',
                cellDataType: 'date',
                headerName: 'Date Raised',
                //sortable: true,
                //comparator: dateCompare,
                flex: 1,
                minWidth: 175
            },
            {
                field: 'address',
                headerName: 'Address',
                filter: true,
                flex: 2
            },
            {
                field: 'postcode',
                headerName: 'Postcode',
                filter: true,
                flex: 1,
                maxWidth: 120
            },
            {
                field: 'jobnumber',
                headerName: 'Job Number',
                flex: 1
            },
            {
                field: 'clientjobnumber',
                headerName: 'Client Ref',
                filter: true,
                flex: 1
            },
            {
                field: 'jobstatus',
                headerName: 'Job Status',
                flex: 1
            },
            {
                field: 'priority',
                headerName: 'Priority',
                flex: 1
            },
            {
                field: 'loggedBy',
                headerName: 'Logged By',
                filter: true,
                flex: 1
            },
            {
                field: 'description',
                headerName: 'Description',
                flex: 2
            },
            {
                field: 'isAppointable',
                headerName: 'Appointable',
                flex: 1,
                maxWidth: 100
            }
        ]);

        
    useEffect(() => {
        getClientNames();
        setFilterTypes([{ label: 'Parts Authorised/Needed', value: 99 }, { label: 'Parts Ordered', value: 8 }, { label: 'Parts In', value: 9 }]);
        setStatusTypes([
            { label: 'Appointed', value: 'Appointed' },
            { label: 'Unappointed', value: 'Unappointed' }
        ])
        setShowGlobalClientList(false);
    }, [])

    const handleChangeClientId = (event) => {
        setClientId(event.target.value)
    }

    const handleChangeTypeId = (event) => {
        setTypeId(event.target.value)
    }

    const handleChangeStatus = (event) => {
        setStatus(event.target.value)
    }

    const handleChangeJobAddress = (event) => {
        setAddress(event.target.value)
    }

    const handleChangeJobNo = (event) => {
        setJobNo(event.target.value)
    }

    const handleSearch = (address, jobNo, clientId, typeId, statusValue) => {
        console.log('handleSearch', address, jobNo, clientId, typeId, statusValue)
        setIsSearched(true)
        setFormVals(address, jobNo, clientId, typeId, statusValue);
        setSearchParams(address, jobNo, clientId, typeId, statusValue)
        setLoading(true)

        JobService.getAll({ address: address, jobNo: jobNo, clientId, size, page: page + 1, jobStatus: typeId, accuStatus: statusValue })
            .then((response) => {
                console.log('searchResult', response);
                if (response.value.collection !== null) {
                    const items = response.value.collection.map((item) => ({
                        id: item.jobId,
                        dateraised: item.dateRaised
                            ? new Date(item.dateRaised) //new Date(Moment(item.dateRaised).format('DD-MM-YYYY HH:mm'))
                            : null,
                        address: item.property?.address?.addressLine1,
                        postcode: item.property?.address?.postCode,
                        jobtype: item.jobType?.literalText,
                        jobnumber: item.jobNumber,
                        clientjobnumber: item.clientJobNumber,
                        jobstatus: item.jobStatus?.literalText,
                        loggedBy: item.loggedBy,
                        isAppointable: item.isAppointable,
                        priority: item.priority?.literalText,
                        clientContractId: item.clientContract,
                        clientId: item.property?.clientId,
                        uprn: item.property?.uprn,
                        description: item.jobDescription
                    }))
                    setLength(response.value.totalRecords)
                    setJobs(items)
                    setIsSearched(false);
                }

            })
            .catch((error) => console.warn(error))
            .finally(() => setLoading(false))
    }

    const handleClear = (searchValue, jobSearchValue, clientId, typeId, statusValue) => {
        setIsSearched(false)
        setFormVals(searchValue, jobSearchValue, clientId, typeId, statusValue);
    }

    const setFormVals = (searchValue, jobSearchValue, clientId, typeId, statusValue) => {
        console.log('setting form vals', jobSearchValue);
        setAddress(searchValue)
        setJobNo(jobSearchValue)
        setClientId(clientId)
        setTypeId(typeId)
        setStatus(statusValue)
        setSearchParams(searchValue, jobSearchValue, clientId, typeId, statusValue)
        setPage(0)
    }

    const setSearchParams = (searchValue, jobSearchValue, filterValue, filterSecondValue, statusValue) => {
        const params = new URLSearchParams()
        if (searchValue) {
            params.append('address', searchValue)
        }

        if (jobSearchValue) {
            params.append('jobNo', jobSearchValue)
        }

        if (filterValue) {
            params.append('clientId', filterValue)
        }

        if (filterSecondValue) {
            params.append('typeId', filterSecondValue)
        }

        if (statusValue) {
            params.append('status', statusValue)
        }

        console.log('params', params)

        history.push({
            pathname: '/jobs',
            search: params.values.length > 0 ? '' : `?` + params
        })
    }

    const getClientNames = () => {
        ClientService.getMyClients()
            .then((response) => {
                const items = response.collection
                    .sort((item) => item.clientName)
                    .map((item) => ({
                        value: item.clientId,
                        label: item.clientName
                    }))

                setFilterClients(items)
            })
            .catch((error) => console.log(error))
    }

    const goToJob = (row) => {
        let obj = row.data;
        history.push(`/job-detail/${obj.jobnumber}/${obj.uprn}/${obj.clientId}`)
    }


    return (
        <div>
            
                    <SearchPanel
                        searchValue={address}
                        jobSearchValue={jobNo}
                        filterValue={clientId ? clientId : '-1'}
                        filterItems={filterClients}
                        filterSecondItems={filterTypes}
                        filterSecondValue={typeId ? typeId : '-1'}
                onSearch={handleSearch}
                onClear={handleClear}
                        searchValuePlaceholder="Enter Address"
                        searchJobValuePlaceholder="Enter Job Number"
                        accuStatusItems={statusTypes}
                        accuStatusValue={status ? status : '-1'}
                    ></SearchPanel>
                    <div>
                        <Typography className={classes.title} variant="h5">
                            Search Results:
                        </Typography>
                    </div>
                    
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            {loading ? (
                                <div className={classes.preloader}>
                                    <CircularProgress color="secondary" />
                                </div>
                                ) : (
                                        <div
                                            className="ag-theme-quartz"
                                            style={{ height: 500 }}
                                        >
                                            <AgGridReact
                                                rowData={jobs}
                                                columnDefs={jobCols}
                                                pagination={true}
                                                paginationPageSize={100}
                                                paginationPageSizeSelector={[100, 200, 500, 1000]}
                                                onRowClicked={goToJob}
                                            />
                                        </div>
                            )}
                            
                        </Grid>
                    </Grid>
                
        </div>
    )
}

export default JobsDataGrid
