
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Button,
    Grid,
    Divider,
    FormControl,
    IconButton,
    Link,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addDays } from 'date-fns'
import ResultsTable from '../../components/ui/ResultsTable/ResultsTable'
import EditIcon from "../../components/ui/Icons/EditIcon"
import DeleteIcon from '../../components/ui/Icons/DeleteIcon';
import DeliveryDetailsModal from "../../components/ui/DeliveryDetailsModal/DeliveryDetailsModal"
import OrderDetailsModal from "../../components/ui/OrderDetailsModal/OrderDetailsModal"
import OrderDetailsEditModal from "../../components/ui/OrderDetailsModal/OrderDetailsEditModal"
import ApproverModal from "../../components/ui/ApproverModal/ApproverModal"
import { useHistory } from 'react-router-dom'
import PurchaseOrderService from "../../services/PurchaseOrderService"
import ClientService from "../../services/ClientService"
import ClientContext from "../../utils/globalClientContext"

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '20px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#E4006D'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E4006D'
            }
        },
    },
    button: {
        width: 'auto',
        marginBottom: '20px',
    },
    textarea: {
        height: '200px',
        paddingRight: '25px',
        marginTop: '8px',
    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500'
    },
    fieldContainer: {
        marginBottom: '25px',
    },
    titleBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        marginBottom: '20px'
    },
    title: {
        borderRight: '1px solid #BCBEBE',
        paddingRight: '10px',
        fontWeight: '600',
        marginBottom: '10px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '30px',
    },
    select: {
        marginRight: '25px',
        marginTop: '8px'
    },
    modalSelect: {
        marginTop: '8px'
    },
    input: {
        paddingRight: '25px',
        marginTop: '8px',
    },
    datePicker: {
        borderColor: '#E4006D',
        marginTop: '8px',
        marginRight: '25px',
        width: '100%',
        '& button': {
            width: 'auto',
        },
        '& .MuiButtonBase-root': {
            backgroundColor: 'transparent',
        }
    },
    iconButton: {
        padding: 0,
    },
    subNavigationItem: {
        marginRight: '20px',
        marginBottom: '60px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        width: 'auto'
    },
    orderDetailsTable: {
        marginBottom: '40px',
    },
    approverContainer: {
        position: 'relative',
    },
    changeApprover: {
        position: 'absolute',
        right: '50px',
        top: '52%',
    },
    autoComplete: {
        marginRight: '25px',
        marginTop: '8px',
        width:'94%'
    }
}));

const NewPurchaseOrder = (props) => {

    const classes = useStyles();
    const params = new URLSearchParams(window.location.search)
    const history = useHistory()
    const { setShowGlobalClientList, setShowPreloader } = useContext(ClientContext);
    

    const handleGoToSearch = () => {
        history.goBack();
    }

    const [canEdit, setCanEdit] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [suppliers, setSuppliers] = useState([]);
    const [branchList, setBranchList] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [contractList, setContractList] = useState([])
    const [contractDetails, setContractDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deliveryDetailsModal, setDeliveryDetailsModal] = useState(false);
    const [orderDetailsModal, setOrderDetailsModal] = useState(false);
    const [orderDetailsEditModal, setOrderDetailsEditModal] = useState(false);
    const [approverModal, setApproverModal] = useState(false);
    const [selectedDetailItem, setSelectedDetailItem] = useState({ quantity: 0, comments: "" });
    const [selectedDetailIndex, setSelectedDetailIndex] = useState(-1);

    const [select, setSelect] = useState({
        supplierID: "",
        supplierTicketNumber: "",
        approverName: "",
        purchaseOrderType: { literalText: "Parts", code: "1" },
        purchaseOrderStatus: { literalText: "Awaiting Approval", code: "2" },
        descriptionOfWorks: "",
        instructionNumber: (params.get("instruction")) ? params.get("instruction") : "",
        instructionID: (params.get("instructionID")) ? params.get("instructionID") : "",
        jobID: (params.get("jobID")) ? params.get("jobID") : "",
        initialCost: 0,
        currentCost: 0,
        selectAddress: "",
        supplierName: "",
        supplierBranchName: "",
        supplierBranchID: "",
        supplierTicket: "",
        clientContractName: "",
        clientContractID: (params.get("clientContractID")) ? params.get("clientContractID") : "",
        createdOn: new Date().toISOString(),
        estimatedDeliveryDate: addDays(new Date(), 3).toISOString(),
        orderLines: [],
        deliveryDetails: {},
        companyDivisionID: "",
        jobStatus: 3,
        jobStatusName: "Parts Needed",
        jobNumber:""
    }, loading);

    useEffect(() => {
        if (loading) {
            ClientService.getClientContracts()
                .then((response) => {
                    setContractDetails(response.collection);
                    const items = response.collection.map((item) =>
                        <MenuItem key={item.clientContractID} value={item.clientContractID}>{item.clientContractName}</MenuItem>
                    );
                    setContractList(items);
                    if (params.get("clientContractID") && contractDetails.length > 0) {
                        let companyDiv = contractDetails.filter(contract => contract.clientContractID === params.get("clientContractID"))[0].companyDivisionID;
                        setSelect({
                            ...select,
                            companyDivisionID: companyDiv
                        })
                    }
                })
                .catch((error) => console.warn(error))

            PurchaseOrderService.getSuppliers()
                .then((response) => {
                    const items = response.value.collection.map((item) =>
                        <MenuItem key={item.supplierID} value={item.supplierID}>{item.supplierName}</MenuItem>
                    );
                    setSuppliers(response.value.collection);
                    setSupplierList(items);
                })
                .catch((error) => console.warn(error))
                .finally(() => setLoading(false))

            setShowGlobalClientList(false);
        }
    }, [contractList]);

    useEffect(() => {
        setBranchList([]);
        setSelect({
            ...select,
            supplierBranchID: "",
            supplierBranchName: ""
        })
        if (select.supplierID) {
            PurchaseOrderService.getBranches(select.supplierID)
                .then((response) => {
                    const items = response.value.collection.map((item) =>
                        <MenuItem key={item.partsSupplierEmailID} value={item.partsSupplierEmailID}>{item.partsSupplierBranch}</MenuItem>
                    );
                    setBranchList(items);
                })
                .catch((error) => console.warn(error))
        }
    }, [select.supplierID])

    const addOrderLineButtons = (lines) => {
        const newLines = JSON.parse(JSON.stringify(lines))

        newLines.forEach((line, i) => {
            line.edit = <IconButton className={classes.iconButton} aria-label="edit" component="span" lineId={i} onClick={handleEditOrderLine}> <EditIcon fill="#9B9D9D" /> </IconButton>;
            line.delete = <IconButton className={classes.iconButton} aria-label="edit" component="span" lineId={i} onClick={handleDeleteOrderLine}> <DeleteIcon fill="#9B9D9D" /> </IconButton >

        })

        return newLines;
    }

    const handleEditOrderLine = (event) => {
        let itemIndex = event.currentTarget.getAttribute("lineId")
        setSelectedDetailItem(select.orderLines[itemIndex]);
        setSelectedDetailIndex(itemIndex);
        setOrderDetailsEditModal(!orderDetailsEditModal)
    }

    const handleDeleteOrderLine = (event) => {
        let itemIndex = event.currentTarget.getAttribute("lineId")
        let lineToDelete = select.orderLines[itemIndex]
        let updateCost = select.currentCost - lineToDelete.lineValue;
        let updateOrderLines = select.orderLines;
        updateOrderLines.splice(itemIndex, 1);

        let updateVals = {
            currentCost: updateCost.toFixed(2),
            orderLines: updateOrderLines
        }
        setSelect(select => ({
            ...select,
            ...updateVals
        }));
    }

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelect({
            ...select,
            [event.target.name]: value,
        })
    };

    const handleSupplierChange = (newSupplier) => {
        setSelect({
            ...select,
            supplierID: newSupplier.props.value,
            supplierName: newSupplier.props.children
        })
    }

    function addSearchIconToEndAdornment(endAdornment) {
        const children = React.Children.toArray(endAdornment.props.children);
        children.push(<SearchIcon />);
        return React.cloneElement(endAdornment, {}, children);
    }

    const handleContractChange = (event) => {
        const value = event.target.value;
        const companyDiv = contractDetails.filter(contract => contract.clientContractID === value)[0].companyDivisionID;
        setSelect({
            ...select,
            clientContractID: value,
            companyDivisionID: companyDiv
        })
    }

    const handleTypeChange = (event) => {
        const value = event.target.value;
        setSelect({
            ...select,
            purchaseOrderType: { literalText: "Parts", code: value },
        })
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setSelect({
            ...select,
            estimatedDeliveryDate: new Date(date).toISOString(),
        })
    };

    const handleDeliveryDetailsModal = () => {
        setDeliveryDetailsModal(!deliveryDetailsModal)
    };

    const handleDeliveryDetailsModalSave = (updateDetails) => {
        setSelect(select => ({
            ...select,
            ...{ deliveryDetails: updateDetails }
        }));
        setDeliveryDetailsModal(!deliveryDetailsModal);
    }

    const handleRowClick = (event) => {

    }

    const handleOrderDetailsModal = () => {
        setOrderDetailsModal(!orderDetailsModal)
    }

    const handleOrderDetailsModalSave = (partDetails, qty, comments) => {
        setOrderDetailsModal(!orderDetailsModal);
        let newOrderLine = {
            productName: `${partDetails.supplierPriceCode} - £${partDetails.supplierPriceRate.toFixed(2)}`,
            price: (partDetails.supplierPriceRate).toFixed(2),
            quantity: qty,
            lineValue: (qty * partDetails.supplierPriceRate).toFixed(2),
            comments: comments,
            supplierPriceID: partDetails.supplierPriceID
        }
        let existingOrderLines = select.orderLines;
        existingOrderLines.push(newOrderLine);
        let updateVals = {
            currentCost: (parseFloat(select.currentCost) + qty * partDetails.supplierPriceRate),
            orderLines: existingOrderLines
        }
        setSelect(select => ({
            ...select,
            ...updateVals
        }));

    }

    const handleOrderDetailsEditModal = () => {
        setOrderDetailsEditModal(!orderDetailsEditModal)
    };

    const handleOrderDetailsEditModalSave = (partDetails, index) => {
        let oldLineValue = partDetails.lineValue;
        let existingOrderLines = select.orderLines;
        existingOrderLines[index].quantity = partDetails.quantity;
        existingOrderLines[index].comments = partDetails.comments;
        existingOrderLines[index].lineValue = parseFloat((partDetails.quantity * partDetails.price).toFixed(2));

        let updateVals = {
            currentCost: (parseFloat(select.currentCost) - oldLineValue + existingOrderLines[index].lineValue).toFixed(2),
            orderLines: existingOrderLines
        }
        setSelect(select => ({
            ...select,
            ...updateVals
        }));
    };

    const handleClearAllLines = () => {
        let updateOrderLines = {
            orderLines: [],
            currentCost: 0
        };
        setSelect(select => ({
            ...select,
            ...updateOrderLines
        }));
    }

    const handleApproverModal = () => {
        setApproverModal(!approverModal)
    }

    const handleApproverModalSave = (updateDetails) => {
        setSelect(select => ({
            ...select,
            ...{ approverName: updateDetails.approverName, approverID: updateDetails.approverID }
        }));
        setApproverModal(!approverModal)
    }

    const createPO = () => {
        setShowPreloader(true);
        PurchaseOrderService.create(select)
            .then((response) => {
                console.log(response);
                history.push(`/purchase-orders?searchText=${response.purchaseOrderNumber}`);
            })
            .catch((error) => console.warn(error))
            .finally(() => setShowPreloader(false));
    }


    return (

        <div>

            <Button
                variant="contained"
                onClick={() => handleGoToSearch()}
                className={classes.button}
                startIcon={<ArrowBackIcon />}
            >
                Back
            </Button>

            <div className={classes.titleBox}>
                <Typography className={classes.title} variant="h5">
                    New Purchase Order
                </Typography>
                
            </div>
            <Divider className={classes.divider} />

            
            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>

                        <Typography variant="body2" className={classes.itemTitle}>
                            Instruction Number
                        </Typography>
                        <Link href="#" variant="body1" color="secondary">
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                fullWidth
                                name="instructionNumber"
                                placeholder="Please input instruction"
                                value={select.instructionNumber}
                                onChange={handleSelectChange}
                            />
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Client Contract</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        ><Select
                                labelId="contract-label"
                                className={classes.select}
                                name="clientContractID"
                                displayEmpty
                                label="Please select client contract..."
                                value={contractList.length>0 ? select.clientContractID : ''}
                                onChange={handleContractChange}
                            >
                                {contractList}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Supplier</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <Autocomplete
                                variant="outlined"
                                options={supplierList}
                                disableClearable
                                forcePopupIcon={false}
                                name="supplierID"
                                placeholder="Supplier"
                                renderInput={(params) => <TextField {...params} variant="outlined" className={classes.autoComplete} fullWidth InputProps={{
                                    ...params.InputProps, endAdornment: addSearchIconToEndAdornment(params.InputProps.endAdornment
                                    )
                                }} />}
                                getOptionLabel={option => option.props.children !== null ? option.props.children : "Please choose a supplier"}
                                onChange={(_event, newSupplier) => { handleSupplierChange(newSupplier); } }
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Purchase Order Type</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <Select
                                className={classes.select}
                                name="purchaseOrderType"
                                displayEmpty
                                value={select.purchaseOrderType.code}
                                onChange={handleTypeChange}
                            >
                                <MenuItem value="Please select PO type..." disabled>
                                    Please select PO type...
                                </MenuItem>
                                <MenuItem value="1" >
                                    Parts
                                </MenuItem>
                                <MenuItem value="2">
                                    Subcontractor
                                </MenuItem>
                                <MenuItem value="3">
                                    Van Stock
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Supplier Ticket Number</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                fullWidth
                                name="supplierTicket"
                                placeholder="Enter ticket number..."
                                value={select.supplierTicket}
                                onChange={handleSelectChange}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Estimated Delivery Date</label>
                        <MuiPickersUtilsProvider className={classes.datePickerContainer} utils={DateFnsUtils}>
                            <Grid container>
                                <KeyboardDatePicker
                                    disableToolbar
                                    autoOk
                                    className={classes.datePicker}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    name="estimatedDeliveryDate"
                                    id="estimatedDeliveryDate"
                                    value={select.estimatedDeliveryDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Box>
                </Grid>
            </Grid>
            {branchList.length > 0 &&
                <Grid container >
                    <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                        <Box>
                            <label className={classes.itemTitle}>Supplier Branch</label>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                fullWidth
                            >
                                <Select
                                    className={classes.select}
                                    name="supplierBranchID"
                                    displayEmpty
                                    label="Please select supplier branch..."
                                    value={select.supplierBranchID}
                                    onChange={handleSelectChange}
                                >
                                    {branchList}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            }
            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <Link onClick={handleDeliveryDetailsModal} variant="body1" color="secondary">
                            + Add / View Delivery Details
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} sm={12} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Description of Works</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.textarea}
                                variant="outlined"
                                multiline
                                minrows={10}
                                maxrows={20}
                                fullWidth
                                name="descriptionOfWorks"
                                placeholder="Description of works..."
                                value={select.descriptionOfWorks}
                                onChange={handleSelectChange}
                            />
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            <div className={classes.titleBox}>
                <Typography className={classes.subtitle} variant="h5">
                    Order Details
                </Typography>
            </div>
            <Divider className={classes.divider} />

            <Grid container>
                <Grid item xs={12} sm={12} className={classes.orderDetailsTable}>

                    <ResultsTable
                        columns={[
                            {
                                name: 'id',
                                label: 'Id',
                                hidden: true
                            },
                            {
                                name: 'productName',
                                label: 'Product'
                            },
                            {
                                name: 'quantity',
                                label: 'Quantity'
                            },
                            {
                                name: 'price',
                                label: 'Price'
                            },
                            {
                                name: 'lineValue',
                                label: 'Line Value'
                            },
                            {
                                name: 'comments',
                                label: 'Comments'
                            },
                            {
                                name: 'edit',
                                label: ''
                            },
                            {
                                name: 'delete',
                                label: ''
                            },
                        ]}
                        data={addOrderLineButtons(select.orderLines)}
                        rowClick={handleRowClick}
                        linkKey="id"
                        orderBy="id"
                        direction="desc"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box>
                        {select.clientContractID!=="" &&
                        <Button
                            className={classes.subNavigationItem}
                            variant="outlined"
                            color="primary"
                            onClick={handleOrderDetailsModal}
                        >
                            + Add New Line
                        </Button>}
                        <Button
                            className={classes.subNavigationItem}
                            variant="outlined"
                            color="primary"
                            onClick={handleClearAllLines}
                        >
                            Clear all lines
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            {select.clientContractID!=="" && 
            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box className={classes.approverContainer}>
                        <label className={classes.itemTitle}>Approver</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                fullWidth
                                name="approverName"
                                placeholder="Please select approver"
                                value={select.approverName}
                                onChange={handleSelectChange}
                            />
                        </FormControl>
                        <Link className={classes.changeApprover} onClick={handleApproverModal} variant="body1" color="secondary">
                            Change...
                        </Link>
                    </Box>
                </Grid>
                </Grid>}

            <Grid container >
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Initial Cost</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                fullWidth
                                name="initialCost"
                                value={select.initialCost}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.fieldContainer}>
                    <Box>
                        <label className={classes.itemTitle}>Current Cost</label>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                fullWidth
                                name="currentCost"
                                value={parseFloat(select.currentCost).toFixed(2)}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid container>

                    <Button
                        className={classes.subNavigationItem}
                        variant="contained"
                        color="primary"
                        onClick={createPO}
                    >
                        Save
                    </Button>

                </Grid>

            </Grid>



            <DeliveryDetailsModal
                open={deliveryDetailsModal}
                data={select.deliveryDetails}
                onClose={handleDeliveryDetailsModal}
                onSubmit={handleDeliveryDetailsModalSave}
                canEdit={canEdit}
            />

            <OrderDetailsModal
                open={orderDetailsModal}
                onClose={handleOrderDetailsModal}
                onSave={handleOrderDetailsModalSave}
                suppliersList={suppliers}
                clientContractName={select.clientContractName}
                clientContractID={select.clientContractID}
                initialSupplier={{ supplierID: select.supplierID, supplierName: select.supplierName }}
                canEdit={canEdit}
            />
            
            <OrderDetailsEditModal
                open={orderDetailsEditModal}
                onClose={handleOrderDetailsEditModal}
                onSave={handleOrderDetailsEditModalSave}
                detailItem={selectedDetailItem}
                detailIndex={selectedDetailIndex}
                canEdit={canEdit}
            />
            
            <ApproverModal
                open={approverModal}
                onClose={handleApproverModal}
                onSubmit={handleApproverModalSave}
                clientContractID={select.clientContractID}
            />



        </div>

    );
};

export default NewPurchaseOrder;