import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom'
import {
    Button
} from '@material-ui/core';
import PropertyService from "../../services/PropertyService"
import ClientService from "../../services/ClientService"
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const useStyles = makeStyles(() => ({
    purchaseOrderButton: {
        width: 'auto!important'
    }
}));

const CreateJobButton = (props) => {
    const classes = useStyles();
    const {
        show,
        propertyId,
        clientId
    } = props

    const [acceptNewJobs, setAcceptNewJobs] = useState(false);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (show && loading && clientId!=='') {

            let canCreateJobs = false;

            PropertyService.getAcceptsNewJobs(propertyId)
                .then(response => {
                    canCreateJobs = response.object;
                })
                .catch((error) => console.warn(error))
                .finally(() => setLoading(false));

            ClientService.getPermissions(clientId)
                .then(response => {
                    const matches = (permission) => permission.recordType === 1 && permission.recordAction === 0 && permission.permission === true
                    canCreateJobs = canCreateJobs && response.some(matches);
                    setAcceptNewJobs(canCreateJobs);
                })
                .catch((error) => console.warn(error))
                .finally(() => setLoading(false));
        }

    }, [show, loading, propertyId, clientId])

  
    return (
        <div>
            {
                (show && !loading && acceptNewJobs
                    ?                  
                        <Button
                            component={Link}
                            underline="none"
                            to={`/create-job/${propertyId}`}
                            className={classes.purchaseOrderButton}
                            startIcon={<DescriptionOutlinedIcon />}
                            variant="contained"
                            size="large"
                            color="primary"
                            target="_new"
                        >
                            Create Job
                        </Button>
                    : <div></div>)
            }
        </div>
    );
};
export default CreateJobButton;
