import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Button,
  Box,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import ResultsTable from "components/ui/ResultsTable/ResultsTable";
import PowerBiService from "services/PowerBiService";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "600",
    marginBottom: "10px",
  },
  divider: {
    backgroundColor: "#E6ADA5",
  },
  submit: {
    margin: "auto 10px",
    height: "52px",
    width: "30%!important",
    textTransform: "none",
  },
  preloader: {
    display: "flex",
    justifyContent: "center",
    padding: "40px 0",
  },
}));

const PowerBiReportsManagement = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [powerBiReports, setPowerBiReports] = useState([]);

  const getPowerBiReports = () => {
    return PowerBiService.getPowerBiReports().then((response) => {
      const items = response.collection.map(
        ({ reportID, reportName, description }) => ({
          id: reportID,
          name: reportName,
          description,
        })
      );
      return { items, nextPage: response.nextPage };
    });
  };

  useEffect(() => {
    setLoading(true);
    getPowerBiReports()
      .then((response) => {
        setPowerBiReports(response.items);
      })
      .catch((error) => console.warn(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container display="flex" justifyContent="flex-end">
        <Grid item xs={7} container direction="column"></Grid>
        <Grid item xs={5} container direction="column">
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              className={classes.submit}
              color="primary"
              href="power-bi-management/add-new-report"
            >
              New Report
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography className={classes.title} variant="h5">
          Power BI Reports
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={12} sm={12}>
          {loading ? (
            <div className={classes.preloader}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <ResultsTable
              columns={[
                {
                  name: "id",
                  label: "Id",
                  hidden: true,
                },
                {
                  name: "name",
                  label: "Name",
                },
                {
                  name: "description",
                  label: "Description",
                },
              ]}
              data={powerBiReports}
              baseLink="/admin/power-bi-management"
              linkKey="id"
              orderBy="id"
              direction="asc"
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default PowerBiReportsManagement;
