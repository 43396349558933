import http from '../utils/http'

const getAll = (query) => {
  const params = new URLSearchParams()
  const page = query.hasOwnProperty('page') ? query.page : 1
  const size = query.hasOwnProperty('size') ? query.size : 1000
  params.append('PageNumber', page)
  params.append('PageSize', size)

  if (query.hasOwnProperty('address') && query.address) {
    params.append('JobNoOrAddress', query.address)
    }

  if (query.hasOwnProperty('clientId') && query.clientId && query.clientId!=='-1') {
    params.append('ClientId', query.clientId)
    }

    if (query.hasOwnProperty('jobStatus') && query.jobStatus && parseInt(query.jobStatus) !== -1) {
        console.log('input', query.jobStatus)
        let typeArray = parseInt(query.jobStatus) === 99
            ? '3,7'
            : query.jobStatus;

        params.append('JobStatus', typeArray)
    }

    if (query.hasOwnProperty('jobNo') && query.jobNo) {
        params.append('JobNoOnly', query.jobNo)
    }

    if (query.hasOwnProperty('accuStatus') && query.accuStatus) {
        params.append('AppointedStatus', query.accuStatus)
    }

    console.log('job params', query);

  return http.get(`/Job`, { params })
}

const getById = (id,uprn,client) => http.get(`/Job/${id}/${uprn}/${client}`)

const getFiles = (id, client) => http.get(`/Job/${id}/files?client=${client}`)

const getZipWithAllFiles = (id, client) => http.get(`/Job/${id}/files/download?client=${client}`, { responseType: 'blob' })

const getInstructions = (id,client) => http.get(`/Job/${id}/instructions?client=${client}`)

const getCommunications = (id, client) => http.get(`/Job/${id}/communications?client=${client}`)

const getPhotos = (id, client) => http.get(`/Job/${id}/photos?client=${client}`)

const getZipWithAllPhotos = (id, client) => http.get(`/Job/${id}/photos/download?client=${client}`, { responseType: 'blob' })

const getNotes = (id, client) => http.get(`/Job/${id}/notes?client=${client}`)

const getSors = (id) => http.get(`/Job/${id}/sors`)

const updateStatus = (id, status, name) => http.put('/Job/update-status', { Id: id, Status: status, StatusName: name })

const create = (job) => http.post('/Job', job)

const getPriorities = (clientId,uprn,contractId) => http.get(`/Job/${clientId}/${contractId}/${uprn}/priorities`)

const getAllSors = (clientId, clientContractId, uprn) => http.get(`/Job/${clientId}/${clientContractId}/${uprn}/all-sors`)

const getAvailability = (clientId, clientContractId, uprn, jobNo, clientRef, startDate) => http.get(`/Job/availability/${clientId}/${clientContractId}/${uprn}/${jobNo}/${clientRef}/${startDate}`)

const bookSlot = (clientId, clientContractId, uprn, jobNo, slotId) => http.post(`/Job/book/${clientId}/${clientContractId}/${uprn}/${jobNo}/${slotId}`)

const isComplexJob = (clientId, clientContractId, jobNo) => http.get(`/Job/is-complex/${clientId}/${clientContractId}/${jobNo}`)

export default {
  getAll,
  getById,
  getFiles,
  getZipWithAllFiles,
  getInstructions,
  getCommunications,
  getPhotos,
  getZipWithAllPhotos,
  getNotes,
  getSors,
  updateStatus,
  create,
  getPriorities,
  getAllSors,
  getAvailability,
  bookSlot,
    isComplexJob
}
