import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import {
  Button,
  Typography,
  Breadcrumbs,
  Box,
  Grid,
  Divider
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import ApplianceService from '../../services/ApplianceService'
import Moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    borderRight: '1px solid #BCBEBE',
    paddingRight: '10px'
  },
  subTitle: {
    paddingLeft: '10px',
    color: '#BCBEBE'
  },
  titleBox: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '20px',
    marginBottom: '20px'
  },
  breadcrumbs: {
    fontSize: '12px',
    marginBottom: '20px',
    textTransform: 'uppercase',
    '& a': {
      color: 'inherit'
    },
    '& span': {
      color: '#E4006D',
      fontWeight: 'bold'
    }
  },
  divider: {
    backgroundColor: '#E6ADA5',
    marginBottom: '20px'
  },
  contactDetailsItems: {
    paddingTop: '12px'
  },
  itemTitle: {
    color: 'rgba(168, 118, 163, 1)',
    fontWeight: '500'
    },
    button: {
        width: '250px'
    }
}))

const Appliance = (props) => {
  const classes = useStyles()
  const history = useHistory()

  const propertyId = props.match.params.propertyId
  const applianceId = props.match.params.id

  const initialState = {
    id: null,
    type: null,
    manufacturer: null,
    model: null,
    scrapped: false,
    location: null,
    ownership: null,
    installedDate: null,
    estimatedAge: null,
    warrantyExpiryDate: null,
    dateApplianceExpiresOn: null,
    fuelType: null,
    flueType: null,
    flueDirection: null,
    gasCouncilNumber: null,
    serialNumber: null,
    detectorPower: null,
    addressLine: null,
    client: null,
    clientEquipmentID: null
  }

  const [currentAppliance, setCurrentAppliance] = useState(initialState)

  const getAppliance = (id) => {
    ApplianceService.getById(id)
      .then((response) => {
        const result = response.object
        setCurrentAppliance({
          id: result.applianceId,
          type: result.type?.literalText,
          manufacturer: result.manufacturer?.literalText,
          model: result.model?.literalText,
          scrapped: result.scrapped,
          location: result.location?.literalText,
          ownership: result.ownership?.literalText,
          installedDate: result.dateInstalled
            ? Moment(result.dateInstalled).format('DD-MM-YYYY')
            : null,
          estimatedAge: result.estimatedAge,
          warrantyExpiryDate: result.warrantyExpiryDate
            ? Moment(result.warrantyExpiryDate).format('YYYY')
            : null,
          dateApplianceExpiresOn: result.dateApplianceExpiresOn
            ? Moment(result.dateApplianceExpiresOn).format('DD-MM-YYYY')
            : null,
          fuelType: result.fuelType?.literalText,
          flueType: result.flueType?.literalText,
          flueDirection: result.flueDirection?.literalText,
          gasCouncilNumber: result.gcNumber,
          serialNumber: result.serialNumber,
          detectorPower: result.detectorPower?.literalText,
          addressLine: result.address?.addressLine1,
          client: result.client?.clientName,
          clientEquipmentID: result.clientEquipmentID
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleGoToList = () => {
    history.push({
      pathname: `/property/${propertyId}`,
      search: `?tab=appliances`
    })
  }

  useEffect(() => {
    getAppliance(applianceId)
  }, [applianceId])

  return (
    <div>
      <Button
              variant="contained"
              className={classes.button }
        onClick={() => handleGoToList()}
        startIcon={<ArrowBackIcon />}
      >
        Back to Appliance List
      </Button>
      <div className={classes.titleBox}>
        <Typography className={classes.title} variant="h4">
          {currentAppliance.addressLine}
        </Typography>
        <Typography
          className={classes.subTitle}
          variant="subtitle1"
          gutterBottom
        >
          {currentAppliance.client}
        </Typography>
      </div>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <Link to={`/property/${propertyId}?tab=appliances`}>
          APPLIANCES
        </Link>
        <span>{currentAppliance.type || ''}</span>
      </Breadcrumbs>
      <Divider className={classes.divider} />
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} container>
          <Grid item xs container spacing={3}>
            <Grid item sm={12} md={3} xs={12}>
              <Box>
                <Typography variant="h5">Appliance Information</Typography>
              </Box>
            </Grid>
            <Grid item sm={12} md={9} xs={12}>
              <Grid item xs={12} container direction="row">
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Type
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.type ? currentAppliance.type : '-'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Manufacturer
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.manufacturer
                        ? currentAppliance.manufacturer
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Model
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.model ? currentAppliance.model : '-'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction="row">
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Scrapped / Removed
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.scrapped ? 'Yes' : 'No'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Location
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.location
                        ? currentAppliance.location
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Ownership
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.ownership
                        ? currentAppliance.ownership
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction="row">
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Date Installed
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.installedDate
                        ? currentAppliance.installedDate
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Estimated Age
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.estimatedAge
                        ? `${currentAppliance.estimatedAge} Years`
                        : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Warranty Expiry Date
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.warrantyExpiryDate
                        ? currentAppliance.warrantyExpiryDate
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction="row">
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Fuel Type
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.fuelType
                        ? currentAppliance.fuelType
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Flue Type
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.flueType
                        ? currentAppliance.flueType
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Flue Direction
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.flueDirection
                        ? currentAppliance.flueDirection
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction="row">
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Gas Council Number
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.gasCouncilNumber
                        ? currentAppliance.gasCouncilNumber
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Serial Number
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.serialNumber
                        ? currentAppliance.serialNumber
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Detector Power
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.detectorPower
                        ? currentAppliance.detectorPower
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction="row">
                <Grid item sm={4} md={4} xs={12} container direction="column">
                  <Box className={classes.contactDetailsItems}>
                    <Typography variant="body2" className={classes.itemTitle}>
                      Date Appliance Expires On
                    </Typography>
                    <Typography variant="body1">
                      {currentAppliance.dateApplianceExpiresOn
                        ? currentAppliance.dateApplianceExpiresOn
                        : '-'}
                    </Typography>
                  </Box>
                   </Grid>
                   <Grid item sm={4} md={4} xs={12} container direction="column">
                   <Box className={classes.contactDetailsItems}>
                   <Typography variant="body2" className={classes.itemTitle}>
                    Client Equipment ID
                    </Typography>
                   <Typography variant="body1">
                   {currentAppliance.clientEquipmentID
                   ? currentAppliance.clientEquipmentID
                   : '-'}
                    </Typography>
                   </Box>
                   </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Appliance
