import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  Box,
  Typography,
  Tab,
  Tabs,
  Grid,
  TextField,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PropTypes from "prop-types";

import PowerBiService from "services/PowerBiService";

import PowerBiReportDetailsTab from "components/PowerBiReportDetailsTab/PowerBiReportDetailsTab";
import PowerBiReportPermissionsTab from "components/PowerBiReportPermissionsTab/PowerBiReportPermissionsTab";
import BasicModal from "components/ui/BasicModal/BasicModal";
import DeleteIcon from "components/ui/Icons/DeleteIcon";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #E6ADA5",
  },
  indicator: {
    backgroundColor: "#E4006D",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: 700,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "#E4006D",
      opacity: 1,
    },
    "&:selected": {
      color: "#E4006D",
      fontWeight: 700,
    },
    "&:focus": {
      color: "#E4006D",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: "flex",
    alignItems: "baseline",
    marginTop: "20px",
    marginBottom: "20px",
  },
  title: {
    borderRight: "1px solid #BCBEBE",
    paddingRight: "10px",
  },
  subTitle: {
    paddingLeft: "10px",
    color: "#BCBEBE",
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
  },
  deleteBtn: {
    margin: "auto 10px",
    height: "52px",
    width: "30%!important",
    textTransform: "none",
  },
  label: {
    marginBottom: "10px",
    color: "rgba(168, 118, 163, 1)",
    fontWeight: "500",
    fontSize: "12px",
  },
  contentBox: {
    padding: "30px 50px 10px 50px",
  },
  rotateLeftIcon: {
    fontSize: "24px !important",
  },
  backButton: {
    width: "fit-content",
  },
}));

const REPORT_INITIAL = {
  name: "",
  description: "",
};

const ManagePowerBiReport = ({
  match: {
    params: { reportId },
  },
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [report, setReport] = useState(REPORT_INITIAL);

  useEffect(() => {
    handleGetPowerBiReportById();
  }, [reportId]);

  const handleGetPowerBiReportById = () => {
    setLoading(true);
    PowerBiService.getPowerBiReportById(reportId)
      .then((response) => {
        const data = {
          name: response.object.reportName || "",
          description: response.object.description || "",
        };
        setReport(data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    PowerBiService.deletePowerBiReport(reportId)
      .then((response) => {
        setOpenDeleteModal(false);
        if (response.succeeded) {
          history.push("/power-bi-management");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleChangeReport = useCallback(
    ({ target: { name, value } }) => {
      const next = { ...report, [name]: value, [`${name}Error`]: '' }
      setReport(next)
    },
    [report]
  )

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleUpdateReport = useCallback(() => {
    setLoading(true);
    const { name, description } = report;
    PowerBiService.updatePowerBiReport(reportId, {
      reportName: name.length ? name : null,
      description: description.length ? description : null,
    })
      .then(() => {})
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [reportId, report]);

  return (
    <div>
      <div>
        <Button
          className={classes.backButton}
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.goBack()}
        >
          Back to Power BI Reports List
        </Button>
      </div>
      <Grid container direction="row" spacing={1} alignItems="center">
        <Grid item xs={7} container direction="column">
          <div className={classes.titleBox}>
            <Typography className={classes.title} variant="h4">
              Manage Report
            </Typography>
            <Typography
              className={classes.subTitle}
              variant="subtitle1"
              gutterBottom
            >
              {report.name}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={5} container direction="column">
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              className={classes.deleteBtn}
              color="primary"
              startIcon={<DeleteIcon />}
              onClick={handleOpenDeleteModal}
            >
              Delete Report
            </Button>
          </Box>
        </Grid>
      </Grid>
      <div>
        <div className={classes.tabs}>
          <AntTabs
            value={currentTab}
            onChange={handleChangeTab}
            aria-label="ant example"
          >
            <AntTab label="REPORT DETAILS" />
            <AntTab label="PERMISSIONS" />
          </AntTabs>
          <TabPanel value={currentTab} index={0}>
            <PowerBiReportDetailsTab
              loading={loading}
              data={report}
              onChange={handleChangeReport}
              onSave={handleUpdateReport}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <PowerBiReportPermissionsTab reportId={reportId} />
          </TabPanel>
        </div>
      </div>
      <BasicModal
        headerIcon="/icons/admin-delete-icon.svg"
        headerTitle="Confirm report deletion"
        submitButtonText="Confirm"
        onClose={handleCloseDeleteModal}
        onSubmit={handleDelete}
        dialogContent={() => (
          <Box className={classes.contentBox}>
            <Box mb={2}>
              <label className={classes.label}>Report Name</label>
              <TextField
                className={classes.input}
                fullWidth
                id="reportName"
                name="name"
                value={report.name}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
        )}
        open={openDeleteModal}
      ></BasicModal>
    </div>
  );
};

export default ManagePowerBiReport;
