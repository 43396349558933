import React, { useState, useEffect, useContext } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import {
    Button,
    Typography,
    Breadcrumbs,
    Box,
    Grid,
    Divider,
    Card,
    CardContent,
    Select,
    MenuItem
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ListOfFiles from '../../components/ui/ListOfFiles/ListOfFiles'
import ImageGallery from '../../components/ui/ImageGallery/ImageGallery'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Carousel from 'react-elastic-carousel'
import JobService from '../../services/JobService'
import PropertyService from '../../services/PropertyService'
import { useHistory } from 'react-router-dom'
import Moment from 'moment'
import ListOfNotes from '../../components/ui/ListOfNotes/ListOfNotes'
import {
    createJobInstructionItem,
    createNoteItem,
    createFileItem,
    createCommunicationItem,
    createPhotoItem,
    returnNA
} from '../../utils/mapData'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { getComparator, stableSort } from '../../utils/dataSorts'
import { downloadFile } from '../../utils/fileConverter'
import ClientContext from "../../utils/globalClientContext"

const useStyles = makeStyles((theme) => ({
    titleBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        marginBottom: '20px'
    },
    title: {
        borderRight: '1px solid #BCBEBE',
        paddingRight: '10px'
    },
    subTitle: {
        paddingLeft: '10px',
        color: '#BCBEBE'
    },
    padding: {
        padding: theme.spacing(3)
    },
    divider: {
        backgroundColor: '#E6ADA5',
        marginBottom: '20px'
    },
    greyDivider: {
        backgroundColor: '#DEDEDE',
        marginBottom: '20px'
    },
    itemGridDivider: {
        backgroundColor: '#DEDEDE',
        marginBottom: 0
    },
    breadcrumbs: {
        fontSize: '12px',
        marginBottom: '20px',
        textTransform: 'uppercase',
        '& a': {
            color: 'inherit'
        },
        '& span': {
            color: '#E4006D',
            fontWeight: 'bold'
        }
    },
    contactDetailsItems: {
        paddingTop: '12px',
        paddingBottom: '35px',
        minHeight: '75px'
    },
    itemTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '500'
    },
    tableTitle: {
        color: 'rgba(168, 118, 163, 1)',
        fontWeight: '400',
        fontSize: '14px'
    },
    arrowHistory: {
        marginLeft: '50px'
    },
    tableIcon: {
        verticalAlign: 'bottom'
    },
    statusImg: {
        paddingRight: '8px'
    },
    carouselCardContainer: {
        '&:focus': {
            outline: 'none'
        }
    },
    carouselCard: {
        margin: '20px 20px',
        width: 'inherit',
        minHeight: '270px',
        boxShadow: '0 0 20px rgba(0,0,0,0.2)',
        border: 'none',
        padding: '10px',
        '&:focus': {
            outline: 'none'
        }
    },
    textDecoration: {
        fontStyle: 'italic',
        paddingTop: '10px'
    },
    outgoingTitle: {
        display: 'flex',
        color: '#A876A3'
    },
    incomingTitle: {
        display: 'flex',
        color: '#F99600'
    },
    forwardBtn: {
        marginLeft: '5px'
    },
    instructionsContainer: {
        marginBottom: '20px',
        paddingLeft: '0 !important',
        paddingRight: '0 !important'
    },
    instructionItem: {
        paddingTop: '10px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    instructionItemPadding: {
        padding: '0 10px 10px 10px !important'
    },
    withoutPaddingRight: {
        paddingRight: '0 !important'
    },
    paddigTopCotainer: {
        paddingTop: '12px'
    },
    paddingBottomContainer: {
        paddingBottom: '35px'
    },
    backBtn: {
        width: 'fit-content'
    },
    columnMarginRight: {
        marginRight: theme.spacing(1)
    },
    purchaseOrderButton: {
        width: 'auto!important'
    }
}))

const JobSummary = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const jobId = props.match.params.id
    const clientId = props.match.params.clientId
    const uprn = props.match.params.uprn
    const carousel = React.useRef()
    const { setShowGlobalClientList, globalUserRole } = useContext(ClientContext);
    setShowGlobalClientList(false);

    const initialState = {
        id: null,
        dateRaised: null,
        jobType: '',
        jobNumber: '',
        clientJobNumber: '',
        status: '',
        priority: '',
        targetCompletionDate: null,
        propertyDetails: '',
        tenantDetails: '',
        jobDescription: '',
        client: '',
        propertyId: '',
        targetResponseDate: '',
        softFixOutcome: '',
        lockedAtLegal: null,
        cancelledReason: '',
        smv: '0'
    }

    const [currentJob, setCurrentJob] = useState(initialState)
    const [jobInstructions, setJobInstructions] = useState([])
    const [jobCommunications, setJobCommunications] = useState([])
    const [jobNotes, setJobNotes] = useState([])
    const [files, setFiles] = useState([])
    const [photos, setPhotos] = useState([])
    const [status, setStatus] = useState("0");
    const [isComplexJob, setIsComplexJob] = useState(true);

    const getJob = (jobId, clientId, uprn) => {
        JobService.getById(jobId, clientId, uprn)
            .then((response) => {
                const result = response.object
                console.log('job', result);
                setCurrentJob({
                    id: jobId,
                    dateRaised: result.dateRaised
                        ? Moment(result.dateRaised).format('DD-MM-YYYY HH:mm')
                        : null,
                    clientJobNumber: !result.clientJobNumber ? "-" : result.clientJobNumber,
                    clientContractId: result.clientContractId,
                    clientContract: result.clientContract,
                    jobDescription: result.jobDescription,
                    jobType: result.jobType?.literalText,
                    contractSpecificJobType: result.contractSpecificJobType,
                    serviceType: result.serviceType,
                    priority: result.priority?.literalText,
                    targetCompletionDate: result.targetCompletionDate
                        ? Moment(result.targetCompletionDate).format('DD-MM-YYYY HH:mm')
                        : null,
                    targetCompletionDateUnformatted: result.targetCompletionDate,
                    propertyDetails: `${result.propertyDetails}, ${result.property.address.postCode}`,
                    client: result.property?.client?.clientName,
                    jobNumber: result.jobNumber,
                    status: result.jobStatus?.literalText,
                    statusCode: result.jobStatus?.code ?? "0",
                    tenantDetails: result.property?.tenant?.occupierFullName,
                    propertyId: result.property?.propertyId,
                    targetResponseDate: result.targetResponseDate
                        ? Moment(result.targetResponseDate).format('DD-MM-YYYY HH:mm')
                        : null,
                    softFixOutcome: result.softFixOutcome,
                    lockedAtLegal: result.lockedAtLegal,
                    cancelledReason: result.cancelledReason,
                    jobValue: result.jobValue,
                    loggedBy: result.loggedBy,
                    property: result.property,
                    smv: result.smv.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2})
                });
                setStatus(result.jobStatus?.code ?? "0")
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getJobInstructions = (jobId, clientId) => {
        JobService.getInstructions(jobId, clientId)
            .then((response) => {
                console.log('instructions', response);
                const items = collectionCheck(response) ? stableSort(
                    response.collection,
                    getComparator('asc', 'instructionNumber', 'string')
                ).map((i) =>
                    createJobInstructionItem(
                        i.instructionId,
                        i.appointmentDateTime
                            ? Moment(i.appointmentDateTime).format('DD-MM-YYYY')
                            : '-',
                        i.appointmentDateTime
                            ? Moment(i.appointmentDateTime).format('HH:mm')
                            : '',
                        i.status?.literalText,
                        i.outcome?.literalText,
                        i.taskDescription,
                        i.instructionNumber,
                        i.pdaStatus,
                        i.operativeAssigned,
                        i.actionRequired
                    )
                ) : []
                setJobInstructions(items)

                
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getNotes = (jobId, clientId) => {
        JobService.getNotes(jobId, clientId)
            .then((response) => {
                console.log('notes', response);
                const items = collectionCheck(response) ? response.collection.map((i) =>
                    createNoteItem(
                        i.date ? Moment(i.date).format('DD-MM-YYYY HH:mm') : null,
                        i.noteText
                    )
                ) : []
                setJobNotes(items)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getFiles = (jobId, clientId) => {
        JobService.getFiles(jobId, clientId)
            .then((response) => {
                console.log('files',response.collection)
                const items = collectionCheck(response) ? stableSort(
                    response.collection,
                    getComparator('desc', 'date', 'date')
                ).map((i) =>
                    createFileItem(
                        i.fileName,
                        i.documentID,
                        i.date ? Moment(i.date).format('DD-MM-YYYY') : null,
                        i.fileName,
                        i.documentBody
                    )
                ) : []
                setFiles(items)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getCommunications = (jobId, clientId) => {
        JobService.getCommunications(jobId, clientId)
            .then((response) => {
                const items = collectionCheck(response) ? response.collection.map((i) =>
                    createCommunicationItem(
                        i.communicationText,
                        i.date ? Moment(i.date).format('DD-MM-YYYY HH:mm') : null,
                        i.type?.literalText,
                        i.type?.code
                    )
                ) : []

                setJobCommunications(items)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getPhotos = (jobId, clientId) => {
        JobService.getPhotos(jobId, clientId)
            .then((response) => {
                console.log('photos', response);
                const items = collectionCheck(response) ? response.collection.map((i) =>
                    createPhotoItem(
                        i.pictureId,
                        i.documentBody,
                        i.mimeType ?? 'image/jpeg',
                        i.pictureText,
                        i.subject,
                        i.date ? Moment(i.date).format('DD-MM-YYYY HH:mm') : null
                    )
                ) : []
                setPhotos(items)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const checkComplexJob = () => {
        console.log(currentJob, jobInstructions);
        if (currentJob.status === 'Open') {
            if (jobInstructions && jobInstructions.length > 0 && jobInstructions.length < 2) {
                JobService.isComplexJob(currentJob.property.client.clientId, currentJob.clientContractId, currentJob.jobNumber)
                    .then((response) => {
                        console.log('isComplexJob', response.object);
                        setIsComplexJob(response.object);
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            }
        }
        
    }

    const handleOnDownloadAllFiles = (event) => {
        JobService.getZipWithAllFiles(jobId, clientId)
            .then((response) => {
                if (response) {
                    downloadFile(`Job_${jobId}_documents.zip`, response)
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const handleOnDownloadAllPhotos = (event) => {
        JobService.getZipWithAllPhotos(jobId, clientId)
            .then((response) => {
                if (response) {
                    downloadFile(`Job_${jobId}_photos.zip`, response)
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const goToInstruction = (event, id, uprn) => {
        history.push(`/job/${jobId}/instruction/${id}/${clientId}/${uprn}`)
    }
    const goToProperty = (event) => {
        PropertyService.getByUprn(clientId, uprn)
            .then((response) => {
                console.log('got prop',response)
                history.push({
                    pathname: `/property/${response.object?.propertyId}`,
                    search: `?tab=job-history`
                })
            })

    }

    const collectionCheck = (collectionObj) => {
        return collectionObj = collectionObj.collection != null && collectionObj.collection.length > 0;
    }

    const carouselNextSlide = () => {
        carousel.current.slideNext()
    }

    const carouselPreviousSlide = () => {
        carousel.current.slidePrev()
    }

    const updateStatus = (event) => {
        JobService.updateStatus(currentJob.id, event.target.value, event._targetInst.child.alternate.memoizedProps[0])
            .then((response) => {
                if (response && response.success === true) {
                    let currJob = currentJob;
                    currJob.statusCode = event.target.value;
                    currJob.status = event._targetInst.child.alternate.memoizedProps[0];
                    setCurrentJob(currJob);
                    setStatus(event.target.value.toString());
                }
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        getJob(jobId, uprn, clientId)
        getJobInstructions(jobId, clientId)
        getNotes(jobId, clientId)
        getFiles(jobId, clientId)
        getPhotos(jobId, clientId)
        getCommunications(jobId, clientId)
    }, [jobId, uprn, clientId])

    useEffect(() => {
        if (currentJob.status !=='' && jobInstructions.length > 0) {
            checkComplexJob();
        }
    }, [currentJob, jobInstructions])

    const renderStatus = () => {
        let defaultOutput = <Typography variant="body1">{currentJob.status}</Typography>

        if (globalUserRole !== 'User') {
            switch (status) {
                case "3": return <Select onChange={updateStatus} value={status}><MenuItem value="3">Parts Needed</MenuItem><MenuItem value="5">Referred to Supervisor</MenuItem><MenuItem value="7">Parts Authorised</MenuItem><MenuItem value="8">Parts Ordered</MenuItem><MenuItem value="9">Parts In</MenuItem></Select>;
                case "8": return <Select onChange={updateStatus} value={status}><MenuItem value="8">Parts Ordered</MenuItem><MenuItem value="9">Parts In</MenuItem></Select>;
                default: return defaultOutput;
            }
        }
        else {
            return defaultOutput;
        }
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Button
                        variant="contained"
                        onClick={(event) => goToProperty(event)}
                        startIcon={<ArrowBackIcon />}
                        className={classes.backBtn}
                    >
                        Go to Property
                    </Button>
                    <div className={classes.titleBox}>
                        <Typography className={classes.title} variant="h4">
                            {currentJob.propertyDetails}
                        </Typography>
                        <Typography
                            className={classes.subTitle}
                            variant="subtitle1"
                            gutterBottom
                        >
                            {currentJob.client} - {currentJob.clientContract}
                        </Typography>
                    </div>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                        <Link to={`/job/${jobId}`}>
                            JOB DETAILS
                        </Link>
                        <span>JOB № {currentJob.jobNumber}</span>
                    </Breadcrumbs>
                </Grid>
                {!isComplexJob ? (
                    <Grid item xs={12} md={4} className={classes.alignRight}>
                        <Button
                            component={Link}
                            underline="none"
                            to={`/schedule-job/${clientId}/${currentJob.clientContractId}/${uprn}/${currentJob.jobNumber}/${currentJob.clientJobNumber}/${Moment(currentJob.targetCompletionDateUnformatted).format('YYYY-MM-DD')}/book`}
                            className={classes.purchaseOrderButton}
                            startIcon={<DescriptionOutlinedIcon />}
                            variant="contained"
                            size="large"
                            color="primary"
                            target="_new"
                        >
                            Book Job
                        </Button>
                    </Grid>
                ) : null}
                

            </Grid>

            <Divider className={classes.divider} />
            <Grid container spacing={3} direction="row">
                <Grid item xs={12} container>
                    <Grid item xs container spacing={3}>
                        <Grid container item sm={12} md={3} xs={12} direction="column">
                            <Box>
                                <Typography variant="h5">Job Information</Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={9} xs={12}>
                            <Grid item xs={12} container direction="row">
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Job Number
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.jobNumber)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Client Job Number
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.clientJobNumber)}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Priority
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.priority)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container direction="row">
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Property Details
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.propertyDetails)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Tenant Details
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.tenantDetails)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Mobile
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.property?.mobileTelephone)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Home Telephone
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.property?.homeTelephone)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Work Telephone
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.property?.workTelephone)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Customer Email
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.property?.emailAddress)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container direction="row">
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Job Type
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.jobType)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Service Type
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.serviceType)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Contract Specific Job Type
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.contractSpecificJobType)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container direction="row">
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Raised Date
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.dateRaised)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Target Response Date
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.targetResponseDate)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Target Completion Date
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.targetCompletionDate)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            SMV
                                        </Typography>
                                        <Typography variant="body1">
                                            {currentJob.smv}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container direction="row" className={classes.paddingBottomContainer}>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Soft Fix Outcome
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.softFixOutcome)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Locked at Legal
                                        </Typography>
                                        <Typography variant="body1">
                                            {currentJob.lockedAtLegal ? 'Yes' : 'No'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Cancelled Reason
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.cancelledReason)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container direction="row" className={classes.paddingBottomContainer}>

                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Status
                                        </Typography>
                                        {renderStatus()}
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Monetary Value
                                        </Typography>
                                        <Typography variant="body1">
                                            £ {currentJob.jobValue}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} md={4} xs={12} container direction="column">
                                    <Box className={classes.paddigTopCotainer}>
                                        <Typography variant="body2" className={classes.itemTitle}>
                                            Raised By
                                        </Typography>
                                        <Typography variant="body1">
                                            {returnNA(currentJob.loggedBy)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={classes.greyDivider} />
            <Grid container spacing={3} direction="row">
                <Grid item xs={12} container>
                    <Grid item xs container spacing={3}>
                        <Grid container item sm={12} md={3} xs={12} direction="column">
                            <Box>
                                <Typography variant="h5">Job Description</Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={9} xs={12}>
                            <Grid
                                item
                                xs
                                container
                                direction="row"
                                className={classes.contactDetailsItems}
                            >
                                <Box>
                                    <Typography variant="body1">
                                        {returnNA(currentJob.jobDescription)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {jobInstructions && jobInstructions.length ? (
                <React.Fragment>
                    <Divider className={classes.greyDivider} />
                    <Grid container spacing={3} direction="row">
                        <Grid
                            item
                            xs={12}
                            container
                            className={classes.withoutPaddingRight}
                        >
                            <Grid item xs container spacing={3}>
                                <Grid container item sm={12} md={3} xs={12} direction="column">
                                    <Box>
                                        <Typography variant="h5">Instruction History</Typography>
                                    </Box>
                                </Grid>

                                <Grid
                                    item
                                    sm={12}
                                    md={9}
                                    xs={12}
                                    className={classes.instructionsContainer}
                                >
                                    {jobInstructions.map((item, index, arr) => (
                                        <div
                                            className={classes.instructionItem}
                                            key={index}
                                            onClick={(event) => goToInstruction(event, item.instructionNumber, uprn)}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                direction="row"
                                                className={classes.instructionItemPadding}
                                            >
                                                <Grid container item xs={4} direction="column">
                                                    <Box>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.itemTitle}
                                                        >
                                                            Appointment Date / Preferred Time
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {item.appointmentDateTime
                                                                ? `${item.appointmentDateTime} ${item.preferredTime}`
                                                                : '-'}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid container item xs={2} direction="column">
                                                    <Box>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.itemTitle}
                                                        >
                                                            Status
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {returnNA(item.status)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid container item xs={2} direction="column">
                                                    <Box>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.itemTitle}
                                                        >
                                                            PDA Status
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {returnNA(item.pdaStatus)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid container item xs={4} direction="column">
                                                    <Box>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.itemTitle}
                                                        >
                                                            Outcome
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {returnNA(item.outcome)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid container item xs={4} direction="column" className={classes.paddigTopCotainer}>
                                                    <Box>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.itemTitle}
                                                        >
                                                            Operative Assigned
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {returnNA(item.operativeAssigned)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid container item xs={4} direction="column" className={classes.paddigTopCotainer}>
                                                    <Box>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.itemTitle}
                                                        >
                                                            Action Required
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {returnNA(item.actionRequired)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                direction="row"
                                                className={classes.instructionItemPadding}
                                            >
                                                <Grid container item xs={12} direction="column">
                                                    <Box className={classes.contactDetailsItems}>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.itemTitle}
                                                        >
                                                            Task Description
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {returnNA(item.taskDescription)}
                                                        </Typography>
                                                        </Box>
                                                </Grid>
                                            </Grid>
                                            {index !== arr.length - 1 ? (
                                                <Divider className={classes.itemGridDivider} />
                                            ) : null}
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ) : null}
            {jobNotes && jobNotes.length ? (
                <React.Fragment>
                    <Divider className={classes.greyDivider} />
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={12} container>
                            <Grid item xs container spacing={3}>
                                <Grid container item sm={12} md={3} xs={12} direction="column">
                                    <Box>
                                        <Typography variant="h5">Job Notes</Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={9} xs={12}>
                                    <ListOfNotes items={jobNotes}></ListOfNotes>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ) : null}

            {jobCommunications && jobCommunications.length > 0 ? (
                <React.Fragment>
                    <Divider className={classes.greyDivider} />
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={12} container>
                            <Grid item xs container spacing={3}>
                                <Grid container item xs={12} sm={12} md={3} direction="column">
                                    <Box>
                                        <Typography variant="h5">
                                            Communication Record (SMS)
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9}>
                                    <Carousel
                                        itemsToShow={3}
                                        itemPosition={'flex-start'}
                                        ref={carousel}
                                    >
                                        {jobCommunications.map((item, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                className={classes.carouselCardContainer}
                                            >
                                                <Card
                                                    className={classes.carouselCard}
                                                    variant="outlined"
                                                >
                                                    <CardContent>
                                                        <Typography color="textSecondary" gutterBottom>
                                                            {returnNA(item.date)}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            component={'div'}
                                                            className={clsx({
                                                                [classes.outgoingTitle]: item.code === '0',
                                                                [classes.incomingTitle]: item.code === '1'
                                                            })}
                                                        >
                                                            {
                                                                {
                                                                    0: (
                                                                        <div>
                                                                            <img
                                                                                src="/icons/output-message-icon.svg"
                                                                                alt="Status"
                                                                                className={classes.statusImg}
                                                                            />
                                                                            Outgoing Message
                                                                        </div>
                                                                    ),
                                                                    1: (
                                                                        <div>
                                                                            <img
                                                                                src="/icons/incoming-message-icon.svg"
                                                                                alt="Status"
                                                                                className={classes.statusImg}
                                                                            />
                                                                            Incoming Message
                                                                        </div>
                                                                    )
                                                                }[item.code]
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            className={classes.textDecoration}
                                                        >
                                                            {returnNA(item.communicationText)}
                                                            <br />
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Carousel>
                                    {jobCommunications.length > 3 ? (
                                        <Box ml={2} mb={4}>
                                            <Button
                                                onClick={carouselPreviousSlide}
                                                variant="contained"
                                            >
                                                <ArrowBackIcon />
                                            </Button>
                                            <Button
                                                onClick={carouselNextSlide}
                                                variant="contained"
                                                className={classes.forwardBtn}
                                            >
                                                <ArrowForwardIcon />
                                            </Button>
                                        </Box>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ) : null}
            {photos && photos.length ? (
                <React.Fragment>
                    <Divider className={classes.greyDivider} />
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        className={classes.contactDetailsItems}
                    >
                        <Grid item xs={12} container>
                            <Grid item xs container spacing={3}>
                                <Grid container item sm={12} md={3} xs={12} direction="column">
                                    <Box>
                                        <Typography variant="h5">Photos</Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={9} xs={12} className={classes.imgGrid}>
                                    <ImageGallery
                                        items={photos}
                                        onDownloadAll={handleOnDownloadAllPhotos}
                                    ></ImageGallery>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ) : null}
            {files && files.length ? (
                <React.Fragment>
                    <Divider className={classes.greyDivider} />
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        className={classes.contactDetailsItems}
                    >
                        <Grid item xs={12} container>
                            <Grid item xs container spacing={3}>
                                <Grid container item sm={12} md={3} xs={12} direction="column">
                                    <Box>
                                        <Typography variant="h5">Files</Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={9} xs={12}>
                                    <ListOfFiles
                                        items={files}
                                        onDownloadAll={handleOnDownloadAllFiles}
                                    ></ListOfFiles>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    )
}

export default JobSummary
