import http from '../utils/http'

const getAll = () => http.get(`/Client/all`)

const getMyClients = () => http.get(`/Client/my-clients`)

const getContractDetails = (clientContractId) => http.get(`/Client/contract-details?clientContractId=${clientContractId}`)

const getClientContracts = (query) => {
    const params = new URLSearchParams()
    const page = query && query.hasOwnProperty('page') ? query.page : 1
    const size = query && query.hasOwnProperty('size') ? query.size : 100
    params.append('PageNumber', page)
    params.append('PageSize', size)

    if (query && query.hasOwnProperty('filterText') && query.filterText) {
        params.append('SearchText', query.filterText)
    }

    if (query && query.hasOwnProperty('filterClient') && query.filterClient) {
        params.append('ClientID', query.filterClient)
    }

    if (query && query.hasOwnProperty('filterContract') && query.filterContract) {
        params.append('ClientContractID', query.filterContract)
    }

    return http.get(`/Client/my-clientcontracts`, { params })
}

const getContractsForProperty = (propertyId) => http.get(`/Client/property-clients?propertyId=${propertyId}`)

const getPermissions = (clientId) => http.get(`Client/permissions?clientId=${clientId}`)

export default {
  getAll,
  getMyClients,
    getClientContracts,
    getContractsForProperty,
    getContractDetails,
    getPermissions
}
