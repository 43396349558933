import Moment from 'moment'

const dateComparator = (a, b, orderBy) => {
  const firstDate = Moment(a[orderBy], 'DD-MM-YYYY HH:mm')
  const secondDate = Moment(b[orderBy], 'DD-MM-YYYY HH:mm')

  if (secondDate.isBefore(firstDate)) {
    return -1
  }
  if (secondDate.isAfter(firstDate)) {
    return 1
  }
  return 0
}

const defaultComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const descendingComparator = (a, b, orderBy, type) => {
  switch (type) {
    case 'date':
      return dateComparator(a, b, orderBy)
    default:
      return defaultComparator(a, b, orderBy)
  }
}

export const getComparator = (order, orderBy, type) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, type)
    : (a, b) => -descendingComparator(a, b, orderBy, type)
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
